import tw, { styled, css } from 'twin.macro';
import { StyledProps as Props } from '../../../types/util.types';

export const wrapper = styled.div<Props>(({ listView }) => [
  css`
    ${tw`flex items-center pl-20 border-b-2 border-gray9 relative`};
    height: 6.4rem;
    min-width: 149rem;
    > div {
      &:first-child {
        ${tw`border-r-2 border-gray9 pr-5`}
        min-width: 57rem;
      }
      &:not(:first-child) {
        ${tw`w-full border-r-2 border-gray9 pl-15 pr-5`}
        min-width:19rem
      }
      &:last-child {
        ${tw`w-full border-r-0  pl-15 `}
        min-width:12rem
      }
    }
  `
]);

export const item = styled.div(
  ({
    theme: {
      typography: { sectionSubtitleSmall }
    }
  }) => [
    css`
      ${sectionSubtitleSmall}
      ${tw`flex justify-start items-center w-full relative mb-0 text-textBlack`} /* max-width: 57rem; */
      min-height: 48px;

      > div {
        ${tw`ml-auto`}
      }
      &:nth-child(5) {
        > span:last-of-type {
          ${tw`ml-auto`}
        }
      }

      > span:first-of-type {
        ${tw`hidden xxl:block`}
      }
      > span:last-of-type {
        ${tw`block xxl:hidden `}
      }
    `
  ]
);

export const action = styled.div(() => [
  css`
    ${tw`w-full px-15 py-10 hover:bg-darkBlue text-darkBlue text-left font-semibold hover:text-white cursor-pointer text-18`};
    transition: all 0.3s;
  `
]);
