import tw, { styled, css } from 'twin.macro';
import {Link} from 'react-router-dom';
import { Dropdown } from '../../../components/Actions/actions-component';
import { StyledProps as Props } from '../../../types/util.types';

export const wrapper = styled.div<Props>(({ listView }) => [
  css`
    ${tw`flex items-center bg-white text-textBlack`};

    ${Dropdown} {
      ${tw`px-0 py-15`}

      div, a {
        ${tw`w-full p-15 hover:bg-darkBlue text-darkBlue text-left font-semibold hover:text-white cursor-pointer transition duration-300`};
      }
    }
  `,
  !listView &&
    css`
      ${tw`flex-col items-start px-20 py-15`}
      border-radius: 4px;
      box-shadow: 0px 7px 10px 0px rgba(0, 0, 0, 0.15);

      &:nth-child(2n) {
        ${Dropdown} {
          ${tw`
          sm:left-auto lg:left-100
          sm:right-100 lg:right-auto
          sm:-translate-x-4 lg:translate-x-4
          `}
        }
      }

      &:nth-child(3n) {
        ${Dropdown} {
          ${tw`
          lg:left-auto xl:left-100
          lg:right-100 xl:right-auto
          lg:-translate-x-4 xl:translate-x-4
          `}
        }
      }

      &:nth-child(4n) {
        ${Dropdown} {
          ${tw`
          xl:left-auto
          xl:right-100
          xl:-translate-x-4
          `}
        }
      }
    `,
  listView &&
    css`
      ${tw` items-center flex-wrap px-20 py-15`}

      &:not(:last-child) {
        ${tw`border-b-2 border-gray9`}
      }
    `
]);

export const title = styled(Link)<Props>(({ list, summary }) => [
  css`
    ${tw`text-32 leading-36 font-semibold text-textBlack hover:text-textBlack text-left break-all`}
  `,
  list === 0 && tw`mb-50`,

  !summary &&
    css`
      ${tw`pointer-events-none`}
    `
]);

export const footer = styled.div<Props>(({ listView }) => [
  css`
    ${tw`flex items-center`};

    img {
      ${tw`cursor-pointer mr-3`}
    }
  `,
  !listView &&
    css`
      ${tw`self-end mt-auto`};
    `,
  listView &&
    css`
      ${tw`ml-auto`}
    `
]);

export const message = styled.span<Props>(({ list }) => [
  list &&
    css`
      ${tw`ml-20 mt-6`}
    `
]);