import React, { useContext, useState, useEffect, useRef } from 'react';
import { format, startOfWeek, differenceInCalendarDays } from 'date-fns';
import {
  ProjectProgressPhase,
  ProjectEvents
} from '../../../store/projects/projects.types';
import { updateProjectStartDate } from '../../../store/projects/projects.actions';
import * as S from './project-duration';
import Phase from './Phase';
import AddEvent from '../AddEvent';
import Event from './Event';
import DateInput from '../../../components/Inputs/DateInput';
import Modal from '../../../components/Modal';
import Button from '../../../components/Button';
import { ProgressContext } from '../Progress';

const ProjectDuration = () => {
  const isFirst = useRef(true);
  const {
    project: { phases, duration, events, id },
    isMemberRole,
    updateProject
  } = useContext(ProgressContext);
  const totalWeeks =
    duration?.startDate && duration?.endDate
      ? Math.ceil(
          differenceInCalendarDays(
            new Date(duration.endDate),
            startOfWeek(new Date(duration.startDate), { weekStartsOn: 1 })
          ) / 7
        )
      : 10;
  const projectStart = duration?.startDate
    ? startOfWeek(new Date(duration.startDate), { weekStartsOn: 1 })
    : null;

  const [projectDate, setProjectDate] = useState<Date | null>(
    duration?.startDate ? new Date(duration.startDate) : null
  );
  const [active, setActive] = useState<boolean>(false);

  const closeModal = () => {
    setActive(false);
  };

  const showModal = () => {
    setActive(true);
  };

  useEffect(() => {
    if (isFirst.current || !projectDate) {
      isFirst.current = false;
      return;
    }
    (async () => {
      try {
        const res = await updateProjectStartDate(
          id,
          format(projectDate, 'yyyy-MM-dd')
        );
        updateProject(res);
      } catch {}
    })();
    // eslint-disable-next-line
  }, [projectDate]);

  return (
    <S.wrapper>
      <h3>
        Project Duration - {phases.length} Phase{phases.length > 1 && 's'}
      </h3>
      <S.body>
        <S.header>
          <div>
            <DateInput
              onOpen={showModal}
              activeAlert={active}
              date={projectDate}
              setDate={setProjectDate}
              label="Start date"
            />
            <S.headerBox disabled={isMemberRole} hasValue={!!projectDate}>
              <span>End Date</span>
              {duration &&
                duration.endDate &&
                format(new Date(duration.endDate), 'dd.MM.yyyy')}
            </S.headerBox>
          </div>
          <AddEvent />
          <div>
            <S.headerBox
              disabled={isMemberRole}
              hasValue={!!duration?.personDays}
            >
              <span>Person Days</span>
              {duration && `${Math.round(duration.personDays)} pd`}
            </S.headerBox>
            <S.headerBox
              disabled={isMemberRole}
              hasValue={!!duration?.duration}
            >
              <span>Project weeks</span>
              {!!duration?.duration && `${totalWeeks} Weeks`}
            </S.headerBox>
          </div>
        </S.header>
        <S.chart>
          <S.chartSidebar>
            <S.sidebarEvents>
              {events
                .filter((e: ProjectEvents) => !e.date)
                .map((e: ProjectEvents) => (
                  <Event data={e} key={e.id} />
                ))}
            </S.sidebarEvents>
            <S.sidebarPhaseWrapper>
              {phases.map((p: ProjectProgressPhase, i: number) => (
                <S.sidebarPhase key={p.id}>
                  <span>
                    Phase {i + 1} - {p.name}
                  </span>
                </S.sidebarPhase>
              ))}
            </S.sidebarPhaseWrapper>
          </S.chartSidebar>
          <S.chartMain>
            <S.chartHeader>
              {phases.length > 0 &&
                Array.from(Array(totalWeeks)).map((w, i) => (
                  <S.chartWeek key={i}>w{i + 1}</S.chartWeek>
                ))}
            </S.chartHeader>
            <S.chartBody style={{ width: `${totalWeeks * 10}rem` }}>
              {phases.map((p: ProjectProgressPhase) => (
                <S.chartRow key={p.id}>
                  <Phase data={p} projectStart={projectStart} />
                </S.chartRow>
              ))}
            </S.chartBody>
            {events
              .filter((e: ProjectEvents) => e.date)
              .sort(
                (a: ProjectEvents, b: ProjectEvents) =>
                  new Date(a.date!).getTime() - new Date(b.date!).getTime()
              )
              .map(
                (
                  e: ProjectEvents,
                  i: number,
                  filteredEvents: ProjectEvents[]
                ) => (
                  <Event
                    data={e}
                    key={e.id}
                    projectStart={projectStart}
                    nextEvent={filteredEvents[i + 1]}
                    prevEvent={filteredEvents[i - 1]}
                  />
                )
              )}
          </S.chartMain>
        </S.chart>
      </S.body>
      <Modal isOpen={active} onDismiss={closeModal}>
        <S.dateWarning>
          <p>
            If any methods are available, the start date of the methods will be{' '}
            <br />
            moved accordingly, to reflect the change of the project start date.
          </p>
          <Button secondary onClick={closeModal}>
            Dismiss
          </Button>
        </S.dateWarning>
      </Modal>
    </S.wrapper>
  );
};

export default ProjectDuration;
