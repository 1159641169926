import React, { useState } from 'react';
import { Disciplines } from '../../../store/method-service/types';
import * as S from './list-item';

interface Props {
  disciplines: Disciplines[];
}

const DisciplineColors = ({ disciplines }: Props) => {
  const serviceDesign = disciplines.find(
    (d: Disciplines) => d.name === 'Service Design'
  );
  const industrialDesign = disciplines.find(
    (d: Disciplines) => d.name === 'Industrial Design'
  );
  const softwareDevelopment = disciplines.find(
    (d: Disciplines) => d.name === 'Software Development'
  );
  const uxDesign = disciplines.find((d: Disciplines) => d.name === 'UX Design');

  const [shown, setShown] = useState<boolean>(false);

  return (
    <>
      <S.discipline
        serviceDesign={serviceDesign}
        industrialDesign={industrialDesign}
        softwareDevelopment={softwareDevelopment}
        uxDesign={uxDesign}
        onMouseEnter={() => setShown(true)}
        onMouseLeave={() => setShown(false)}
      >
        <div></div>
        <div></div>
        <div></div>
        <div></div>
      </S.discipline>
      <S.colorInfo
        open={shown}
        serviceDesign={serviceDesign}
        industrialDesign={industrialDesign}
        softwareDevelopment={softwareDevelopment}
        uxDesign={uxDesign}
      >
        <div>UX Design</div>
        <div>Industrial Design</div>
        <div>Service Design</div>
        <div>Software Development</div>
      </S.colorInfo>
    </>
  );
};

export default DisciplineColors;
