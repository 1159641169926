import React, { ChangeEvent, useState, useEffect } from 'react';
import { useDropzone } from 'react-dropzone';
import { TextInput, CbRadio } from '../Inputs';
import Button from '../Button';
import Modal from '../../components/Modal';
import PictureCropper from '../../components/PictureCropper/PictureCropper';
import { genderModel, GenderType } from './form-model';
import { toastUtil } from '../../utils/toast.util';
import { handleErrors } from '../../utils/error.utils';
import { NewSearchInput } from '../../components/Inputs';
import { addMember, searchAccounts } from '../../store/members/members.actions';
import { SearchMemberItem } from '../../store/members/members.types';
import {
  addCompany,
  getCompanies
} from '../../store/companies/companies.actions';
import { CompanyType } from '../../store/companies/companies.types';
import { uploadClientAvatar } from '../../store/projects/projects.actions';
import { SetStateType } from '../../types/util.types';
import addIcon from '../../images/add.svg';
import uploadWhite from '../../images/upload-white.svg';
import uploadDark from '../../images/upload.svg';
import successIcon from '../../images/accepted-white.svg';
import * as S from './client-form';

interface Props {
  selectedUser: SearchMemberItem | null;
  setSelectedUser: SetStateType<SearchMemberItem | null>;
  title: string;
}

const ClientForm = ({ selectedUser, setSelectedUser, title }: Props) => {
  const [submitting, setSubmitting] = useState<boolean>(false);
  const [selectedCompany, setSelectedCompany] = useState<CompanyType | null>();
  const [company, setCompany] = useState<string>('');
  const [gender, setGender] = useState('MALE');
  const [userCreated, setUserCreated] = useState<boolean>(false);
  const [lastName, setLastName] = useState<string>('');
  const [firstName, setFirstName] = useState<string>('');
  const [email, setEmail] = useState<string>('');
  const [userAvatarPreview, setUserAvatarPreview] = useState<any>(null);
  const [croppedImagePreview, setCroppedImagePreview] = useState<any>(null);
  const [isUploading, setIsUploading] = useState<boolean>(false);
  const [file, setFile] = useState<any>(null);
  const [openModal, setOpenModal] = useState<boolean>(false);

  const searchCompany = async (searchTerm: string) => {
    try {
      const data = await getCompanies(company);
      return data;
    } catch {}
  };

  const openCropModal = () => {
    setOpenModal(true);
  };

  const closeModal = () => {
    setOpenModal(false);
  };

  const createCompany = async () => {
    try {
      setSubmitting(true);
      const res = await addCompany({
        companyName: company
      });
      setSelectedCompany(res);
      setSubmitting(false);
      toastUtil('success', 'The company was successfully created');
    } catch (error) {
      handleErrors(error);
      setSubmitting(false);
    }
  };

  const searchClients = async (searchTerm: string) => {
    try {
      const searchResult = await searchAccounts(searchTerm, 'ROLE_CLIENT');
      return searchResult;
    } catch {}
  };

  const createUser = async () => {
    try {
      setSubmitting(true);
      const newUser = await addMember({
        gender,
        role: 'ROLE_CLIENT',
        firstName,
        lastName,
        email,
        company: selectedCompany!.id
      });
      setSubmitting(false);
      setSelectedUser(newUser);
      setUserCreated(true);
      toastUtil(
        'success',
        `Client ${newUser?.firstName} ${newUser?.lastName} from ${newUser?.company.name} has been created. `,
        'small',
        2500
      );
    } catch (error) {
      setSubmitting(false);
      setUserCreated(false);
    }
  };

  const handleUpload = async () => {
    try {
      setIsUploading(true);
      const data = await uploadClientAvatar(selectedUser!.id!, file);
      setSelectedUser(data);
      toastUtil('success', `User avatar was successfully uploaded`);
    } catch (error) {
      setIsUploading(false);
      console.warn('Image upload failed');
    }
  };

  const onCropSuccess = async (file: any, preview: any) => {
    try {
      setFile(file);
      setCroppedImagePreview(preview);
    } catch {
      console.warn('Image upload failed');
    }
  };

  useEffect(() => {
    if (userAvatarPreview) openCropModal();
  }, [userAvatarPreview]);

  const { getRootProps, getInputProps } = useDropzone({
    accept: 'image/png, image/jpeg',
    onDropRejected: () => {
      console.warn('rejected');
      setUserAvatarPreview(null);
    },
    onDrop: (acceptedFiles) => {
      const reader = new FileReader();
      reader.onload = () => {
        const url = reader.result;
        setUserAvatarPreview(url);
      };
      reader.readAsDataURL(acceptedFiles[0]);
    }
  });

  useEffect(() => {
    if (lastName !== selectedUser?.lastName) setSelectedUser(null);
    // eslint-disable-next-line
  }, [lastName, selectedUser]);

  useEffect(() => {
    if (company !== selectedCompany?.name) setSelectedCompany(null);
  }, [company, selectedCompany]);

  useEffect(() => {
    setUserCreated(false);
  }, [company, lastName, firstName, email]);

  return (
    <S.wrapper>
      <h3>{title}</h3>
      <S.companyContent>
        <NewSearchInput
          setValue={(value: string) => setCompany(value)}
          selectValue={(item: CompanyType) => {
            setSelectedCompany(item);
            setCompany(item.name);
          }}
          value={company}
          label="Company Name"
          labelProps={['name']}
          onSearch={searchCompany}
          disabled={userCreated || !!selectedUser}
        />
        <Button
          small
          frameless
          disabled={submitting || !company || !!selectedCompany}
          onClick={createCompany}
        >
          <img src={addIcon} alt="" />
          Add company
        </Button>
      </S.companyContent>
      <S.form>
        <S.gender>
          {genderModel.map((g: GenderType) => (
            <CbRadio
              key={g.value}
              radio
              checked={gender === g.name}
              disabled={!!selectedUser || !selectedCompany}
              name={g.name}
              value={g.value}
              onChange={(evt: ChangeEvent<HTMLInputElement>) => {
                if (evt.target.checked) {
                  setGender(g.name);
                }
              }}
            >
              {g.value}
            </CbRadio>
          ))}
        </S.gender>
        <NewSearchInput
          disabled={userCreated || !!selectedUser}
          setValue={(value: string) => setLastName(value)}
          selectValue={(item: SearchMemberItem) => {
            setLastName(item.lastName);
            setFirstName(item.firstName);
            setEmail(item.email);
            setSelectedUser(item);
            setUserCreated(false);
            setCompany(item.company.name);
            setSelectedCompany(item.company);
          }}
          value={lastName}
          label="Lastname"
          labelProps={['lastName', 'firstName']}
          onSearch={searchClients}
        />
        <TextInput
          value={firstName}
          type="text"
          label="Firstname"
          name="firstName"
          onChange={(e) => {
            setFirstName(e.target.value);
          }}
          disabled={!!selectedUser || !selectedCompany}
          disableAutoComplete
        />
        <TextInput
          value={email}
          type="email"
          label="Email address"
          name="email"
          onChange={(e) => {
            setEmail(e.target.value);
          }}
          disabled={!!selectedUser || !selectedCompany}
          disableAutoComplete
        />
        {(!selectedUser || userCreated) && (
          <S.buttonContainer userCreated={userCreated}>
            <Button
              secondary
              small
              disabled={
                submitting ||
                !selectedCompany ||
                !lastName ||
                !firstName ||
                !email
              }
              onClick={createUser}
            >
              {userCreated ? (
                <>
                  <img src={successIcon} alt="" />
                  User is created
                </>
              ) : (
                'Create user'
              )}
            </Button>
          </S.buttonContainer>
        )}
      </S.form>
      <S.avatarWrapper>
        {!userCreated && selectedUser && selectedUser.image && (
          <img src={selectedUser.image} alt={selectedUser.firstName} />
        )}
        {(userCreated || !selectedUser) && (
          <>
            <S.droparea
              disabled={!selectedCompany || !lastName || !firstName || !email}
              {...getRootProps({ refKey: 'innerRef' })}
              preview={!!croppedImagePreview}
            >
              <input
                {...getInputProps()}
                disabled={!selectedCompany || !lastName || !firstName || !email}
              />
              {croppedImagePreview ? (
                <img src={croppedImagePreview} alt="" />
              ) : (
                <div>
                  <p>
                    Drop avatar image here, or select a file from your computer
                  </p>
                  <img src={uploadDark} alt="" />
                </div>
              )}
            </S.droparea>
            <S.actions>
              <Button
                secondary
                disabled={isUploading || !file || !selectedUser}
                onClick={handleUpload}
                small
              >
                <img src={uploadWhite} alt="" />
                Upload Avatar
              </Button>
              <span>JPG or PNG, at least 256px.</span>
            </S.actions>
          </>
        )}
      </S.avatarWrapper>
      <Modal isOpen={openModal} onDismiss={closeModal}>
        <PictureCropper
          title="Upload Avatar"
          description="Drop avatar image here, or select a file from your computer"
          uploadDefinition="Upload Image"
          aspect={1 / 1}
          onClose={closeModal}
          selectedImage={userAvatarPreview}
          onCropSuccess={onCropSuccess}
        />
      </Modal>
    </S.wrapper>
  );
};

export default ClientForm;
