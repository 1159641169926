import React, { useState, FunctionComponent, useContext } from 'react';
import { format } from 'date-fns';
import { ProjectServicePackages } from '../../../store/projects/projects.types';
import { ProgressContext } from '../Progress';
import * as S from './project-phases';
import Modal from '../../../components/Modal';
import AddMethod from './AddMethod';
import PhaseMethod from '../PhaseMethod';
import images from '../../../utils/images.utils';

interface Props {
  data: ProjectServicePackages;
  onDelete: (id: number) => void;
  categories: string;
  phaseId: number;
  showDeleteMethod: (methodId: number, serviceId: number | null) => void;
}

const PhaseService: FunctionComponent<Props> = ({
  data: { id, name, startDate, endDate, totalPersonDays, level, methods },
  onDelete,
  phaseId,
  categories,
  showDeleteMethod
}) => {
  const { isMemberRole } = useContext(ProgressContext);
  const [showDetails, setShowDetails] = useState<boolean>(false);
  const [methodModal, setMethodModal] = useState<boolean>(false);

  const methodDeliverables: any = methods
    .map((m: ProjectServicePackages) => m.deliverables.map((d: any) => d.name))
    .flat()
    .filter((name) => name)
    .join('; ');

  const openMethodModal = () => {
    setMethodModal(true);
  };

  const closeMethodModal = () => {
    setMethodModal(false);
  };

  const toggleDetails = () => setShowDetails(!showDetails);

  return (
    <>
      <S.service>
        <S.serviceHeader>
          <S.serviceTitle onClick={toggleDetails}>
            <S.serviceArrow
              src={images.arrowDownIcon}
              alt="arrow"
              onClick={toggleDetails}
              active={showDetails}
            />
            {name}
          </S.serviceTitle>
          <S.serviceInfo>
            <S.serviceDate>
              <S.serviceIcon
                src={images.calendarIcon}
                alt="calendar"
                disabled={isMemberRole ? 1 : 0}
              />
              {startDate &&
                endDate &&
                `${format(new Date(startDate), 'dd.MM.yyyy')} - ${format(
                  new Date(endDate),
                  'dd.MM.yyyy'
                )}`}
            </S.serviceDate>
            <S.serviceDays>
              <S.serviceIcon src={images.userOutline} alt="user" />
              {totalPersonDays.toFixed(2)} pd
            </S.serviceDays>
          </S.serviceInfo>
          <S.serviceDeleteIcon
            src={images.delete}
            alt="delete"
            onClick={() => onDelete(id)}
            disabled={isMemberRole ? 1 : 0}
          />
        </S.serviceHeader>
        <S.serviceBody active={showDetails}>
          {methodDeliverables && (
            <S.serviceDescription>{methodDeliverables}</S.serviceDescription>
          )}
          {methods.map((m: ProjectServicePackages) => (
            <PhaseMethod
              key={m.id}
              data={m}
              servicePackageId={id}
              onDelete={showDeleteMethod}
            />
          ))}

          <S.itemFooter>
            <S.addButton
              onClick={openMethodModal}
              disabled={isMemberRole ? 1 : 0}
            >
              <img src={images.addIcon} alt="Add method to service package" />
              Add method to service package
            </S.addButton>
          </S.itemFooter>
        </S.serviceBody>
      </S.service>
      <Modal isOpen={methodModal} onDismiss={closeMethodModal}>
        <AddMethod
          level={level!}
          closeModal={closeMethodModal}
          categories={categories}
          phaseId={phaseId}
          servicePackageId={id}
        />
      </Modal>
    </>
  );
};

export default PhaseService;
