import React, { useState, useContext } from 'react';
import { useParams } from 'react-router-dom';
import {
  ProjectProgressPhase,
  ProjectServicePackages
} from '../../../store/projects/projects.types';
import {
  removeSpFromPhase,
  removeMethod
} from '../../../store/projects/projects.actions';
import { ProgressContext } from '../Progress';
import * as S from './project-phases';
import Button from '../../../components/Button';
import Modal from '../../../components/Modal';
import AddSp from './AddSp';
import AddMethod from './AddMethod';
import PhaseService from './PhaseService';
import PhaseMethod from '../PhaseMethod';
import images from '../../../utils/images.utils';

interface Props {
  onDelete: (id: number) => void;
  phase: ProjectProgressPhase;
  index: number;
  categories: string;
}

interface Params {
  id: string;
}

type DeleteMethodType = {
  methodId: number | null;
  serviceId: number | null;
};

const PhaseItem = ({ phase, index, onDelete, categories }: Props) => {
  const { updateProject, isMemberRole } = useContext(ProgressContext);
  const { id } = useParams<Params>();
  const [spModal, setSpModal] = useState<boolean>(false);
  const [methodModal, setMethodModal] = useState<boolean>(false);
  const [activeDelete, setActiveDelete] = useState<boolean>(false);
  const [isDeleting, setIsDeleting] = useState<boolean>(false);
  const [deleteSpId, setDeleteSpId] = useState<number | null>(null);
  const [deleteMethodData, setDeleteMethodData] = useState<DeleteMethodType>({
    methodId: null,
    serviceId: null
  });
  const [deleteMethodModal, setDeleteMethodModal] = useState<boolean>(false);

  const openSpModal = () => {
    setSpModal(true);
  };

  const closeSpModal = () => {
    setSpModal(false);
  };

  const openMethodModal = () => {
    setMethodModal(true);
  };

  const closeMethodModal = () => {
    setMethodModal(false);
  };

  const onDeleteSp = async () => {
    try {
      setIsDeleting(true);
      const data = await removeSpFromPhase(id, phase.id, deleteSpId!);
      setActiveDelete(false);
      updateProject(data);
      setIsDeleting(false);
    } catch {
      setIsDeleting(false);
    }
  };

  const onDeleteMethod = async () => {
    try {
      setIsDeleting(true);
      const data = await removeMethod(
        id,
        phase.id,
        deleteMethodData.methodId!,
        deleteMethodData.serviceId
      );
      setDeleteMethodModal(false);
      updateProject(data);
      setIsDeleting(false);
    } catch {
      setIsDeleting(false);
    }
  };

  const showDeleteModal = (id: number) => {
    setActiveDelete(true);
    setDeleteSpId(id);
  };

  const closeDeleteModal = () => {
    setActiveDelete(false);
  };

  const showDeleteMethod = (methodId: number, serviceId: number | null) => {
    setDeleteMethodModal(true);
    setDeleteMethodData({ methodId, serviceId });
  };

  const closeDeleteMethod = () => {
    setDeleteMethodModal(false);
    setDeleteMethodData({ methodId: null, serviceId: null });
  };

  return (
    <>
      <S.itemWrapper>
        <S.itemHeader>
          <h4>
            Phase {index + 1} - {phase.name}
          </h4>
          <S.deletePhase
            src={images.delete}
            alt="Remove Phase"
            onClick={() => onDelete(phase.id)}
            disabled={isMemberRole ? 1 : 0}
          />
        </S.itemHeader>
        {phase.methodsServicePackages.map((s: ProjectServicePackages) => {
          if (s.servicePackage)
            return (
              <PhaseService
                key={s.id}
                data={s}
                onDelete={showDeleteModal}
                categories={categories}
                phaseId={phase.id}
                showDeleteMethod={showDeleteMethod}
              />
            );

          return (
            <S.phaseMethodContainer key={s.id}>
              <PhaseMethod
                data={s}
                onDelete={showDeleteMethod}
                servicePackageId={null}
              />
            </S.phaseMethodContainer>
          );
        })}
        <S.itemFooter>
          <S.addButton onClick={openSpModal} disabled={isMemberRole ? 1 : 0}>
            <img src={images.addIcon} alt="Add service package to phase" />
            Add service package to phase
          </S.addButton>
          <S.addButton
            onClick={openMethodModal}
            disabled={isMemberRole ? 1 : 0}
          >
            <img src={images.addIcon} alt="Add method to phase" />
            Add method to phase
          </S.addButton>
        </S.itemFooter>
      </S.itemWrapper>
      <Modal isOpen={spModal} onDismiss={closeSpModal}>
        <AddSp
          closeModal={closeSpModal}
          categories={categories}
          phaseId={phase.id}
        />
      </Modal>
      <Modal isOpen={methodModal} onDismiss={closeMethodModal}>
        <AddMethod
          closeModal={closeMethodModal}
          categories={categories}
          phaseId={phase.id}
        />
      </Modal>
      <Modal isOpen={activeDelete} onDismiss={closeDeleteModal}>
        <S.removeSp>
          <p>Are you sure that you want to delete this service package?</p>
          <div>
            <Button secondary onClick={closeDeleteModal} disabled={isDeleting}>
              Cancel
            </Button>
            <Button onClick={onDeleteSp} disabled={isDeleting}>
              Confirm
            </Button>
          </div>
        </S.removeSp>
      </Modal>
      <Modal isOpen={deleteMethodModal} onDismiss={closeDeleteMethod}>
        <S.removeSp>
          <p>Are you sure that you want to delete this method?</p>
          <div>
            <Button secondary onClick={closeDeleteMethod} disabled={isDeleting}>
              Cancel
            </Button>
            <Button onClick={onDeleteMethod} disabled={isDeleting}>
              Confirm
            </Button>
          </div>
        </S.removeSp>
      </Modal>
    </>
  );
};

export default PhaseItem;
