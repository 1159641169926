import { createGlobalStyle } from 'styled-components';
import tw from 'twin.macro';
import media from './media';
import typography from './typography';
// import mixins from './mixins';
import vars from './vars';
import ErrorIcon from '../images/error-white.svg';
import CloseX from '../images/close-x-white.svg';
import InviteIcon from '../images/paper-plane-white.svg';
import ChangePasswordIcon from '../images/auth/password-outline-white.svg';
import SuccessIcon from '../images/accepted-white.svg';
import WarningIcon from '../images/warning-white.svg';

export const GlobalStyles = createGlobalStyle`
*,
:after,
:before {
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}

::placeholder {
  color: gray;
  font-weight: 400;
}

@media print {
  body {
    -webkit-print-color-adjust: exact; 
    color-adjust: exact;
  }
}

html {
  height: 100%;
  -webkit-text-size-adjust: 100%;
  -ms-text-size-adjust: 100%;
  font-size: 10px;
}

body {
  height: 100%;
  margin: 20 auto;
  color: gray;
  font-family: 'Titillium Web';
  font-size: 1.8rem;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #f8f8fb;
}

@media print {
  body {
    -webkit-print-color-adjust: exact; 
    color-adjust: exact;
    background-color: #f2f5f8 !important;

  }

  @page {
    background-color: #f2f5f8 !important;
    size: A3;
  }

  
}


h1,
h2,
h3,
h4,
h5,
h6,
p,
ol,
ul {
  margin: 0;
  padding: 0;
  font-weight: normal;
}

h1 {
  ${typography.title}
}

h2 {
  ${typography.subtitle}
}

h3 {
  ${typography.sectionTitle}
}

h4 {
  ${typography.sectionSubtitle}
}

h5 {
  ${typography.sectionSubtitleSmall}
}

p {
  ${typography.p}
}


 a {
   color: ${vars.colors.pink};
   text-decoration: none;
   font-size: 1.8rem;

    &:hover {
      color: ${vars.colors.blue}
    }

    &:focused {
      color: ${vars.colors.green4}
    }
    
   &.disabled {
     opacity: .5
   }
 }

 #root {
   ${tw`flex flex-col lg:flex-row min-h-screen `};

   .content-wrapper {
     ${tw`flex-grow max-w-full`}
     padding-bottom: 10rem;
     ${tw`print:pb-0`}

     @media screen and (min-width: ${media.breakpoints.lgMin}px) {
      max-width: calc(100% - 37.5rem)
    }

    @media screen and (min-width: 375px) and (max-width: 575px) {
      background-color: rgba(242,245,248);
    }
   }
 }

 [data-reach-dialog-overlay] {
  z-index: 100;
  background: none;
}

.Toastify__toast-container {
  width: auto;
  left:50%;
  transform: translateX(0);
  bottom:1rem;

  @media screen and (max-width:1024px) {
    transform: translateX(-50%);
  } 
  @media screen and (max-width:1024px) {
    transform: translateX(-50%);
  
  } 

}

.Toastify__toast {
  /* min-height:195px; by design*/
  padding: 1.4rem 4.8rem 1.4rem 1.6rem;
  color: #ffffff;
  font-size: 1.4rem;
  font-weight: 600;
  letter-spacing: 0;
  line-height: 2.4rem;
  font-family: 'Titillium Web';
  word-break:  break-word;



  &:before {
    content: '';
    display: block;
    min-width: 5.4rem;
    height: 5.4rem;
    margin-right: 2.5rem;
    background-position: center;
    background-repeat: no-repeat;
    background-size:cover;
  }
  &.small {
    min-height:154px;
  }
  &.extra_small {
    min-height:106px;
    &:before {
    width: 6rem;
    height: 7rem;
  }
  }
}


.Toastify__toast--default {
  background: $gray-dark-6;
  &:before {
    display: none;
  }
}

.Toastify__toast--success {
  background: ${vars.colors.green5};
  width:100%;
  max-width:635px;
  & > div {
    font-size: 32px;
font-weight: bold;
letter-spacing: 0.25px;
line-height: 48px;
margin-top:0;
  }
 &:before {
    background-image: url(${SuccessIcon});
  }  
 
  &.small {
  max-width:540px;
 
& > div {
  font-size: 24px;
font-weight: 600;
letter-spacing: 0.5px;
line-height: 40px;
    max-width:387px;
    margin-right:auto
}
  }
  &.extra_small {
  max-width:540px;
 
& > div {
  font-size: 24px;
font-weight: 600;
letter-spacing: 0.5px;
line-height: 40px;
    max-width:387px;
    margin-right:auto
}
  }
  &.invite {
    &:before {
    background-image: url(${InviteIcon});
  }
  }
  &.change_password {
    &:before {
    background-image: url(${ChangePasswordIcon});
  }
  }
}

.Toastify__toast--warning {
  width:100%;
  max-width:635px;
  & > div {
    font-size: 32px;
    font-weight: bold;
    letter-spacing: 0.25px;
    line-height: 48px;
    margin-top:0;
  }
 &:before {
    background-image: url(${WarningIcon});
  }  
}


.Toastify__toast--error {
  background: ${vars.colors.error};
  width:100%;
  max-width:635px;

  & > div {
    max-width: 450px;
    font-size: 32px;
font-weight: bold;
letter-spacing: 0.25px;
line-height: 48px;
margin-right:auto;
margin-top:0;
  }
  &&.small {
  max-width:540px;

  & > div {
  font-size: 24px;
font-weight: 600;
letter-spacing: 0.5px;
line-height: 40px;
    max-width:387px;
    margin-right:auto
}
} 
  &:before {
    background-image: url(${ErrorIcon})
  }
}
/* .Toastify__toast--error */

.Toastify__close-button {
  position: absolute;
  top: 1.4rem;
  right: 1.4rem;
  align-self: center;
  width: 2.4rem;
  height: 2.4rem;
  background: url(${CloseX}) no-repeat center;
  opacity: 1;
  margin-bottom:auto;


  &:focus {
    outline:none
  }

  svg {
    display: none;
  }
}

.dialog-form {
  h2 {
    @include title-l();
    margin-bottom: 3.5rem;
  }

  h3 {
    @include title-m();
    font-size: 1.6rem;
    margin-top: 1rem;
    margin-bottom: 0.4rem;
  }
}
`;

export default {
  media,
  typography,
  ...vars
  // ...mixins,
};
