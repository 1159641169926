import tw, { styled, css } from 'twin.macro';

export const wrapper = styled.div(() => [
  css`
    ${tw`w-full mt-40 print:mt-0 mb-20`}

    @media screen and (max-width: 575px) and (min-width: 320px) {
      ${tw`w-full mt-0`}
    }
  `
]);

export const header = styled.div(() => [
  css`
    ${tw`flex items-center pr-37 bg-darkBlue`}
    min-height: 6.2rem;
    padding-left: 18rem;

    h2 {
      ${tw`m-0 text-center flex-1 text-34 leading-none font-semibold text-white`}
    }

    img {
      ${tw`ml-auto`}
      height: 5rem;
      width: auto;
    }

    @media screen and (max-width: 575px) and (min-width: 320px) {
      ${tw`pl-10`}
      padding-right: 1rem;
      min-height: 4rem;

      h2 {
        ${tw`text-20 leading-22`}
      }

      img {
        ${tw`h-30 ml-auto`}
        width: auto;
      }
    }
  `
]);

export const body = styled.div(() => [
  css`
    ${tw`relative w-full bg-white bg-gray12`}
    height: 17rem;

    > img {
      height: 100%;
      width: 100%;
      object-fit: cover;
    }

    div {
      ${`mt-100`}
    }
  `
]);

export const clientBox = styled.div(() => [
  css`
    ${tw`bg-white absolute flex flex-col items-center rounded-4 pb-16 px-8 pt-8`}
    left: 5rem;
    top: -4rem;
    width: auto;
    overflow: hidden;

    img {
      height: 10rem;
      width: 10rem;
      border-radius: 4px;
    }

    div {
      ${tw`flex justify-center items-center mb-5`}
      height: 11rem;
      span {
        ${tw`text-white lowercase bg-green2 font-semibold p-15 rounded-4 text-20 mt-10 `}
        width: auto;
      }
    }
    p {
      ${tw`text-12 font-semibold text-darkBlue mx-5 text-center leading-14`}
    }

    > span {
      ${tw`text-11 font-semibold text-center text-textBlack opacity-50`}
    }

    @media screen and (max-width: 575px) and (min-width: 320px) {
      ${tw`pb-7`}
      top: 5px;
      left: 2rem;
      width: auto;
      padding: 8px;

      img {
        height: 10rem;
        width: 10rem;
        border-radius: 4px;
      }
    }
  `
]);
