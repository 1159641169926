import React, { ChangeEvent, useEffect } from 'react';
import { Complexity } from '../../../store/method-service/types';
import { SetStateType } from '../../../types/util.types';
import { validatePd } from '../../../utils/personDays.utils';
import TextInput from '../../../components/Inputs/TextInput';
import * as S from './complexity-field';

interface Props {
  complexity: Complexity;
  setComplexity: SetStateType<Complexity>;
  title: string;
  disabled?: boolean;
  disableAll?: boolean;
}

const ComplexityField = ({
  complexity,
  complexity: { type, price, personDays },
  setComplexity,
  title,
  disabled,
  disableAll
}: Props) => {
  const handlePriceChange = (evt: ChangeEvent<HTMLInputElement>) => {
    if (!validatePd(evt.target.value)) return;
    setComplexity({ ...complexity, price: evt.target.value });
  };

  const handlePdChange = (evt: ChangeEvent<HTMLInputElement>) => {
    if (!validatePd(evt.target.value)) return;
    setComplexity({ ...complexity, personDays: evt.target.value });
  };

  const handlePdBlur = (evt: ChangeEvent<HTMLInputElement>) => {
    let val = evt.target.value;
    if (val.endsWith('.'))
      setComplexity({
        ...complexity,
        personDays: val.substring(0, val.length - 1)
      });
  };

  useEffect(() => {
    if (complexity.personDays === 0 || complexity.price === 0) {
      setComplexity({
        ...complexity,
        personDays: complexity.personDays + '',
        price: complexity.price + ''
      });
    }
    //eslint-disable-next-line
  }, [complexity]);

  return (
    <S.wrapper>
      <h4>{title}</h4>
      <TextInput
        name={`${type}-pd`}
        label="Persondays"
        onChange={handlePdChange}
        onBlur={handlePdBlur}
        value={personDays}
        disabled={disabled || disableAll}
      />
      <TextInput
        name={`${type}-price`}
        label="Price"
        onChange={handlePriceChange}
        value={price}
        disabled={disableAll}
      />
    </S.wrapper>
  );
};

export default ComplexityField;
