import React, { useState, ChangeEvent } from 'react';
import { ReactSVG } from 'react-svg';
import useGlobal from '../../../store';
import {
  toggleUserStatus,
  changeRole,
  resendInvitation
} from '../../../store/members/members.actions';
import * as S from './members-item';
import Button from '../../../components/Button';
import CbRadio from '../../../components/Inputs/CbRadio';
import Actions from '../../../components/Actions';
import { Member } from '../../../store/members/members.types';
import UserRole from '../../../components/UserRole';
import ClientSuperAdminRoles from './ClientSuperAdminRoles';
import AdminRoles from './AdminRoles';
import ManagerMemberRoles from './ManagerMemberRoles';
import { roles } from '../../../utils/roles.utils';
import { toastUtil } from '../../../utils/toast.util';
import images from '../../../utils/images.utils';

interface Props extends Member {
  listView: boolean;
  onUpdate: () => Promise<any>;
  invitationEmailId: number;
}

const MembersListItem = ({
  id,
  firstName,
  lastName,
  email,
  image,
  role,
  status,
  listView,
  onUpdate,
  resendInvitationEmail,
  invitationEmailId,
}: Props) => {
  const [user] = useGlobal((state) => state.auth.currentUser);
  const [disabled, setDisabled] = useState<boolean>(status !== 'ACTIVE');
  const [newRole, setNewRole] = useState<string>('');
  const [open, setOpen] = useState<boolean>(false);

  const toggle = () => setOpen(!open);

  const close = () => {
    if (open) setOpen(false);
  };

  const handleStatusChange = (evt: ChangeEvent<HTMLInputElement>) => {
    setDisabled(evt.target.checked);
  };

  const handleRoleChange = (evt: ChangeEvent<HTMLInputElement>) => {
    setNewRole(evt.target.value);
  };

  const updateUser = async () => {
    try {
      if ((status === 'ACTIVE' && disabled) || (status !== 'ACTIVE' && !disabled)) {
        await toggleUserStatus(id, disabled);
      }
      if (newRole !== '') {
        await changeRole(id, newRole);
      }
      await onUpdate();
      close();
      toastUtil('success', 'Success', '', 2000);
      setNewRole('');
    } catch (error) {
      console.warn(error);
    }
  };
  
  const resendInv = async () => {
    try {
      await resendInvitation(invitationEmailId);
      await onUpdate();
      close();
      toastUtil('success', 'Success', '', 2000);
    } catch (error) {
      console.warn(error);
    }
  };

  const disableMemberHandling = (memberRole: string) => {
    let isDisabled = false;
    if (user?.role === roles.admin && memberRole === roles.admin) {
      isDisabled = true;
    }
    if (user?.role === roles.manager && memberRole === roles.manager) {
      isDisabled = true;
    }
    if (user?.role === roles.manager && memberRole === roles.admin) {
      isDisabled = true;
    }
    return isDisabled
  }

  return (
    <S.wrapper listView={listView} disabled={status === 'INACTIVE'}>
      <S.header listView={listView} isClient={role === roles.client}>
        {image ? (
          <img src={image} alt={firstName} />
        ) : (
          <span>{`${lastName.substring(0, 2)}${firstName.substring(
            0,
            2
          )}`}</span>
        )}
      </S.header>
      <S.memberName listView={listView}>
        {firstName} {lastName}
      </S.memberName>
      <S.memberEmail listView={listView}>{email}</S.memberEmail>
      {resendInvitationEmail && (
        <S.noConfirmation listView={listView}>
          <>
            <ReactSVG src={images.papirPlaneIcon} />
            No confirmation
          </>
        </S.noConfirmation>
      )}
      <S.footer listView={listView}>
        <UserRole role={role} />
        {(role !== roles.superAdmin && user?.accountId !== id) && (
          <Actions
            listView={listView}
            toggle={toggle}
            open={open}
            close={close}
          >
            {(role === roles.client ||
              role === roles.superAdmin) && 
              <ClientSuperAdminRoles />
            }
            {role === roles.admin && (
              <AdminRoles
                role={role}
                handleRoleChange={handleRoleChange}
                newRole={newRole}
              />
            )}
            {(role === roles.manager || role === roles.member) && (
              <ManagerMemberRoles
                role={role}
                handleRoleChange={handleRoleChange}
                newRole={newRole}
              />
            )}
            <hr />
            <CbRadio
              name="disable"
              small
              onChange={handleStatusChange}
              checked={disabled}
              disabled={disableMemberHandling(role)}
            >
              Disable Member
            </CbRadio>
            <Button secondary small onClick={updateUser}>
              Confirm
            </Button>
            {resendInvitationEmail && (
              <Button small onClick={resendInv}>
                Resend invitation
              </Button>
            )}
          </Actions>
        )}
      </S.footer>
    </S.wrapper>
  );
};

export default MembersListItem;
