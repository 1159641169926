import tw, { styled, css } from 'twin.macro';
import arrow from '../../images/arrow-right.svg';

export const wrapper = styled.div(() => [
  css`
    ${tw`flex justify-center mt-30 text-textBlack`};

    .list {
      ${tw`flex items-center justify-center`}
    }

    .page {
      ${tw`flex items-center justify-center mx-3 bg-transparent text-textBlack text-24 font-semibold transition transition-colors duration-200 outline-none`}
      width: 3.2rem;
      height: 3.2rem;
      border-radius: 2px;

      &.active {
        ${tw`bg-gray6`}
      }
    }

    .previous,
    .next {
      &.disabled {
        opacity: 0.2;
      }
    }

    .prev-btn,
    .next-btn {
      display: block;
      width: 3.2rem;
      height: 3.2rem;
      margin-left: 0;
      background: url(${arrow}) no-repeat center;
      opacity: 0.5;
      outline: none;
    }

    .prev-btn {
      margin: 0 1.6rem 0 0;
      transform: rotate(180deg);
    }
  `
]);
