import React, { useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import AuthWrapper from '../../components/AuthWrapper';
import LoginForm from './LoginForm';
import { Client } from '../../store/auth/auth.types';
import { isClientAction } from '../../store/auth/auth.actions';
import LinksExpiration from './LinksExpiration';

const Login = () => {
  let location = useLocation();
  const token = location.search.split('=')[1];
  const [isClient, setIsClient] = useState<Client | null>(null);
  const [decoding, setDecoding] = useState<boolean>(!!token);
  const [linkType, setLinkType] = useState<string>('');
  const [errorMsg, setErrorMsg] = useState<string>('');

  useEffect(() => {
    let mounted = true;
    const getUserData = async () => {
      try {
        setDecoding(true);
        const userData = await isClientAction(token);
        setIsClient(userData);
        setDecoding(false);
      } catch (error) {
        setLinkType(error.meta.type);
        setErrorMsg(error.meta.message);
        setIsClient(null);
        setDecoding(false);
      }
    };
    if (token && mounted) {
      getUserData();
    }
    return () => {
      mounted = false;
    };
  }, [token]);

  return (
    <AuthWrapper>
      {linkType ? (
        <LinksExpiration linkType={linkType} errorMsg={errorMsg} />
      ) : (
        <LoginForm data={{ token, isClient, decoding }} />
      )}
    </AuthWrapper>
  );
};

export default Login;
