import tw, { styled, css } from 'twin.macro';

export const wrapper = styled.div(() => [
  css`
    ${tw`relative flex flex-col items-start border-2 min-w-full max-w-full
     mt-10 h-160 pt-9 mt-25
     border-2 border-darkBlue   
     `}
    padding-right: 2px;

    textarea {
      ${tw`flex-1 w-full h-full pl-15 text-18 font-semibold`}
      resize: none;
      outline: none;
      cursor: auto;
      ::-webkit-scrollbar {
        background-color: white;
        width: 8px;
        margin-bottom: 1rem;
      }
      ::-webkit-scrollbar-thumb {
        background-color: #205271;
        border-radius: 4px;
      }
    }
  `
]);

interface ServiceActiveProps {
  active: boolean;
}

export const icon = styled.img<ServiceActiveProps>(({ active }) => [
  css`
    ${tw`absolute cursor-pointer`}
    top: 1.5rem;
    right: 0;
  `,
  active &&
    css`
      ${tw`transform rotate-180`}
    `
]);

interface SaveFieldProps {
  isSaved: boolean;
}

export const saveField = styled.div<SaveFieldProps>(({ isSaved }) => [
  css`
    ${tw`flex items-center ml-16 bg-white`}

    svg {
      height: 1.3rem;
      width: 1.8rem;
      opacity: 0.5;
    }

    span {
      ${tw`text-18 font-semibold leading-28 ml-11 opacity-50`}
    }
  `,
  isSaved &&
    css`
      ${tw``}

      span {
        ${tw`opacity-100`}
      }

      svg {
        opacity: 1;

        path {
          fill: #009471;
        }
      }
    `
]);
