import tw, { styled, css } from 'twin.macro';
// import { StyledProps as Props } from '../../types/util.types';
import { StyledButton } from '../../../components/Button/styles';

interface RoleBasedControlProps {
  disabled?: number;
}

export const wrapper = styled.div(() => [
  css`
    ${tw`mx-30 mb-80`};

    h3 {
      ${tw`text-textBlack text-48 leading-none mb-28 `};
    }
  `
]);

export const body = styled.div(() => [
  css`
    ${tw`
        flex flex-col
        w-full 
        bg-white shadow-discipline rounded-4
        py-32
    `}
    box-shadow: 0px 2px 8px 0px rgb(0 0 0 / 15%);

    p {
      ${tw`text-textBlack text-32 leading-44 font-normal mb-44`}
    }
  `
]);

export const total = styled.div(() => [
  css`
    ${tw`border-2 border-darkBlue border-opacity-50
      flex items-center justify-between
      mr-32
      pr-16 pl-22
      self-end
      text-32 font-semibold text-textBlack
      `}
    width: 25.5rem;
    height: 4.8rem;
  `
]);

export const listWrapper = styled.div(() => [
  css`
    ${tw`
        mt-32
        px-16
    `}
  `
]);

export const listItem = styled.div(() => [
  css`
    ${tw`
        flex
        justify-between
        px-16
        border-b-2 border-gray9
        last:border-b-0
      `}
  `
]);

export const itemCost = styled.div(() => [
  css`
    ${tw`
        flex items-center
     `}

    img {
      ${tw`
            ml-20
            cursor-pointer
         `}
    }

    span {
      ${tw`flex items-center 
         text-textBlack font-semibold text-22 text-opacity-75`}

      svg {
        width: 2rem;
        margin-left: 0.5rem;
        path {
          fill: rgba(30, 68, 92, 0.75);
        }
      }
    }
  `
]);

export const costActionImage = styled.img<RoleBasedControlProps>(
  ({ disabled }) => [
    disabled === 1 &&
      css`
        ${tw`pointer-events-none opacity-50`}
      `
  ]
);

export const itemTitle = styled.span(() => [
  css`
    ${tw`
        py-17
        text-24 text-darkBlue leading-32 font-semibold
      `}
  `
]);

export const addCostWrap = styled.span<RoleBasedControlProps>(
  ({ disabled }) => [
    css`
      ${tw`flex items-center
        text-22 font-semibold text-darkBlue ml-52 mt-32
        cursor-pointer
      `}

      img {
        ${tw`mr-13`}
        width: 2.8rem;
      }
    `,
    disabled === 1 &&
      css`
        ${tw`pointer-events-none opacity-50`}
      `
  ]
);

export const modalWrapper = styled.div(() => [
  css`
    ${tw`flex flex-col items-start bg-gray10 border-2 border-darkBlue w-730 max-w-full
        p-32`}

    h3 {
      ${tw`
          text-textBlack text-32 leading-36 font-semibold 
          mb-0`}
    }
    ${StyledButton} {
      ${tw`ml-auto mt-26`}
    }
  `
]);

export const inputWrapper = styled.div(() => [
  css`
    ${tw`
        grid grid-cols-2 gap-16
        w-full
        mt-30
     `}
  `
]);

export const form = styled.form(() => [
  css`
    ${tw`
        w-full
     `}
  `
]);

export const deleteModal = styled.div(() => [
  css`
    ${tw`flex flex-col items-start bg-gray10 border-2 border-darkBlue max-w-full
        py-32 px-20
        `}
    width: 48rem;

    p {
      ${tw`
          text-textBlack text-32 leading-36 font-semibold 
          mb-0`}
    }

    div {
      ${tw`
        flex justify-end w-full
        mt-28
      `}

      button {
        ${tw`first:mr-15`}
      }
    }
  `
]);
