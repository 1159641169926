import React from 'react';
import Dropdown from '../../../components/Dropdown';
import { methodLevels } from '../../../store/method-service/types';
import { DropdownCbOption, SetStateType } from '../../../types/util.types';
import * as S from './method-level';

interface Props {
  selected: DropdownCbOption[];
  onAdd: SetStateType<DropdownCbOption[]>;
  label: string;
  disabled?: boolean;
}

const MethodLevels = ({ selected, onAdd, label, disabled }: Props) => {
  return (
    <S.wrapper>
      <Dropdown
        labelColor="#1e445c"
        label={label}
        options={methodLevels}
        values={selected}
        setValues={onAdd}
        disabled={disabled}
      />
    </S.wrapper>
  );
};

export default MethodLevels;
