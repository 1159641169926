import React, { useState, ChangeEvent, FormEvent } from 'react';
import { useParams } from 'react-router-dom';
import * as S from './external-cost';
import Button from '../../../components/Button/Button';
import TextInput from '../../../components/Inputs/TextInput';
import {
  createExternalCost,
  editExternalCost
} from '../../../store/projects/projects.actions';
import { ExternalCostType } from '../../../store/projects/projects.types';
import { validatePd } from '../../../utils/personDays.utils';

interface InputState {
  name: string;
  cost: string;
}

interface Params {
  id: string;
}

interface Props {
  onSuccess: (costs: ExternalCostType | ExternalCostType[]) => void;
  cost: ExternalCostType | null;
}

const AddCost = ({ onSuccess, cost }: Props) => {
  const { id } = useParams<Params>();
  const [values, setValues] = useState<InputState>({
    name: cost?.name || '',
    cost: cost?.price.toString() || ''
  });
  const [submitting, setSubmitting] = useState<boolean>(false);

  const changeHandler = (event: ChangeEvent<HTMLInputElement>) => {
    const { name, value } = event.target;
    if (name === 'cost' && !validatePd(value)) return;

    setValues({
      ...values,
      [name]: value
    });
  };

  const onSubmit = async (event: FormEvent) => {
    event.preventDefault();
    try {
      setSubmitting(true);
      const data = cost
        ? await editExternalCost(id, cost.id, values.name, values.cost)
        : await createExternalCost(id, values.name, values.cost);
      onSuccess(data);
    } catch {
      setSubmitting(false);
    }
  };

  return (
    <S.modalWrapper>
      <h3>{cost ? 'Edit' : 'Add'} External Cost</h3>
      <S.form onSubmit={onSubmit}>
        <S.inputWrapper>
          <TextInput
            value={values.name}
            onChange={changeHandler}
            name="name"
            label="Cost name"
            disableAutoComplete
          />
          <TextInput
            value={values.cost}
            onChange={changeHandler}
            name="cost"
            label="Price"
            disableAutoComplete
          />
        </S.inputWrapper>
        <Button
          type="submit"
          secondary
          disabled={!values.name || !values.cost || submitting}
        >
          Confirm
        </Button>
      </S.form>
    </S.modalWrapper>
  );
};

export default AddCost;
