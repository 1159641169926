import React, { useState, ChangeEvent, useRef, useEffect } from 'react';
import useOnClickOutside from 'use-onclickoutside';

import { ReactComponent as Appointment } from '../../../images/calendar.svg';
import { ReactComponent as OfferAccept } from '../../../images/confirmed.svg';
import { ReactComponent as Document } from '../../../images/list.svg';
import { ReactComponent as WeCare } from '../../../images/we-care.svg';
import { ReactComponent as MessageSent } from '../../../images/paper-plane.svg';
import { ReactComponent as Call } from '../../../images/call.svg';
import { ReactComponent as Edits } from '../../../images/edit.svg';
import { ReactComponent as PersonalMeet } from '../../../images/speech-bubble.svg';
import { ReactComponent as Fair } from '../../../images/fair.svg';
import { ReactComponent as Comment } from '../../../images/commentary.svg';

import { EventIcon } from '../../../store/projects/projects.types';
import { SetStateType } from '../../../types/util.types';

import * as S from './add-event';

const iconList: EventIcon[] = [
  {
    value: 'date',
    label: 'Date',
    icon: Appointment
  },
  {
    value: 'accepted',
    label: 'Accepted',
    icon: OfferAccept
  },
  {
    value: 'document',
    label: 'Document',
    icon: Document
  },
  {
    value: 'weCare',
    label: 'We Care',
    icon: WeCare
  },
  {
    value: 'message',
    label: 'Message (Sent)',
    icon: MessageSent
  },
  {
    value: 'call',
    label: 'Call',
    icon: Call
  },
  {
    value: 'edit',
    label: 'Edit',
    icon: Edits
  },
  {
    value: 'talkMeeting',
    label: 'Talk/Meeting',
    icon: PersonalMeet
  },
  {
    value: 'fair',
    label: 'Fair',
    icon: Fair
  },
  {
    value: 'comment',
    label: 'Comment',
    icon: Comment
  }
];

interface Props {
  selected: EventIcon | null;
  setSelected: SetStateType<EventIcon | null>;
}

const IconSearch = ({ selected, setSelected }: Props) => {
  const ref = useRef(null);
  const [value, setValue] = useState<string>('');
  const [active, setActive] = useState<boolean>(false);

  const toggle = () => setActive(!active);
  const close = () => setActive(false);

  useOnClickOutside(ref, close);

  const onChange = (event: ChangeEvent<HTMLInputElement>) => {
    setValue(event.target.value);
    setActive(true);
  };

  const onSelect = (icon: EventIcon) => {
    close();
    setSelected(icon);
    setValue(icon.label);
  };

  useEffect(() => {
    if (value !== selected?.label) setSelected(null);
    // eslint-disable-next-line
  }, [value]);

  const list: EventIcon[] = iconList.filter((i: EventIcon) =>
    i.label.toLowerCase().includes(value.toLowerCase())
  );

  return (
    <S.iconSearchWrapper ref={ref}>
      <S.iconInput onClick={toggle}>
        <input value={value} onChange={onChange} />
        <S.iconSearchLabel hasValue={!!value} open={active}>
          Icon Name
        </S.iconSearchLabel>
        {selected && <selected.icon />}
      </S.iconInput>
      {list.length > 0 && active && (
        <S.iconList>
          {list.map((icon: EventIcon) => (
            <span key={icon.value} onClick={() => onSelect(icon)}>
              {icon.label}
              <icon.icon />
            </span>
          ))}
        </S.iconList>
      )}
    </S.iconSearchWrapper>
  );
};

export default IconSearch;
