import tw, { styled, css } from 'twin.macro';
import {
  wrapper as dateInputWrapper,
  dropdown as dateInputDropdown,
  label as dateInputLabel,
  input as dateInputBox
} from '../../../components/Inputs/date-input';

export const wrapper = styled.div(() => [
  css`
    ${tw`mx-30 mb-80`};

    h3 {
      ${tw`text-textBlack text-48 leading-none mb-28`};
    }
  `
]);

export const body = styled.div(() => [
  css`
    ${tw`pb-20 bg-white shadow-discipline rounded-4`};
    overflow: initial;
  `
]);

export const header = styled.div(() => [
  css`
    ${tw`flex flex-col sm:flex-row justify-between px-16 md:px-32 pt-32 pb-41`};

    > div {
      ${tw`flex flex-col xxl:flex-row justify-between first:mb-16 first:sm:mb-0`}
    }

    ${dateInputWrapper} {
      ${tw`xxl:mr-16 mb-16 xxl:mb-0 w-full sm:w-200`}
    }

    ${dateInputDropdown} {
      ${tw`left-0`}
      min-width: 35rem;
    }

    ${dateInputLabel} {
      ${tw`pt-5`}
    }

    ${dateInputBox} {
      ${tw`justify-end pb-6`}
    }
  `
]);

interface HeaderBoxProps {
  disabled?: boolean;
  hasValue: boolean;
}

export const headerBox = styled.div<HeaderBoxProps>(
  ({ disabled, hasValue }) => [
    css`
      ${tw`flex flex-col items-start justify-center w-full sm:w-200 px-16 text-22 rounded-2 leading-23 font-semibold text-black bg-darkBlue bg-opacity-10`};
      height: 6.4rem;

      span {
        ${tw`text-22 leading-16 font-semibold pt-5 text-textBlack text-opacity-50`}
      }

      &:first-child:not(:only-child) {
        ${tw`xxl:mr-16 mb-16 xxl:mb-0`}
      }
    `,
    disabled &&
      css`
        ${tw`pointer-events-none opacity-50`}
      `,
    hasValue &&
      css`
        span {
          ${tw`mb-6 text-14 leading-16 font-semibold text-textBlack text-opacity-50`}
        }
      `
  ]
);

export const chart = styled.div(() => [
  css`
    ${tw`flex w-full items-start`};
  `
]);

export const chartSidebar = styled.div(() => [
  css`
    ${tw`flex relative border-r border-darkBlue border-opacity-25`};
    margin-top: 13rem;
  `
]);

export const sidebarPhaseWrapper = styled.div(() => [
  css`
    ${tw`flex flex-col`}
  `
]);

export const sidebarPhase = styled.div(() => [
  css`
    ${tw`flex items-center w-full h-104 px-32 text-textBlack text-24 leading-32 font-semibold `}
    white-space: nowrap;

    span {
      ${tw`relative z-10`}
    }

    &:nth-child(odd):after {
      ${tw`block absolute left-0 right-0 h-104 bg-darkBlue bg-opacity-05`}
      content: '';
    }
  `
]);

export const chartMain = styled.div(() => [
  css`
    ${tw`relative flex flex-col flex-1 overflow-auto pb-20`};
    padding-top: 8.2rem;
    min-width: 40rem;
  `
]);

export const chartHeader = styled.div(() => [
  css`
    ${tw`flex`};
  `
]);

export const chartBody = styled.div(() => [
  css`
    ${tw`min-w-full`};
  `
]);

export const chartWeek = styled.div(() => [
  css`
    ${tw`min-w-100 max-w-100 h-48 text-textBlack text-18 leading-28 font-semibold uppercase`};

    &:not(:first-child) {
      &:before {
        ${tw`absolute bg-darkBlue bg-opacity-25`}
        content: '';
        top: 13rem;
        width: 1px;
        bottom: 2rem;
      }
    }
  `
]);

export const chartRow = styled.div(() => [
  css`
    ${tw`flex items-center h-104`};
    &:nth-child(odd) {
      ${tw`bg-darkBlue bg-opacity-05`}
    }
    &:nth-child(n + 1) {
      ${phase} {
        ${tw`bg-darkBlue`}
      }
    }
    &:nth-child(2n) {
      ${phase} {
        ${tw`bg-green2`}
      }
    }
    &:nth-child(3n) {
      ${phase} {
        ${tw`bg-green3`}
      }
    }
    &:nth-child(4n) {
      ${phase} {
        ${tw`bg-darkBlue4`}
      }
    }
  `
]);

interface PhaseProps {
  isGrayedOut: boolean;
}

export const phase = styled.div<PhaseProps>(({ isGrayedOut }) => [
  css`
    ${tw`relative z-30 flex items-center justify-around h-48 px-17 rounded-24 bg-green text-white text-24`}
    width: 30rem;
  `,
  isGrayedOut &&
    css`
      ${tw`bg-darkBlue! bg-opacity-20!`}
    `
]);

export const method = styled.div(() => [
  css`
    ${tw`w-24 h-24 bg-white bg-opacity-50 rounded-50`};
  `
]);

export const sidebarEvents = styled.div(() => [
  css`
    ${tw`flex relative z-10 pl-16 md:pl-32 -mb-20`}
    margin-top: -13rem;

    ${event} {
      ${tw`mr-24 last:mr-0`}
    }
  `
]);

interface EventProps {
  iconColor: string;
  inChart: boolean;
  showFullEvent: boolean;
}

export const event = styled.div<EventProps>(
  ({ iconColor, inChart, showFullEvent }) => [
    css`
      ${tw`flex flex-col text-14 font-semibold leading-16 pl-6 text-textBlack z-1 hover:z-20`}
      word-break: break-word;
      width: 10rem;

      svg {
        ${tw`mb-8`}
        path {
          fill: ${iconColor};
        }
      }
    `,
    inChart &&
      css`
        ${tw`absolute top-0 bottom-0`}
      `,
    inChart &&
      !showFullEvent &&
      css`
        ${tw`z-20 hover:z-25`}
        width: 0;
      `
  ]
);

interface EventLineProps {
  lineColor: string;
  isSidebar: boolean;
}

export const eventLine = styled.div<EventLineProps>(
  ({ lineColor, isSidebar }) => [
    css`
      ${tw`h-full absolute top-0 left-0 px-1 pointer-events-auto`}
      background: ${lineColor};

      &:hover + div {
        ${tw`visible opacity-100`}
      }

      &:hover div {
        ${tw`visible opacity-100`}
      }
    `,

    !isSidebar &&
      css`
        ${tw`cursor-pointer`}
      `
  ]
);

export const eventIconColoredWrap = styled.div(() => [
  css`
    ${tw`bg-white -ml-2 z-10 relative invisible opacity-0 transition duration-300 ease-in-out`}
    width: 6rem;
  `
]);

export const deleteEventColoredWrap = styled.div(() => [
  css`
    ${tw`bg-white absolute bottom-0 ml-2 z-10 invisible opacity-0 transition duration-300 ease-in-out`}
    width: 3.2rem;
    height: 3.2rem;
  `
]);

export const deleteEventWrap = styled.div(() => [
  css`
    ${tw`absolute bottom-0`}
    width: 3.2rem;
    height: 3.2rem;

    img {
      ${tw`cursor-pointer`}
    }
  `
]);

export const deleteEventModal = styled.div(() => [
  css`
    ${tw`flex flex-col items-start bg-gray10 border-2 border-darkBlue max-w-full py-32 px-20`}
    width: 48rem;

    p {
      ${tw`text-textBlack text-32 leading-36 font-semibold mb-0`}
    }

    div {
      ${tw`flex justify-end w-full mt-28`}

      button {
        ${tw`first:mr-15`}
      }
    }
  `
]);

export const dateWarning = styled.div(() => [
  css`
    ${tw`flex flex-col space-y-40 items-start bg-gray10 border-2 border-darkBlue max-w-full
        py-32 px-20`}

    p {
      ${tw`
          text-textBlack text-32 leading-36 font-semibold 
          mb-0`}
    }

    button {
      ${tw`justify-end mt-15 ml-auto`}
    }
  `
]);
