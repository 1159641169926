import React from 'react';
import { NavLink } from 'react-router-dom';
import { ReactSVG } from 'react-svg';
import useGlobal from '../../store';
import * as S from './app-sidebar';
import { UserRoles, AuthRoutes } from '../../utils/routeEnums.utils'
import images from '../../utils/images.utils';

const links = [
  {
    url: AuthRoutes.clientProjects,
    label: 'Project proposals',
    icon: images.projectIcon,
    roles: [String(UserRoles.client)]
  },
  {
    url: AuthRoutes.project,
    label: 'Projects',
    icon: images.projectIcon,
    roles: [String(UserRoles.superAdmin), String(UserRoles.admin), String(UserRoles.manager), String(UserRoles.member)]
  },
  {
    url: AuthRoutes.clients,
    label: 'Client Invitation',
    icon: images.invIcon,
    roles: [String(UserRoles.superAdmin), String(UserRoles.admin), String(UserRoles.manager)]
  },
  {
    url: AuthRoutes.members,
    label: 'Members',
    icon: images.userOutline,
    roles: [String(UserRoles.superAdmin), String(UserRoles.admin), String(UserRoles.manager)]
  },
  {
    url: AuthRoutes.services,
    label: 'Services',
    icon: images.serviceIcon,
    roles: [String(UserRoles.superAdmin), String(UserRoles.admin), String(UserRoles.manager)]
  }
];

interface Props {
  toggleMenu?: () => void;
}

const Nav = ({ toggleMenu }: Props) => {
  const [user] = useGlobal((state) => state.auth.currentUser);
 
  const renderNavigation = () => {
    if (!user) {
      return null
    }
    return (
      links.filter((l:{ roles: any }) => l.roles.includes(user.role))
      .map((l: { url: string; label: string; icon: string }) => (
        <NavLink
          to={l.url}
          activeClassName="active"
          key={l.url}
          onClick={toggleMenu}
        >
          <ReactSVG src={l.icon} />
          {l.label}
        </NavLink>
      ))
    )
  }
 
  return (
    <S.navigation>
      {renderNavigation()}
    </S.navigation>
  );
}

export default Nav;
