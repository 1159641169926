import React, { useState, ChangeEvent } from 'react';
import { useParams } from 'react-router-dom';
import { ProjectProgress } from '../../../store/projects/projects.types';
import TextInput from '../../../components/Inputs/TextInput';
import IconSearch from './IconSearch';
import DateInput from '../../../components/Inputs/DateInput';
import Button from '../../../components/Button';
import { EventIcon } from '../../../store/projects/projects.types';
import { addEvent } from '../../../store/projects/projects.actions';
import { startOfDay } from 'date-fns';

import * as S from './add-event';

interface Props {
  onSuccess: (data: ProjectProgress) => void;
  startDate?: string;
  endDate?: string;
}

const colorBlue = 'BLUE';
const colorRed = 'RED';

interface Params {
  id: string;
}

const EventForm = ({ onSuccess, startDate, endDate }: Props) => {
  const { id } = useParams<Params>();
  const [name, setName] = useState<string>('');
  const [iconColor, setIconColor] = useState<string>('');
  const [lineColor, setLineColor] = useState<string>('');
  const [selectedIcon, setSelectedIcon] = useState<EventIcon | null>(null);
  const [submitting, setSubmitting] = useState<boolean>(false);
  const [date, setDate] = useState<Date | null>(
    startDate && endDate ? new Date() : null
  );

  const changeHandler = (event: ChangeEvent<HTMLInputElement>) => {
    if (event.target.value.length > 20) return;
    setName(event.target.value);
  };

  const onSubmit = async () => {
    try {
      setSubmitting(true);
      const data = await addEvent(
        id,
        name,
        selectedIcon!.value,
        iconColor,
        lineColor,
        date
      );
      onSuccess(data);
    } catch {
      setSubmitting(false);
    }
  };

  return (
    <S.wrapper>
      <h3>Create Event</h3>
      <TextInput
        value={name}
        name="name"
        label="Event name"
        onChange={changeHandler}
        disableAutoComplete
      />
      <IconSearch selected={selectedIcon} setSelected={setSelectedIcon} />
      {startDate && endDate && (
        <DateInput
          before={startDate}
          after={endDate}
          setDate={setDate}
          date={date}
        />
      )}
      <h4>Choose icon color</h4>
      <S.colorPicker>
        <S.colorBox
          active={iconColor === colorBlue}
          color={colorBlue}
          onClick={() => setIconColor(colorBlue)}
        />
        <S.colorBox
          active={iconColor === colorRed}
          color={colorRed}
          onClick={() => setIconColor(colorRed)}
        />
      </S.colorPicker>
      <h4>Choose line color</h4>
      <S.colorPicker>
        <S.colorBox
          active={lineColor === colorBlue}
          color={colorBlue}
          onClick={() => setLineColor(colorBlue)}
        />
        <S.colorBox
          active={lineColor === colorRed}
          color={colorRed}
          onClick={() => setLineColor(colorRed)}
        />
      </S.colorPicker>
      <h4>Review your choice</h4>
      <S.review lineColor={lineColor} iconColor={iconColor}>
        <div>
          {selectedIcon && <selectedIcon.icon />}
          {name}
        </div>
      </S.review>
      <Button
        small
        onClick={onSubmit}
        disabled={
          !name ||
          !iconColor ||
          !lineColor ||
          submitting ||
          (!!startDate && !date) ||
          !selectedIcon ||
          !!(
            startDate &&
            endDate &&
            date &&
            (new Date(startDate).getTime() > date.getTime() ||
              startOfDay(new Date(endDate)).getTime() <
                startOfDay(date).getTime())
          )
        }
      >
        Add event
      </Button>
    </S.wrapper>
  );
};

export default EventForm;
