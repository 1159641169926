import React from 'react';
import CbRadio from '../../../components/Inputs/CbRadio';
import { roles, roleLabels } from '../../../utils/roles.utils';

const ClinetSuperAdminRoles = () => {
  return (
    <>
      <CbRadio
        value={roles.admin}
        name="role"
        radio
        small
        disabled={true}
        checked={false}
      >
        {roleLabels.ROLE_ADMIN}
      </CbRadio>
      <CbRadio
        value={roles.manager}
        name="role"
        radio
        small
        disabled={true}
        checked={false}
      >
        {roleLabels.ROLE_MANAGER}
      </CbRadio>
      <CbRadio
        value={roles.member}
        name="role"
        radio
        small
        disabled={true}
        checked={false}
      >
        {roleLabels.ROLE_PROJECT_MEMBER}
      </CbRadio>
    </>
  );
};

export default ClinetSuperAdminRoles;
