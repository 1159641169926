import React, { useContext } from 'react';
import { ReactSVG } from 'react-svg';
import { ExternalCostType } from '../../../store/projects/projects.types';
import { ProgressContext } from '../Progress';
import * as S from './external-cost';
import images from '../../../utils/images.utils';

interface Props {
  onDelete: (id: number) => void;
  cost: ExternalCostType;
  onEdit: (cost: ExternalCostType) => void;
}

const CostItem = ({ onDelete, onEdit, cost }: Props) => {
  const { isMemberRole } = useContext(ProgressContext);
  return (
    <S.listItem>
      <S.itemTitle>{cost.name}</S.itemTitle>
      <S.itemCost>
        <span>
          {cost.price}
          <ReactSVG wrapper="span" src={images.costIcon} />
        </span>
        <S.costActionImage
          src={images.edit}
          alt="Edit Cost"
          onClick={() => onEdit(cost)}
          disabled={isMemberRole ? 1 : 0}
        />
        <S.costActionImage
          src={images.delete}
          alt="Remove Cost"
          onClick={() => onDelete(cost.id)}
          disabled={isMemberRole ? 1 : 0}
        />
      </S.itemCost>
    </S.listItem>
  );
};

export default CostItem;
