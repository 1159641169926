import tw, { styled, css } from 'twin.macro';
import { StyledProps as Props } from '../../../types/util.types';

export const wrapper = styled.div<Props>(
  ({
    theme: {
      media: {
        breakpoints: { smMax }
      }
    },
    maxWidth,
    service,
    disabled
  }) => [
    css`
      ${tw`w-full mb-20`}
      max-width: ${maxWidth ? maxWidth : '35rem'};

      textarea {
        ${tw`w-full px-15 py-5 border-2 border-solid border-textBlack text-18 leading-28 font-semibold outline-none`}
        min-height: 120px;
      }

      &:not(:last-child) {
        ${tw`md:mr-30`}
      }

      @media screen and (max-width: ${smMax}px) {
        max-width: none;
      }
    `,
    service &&
      css`
        ${tw`lg:mr-30`}
      `,
    disabled &&
      css`
        opacity: 0.5;
        pointer-events: none;

        textarea {
          resize: none;
        }
      `
  ]
);
