import React, { ChangeEvent } from 'react';
import * as S from './summary-contact';
import useGlobal from '../../../store';

import {
  ProjectSummaryFooterType,
  ProjectProgressDuration
} from '../../../store/projects/projects.types';
import { CbRadio } from '../../../components/Inputs';
import { isClientRole } from '../../../utils/index';
import images from '../../../utils/images.utils';
import { format } from 'date-fns';
interface Props {
  data: ProjectSummaryFooterType;
  projectName: string;
  duration: ProjectProgressDuration | null;
  showCostToClient: boolean;
  setShowCostToClient: any;
}

const SummaryContact = ({
  data: { updater, updateDate, isCostHidden, cost },
  projectName,
  duration,
  showCostToClient,
  setShowCostToClient
}: Props) => {
  const [user] = useGlobal((state) => state.auth.currentUser);
  const renderClientCost = () => {
    if (isClientRole(user) && !isCostHidden) {
      return (
        <S.effortCard>
          <img src={images.cost} alt="cost" />
          <div>
            <span>{cost || ''}</span>
            <span>Cost</span>
          </div>
        </S.effortCard>
      );
    }
    return null;
  };

  return (
    <>
      <S.contactEffortWrap>
        <S.effortWrap>
          <h1>Project Effort</h1>
          <S.effortBody>
            <S.effortWrapCards>
              <S.effortCard>
                <img src={images.userOutline} alt="days" />
                <div>
                  <span>{duration && duration.personDays.toFixed(2)}</span>
                  <span>Person-Days</span>
                </div>
              </S.effortCard>

              {showCostToClient && !isClientRole(user) && (
                <S.effortCard>
                  <img src={images.cost} alt="cost" />
                  <div>
                    <span>{cost}</span>
                    <span>Cost</span>
                  </div>
                </S.effortCard>
              )}
              {renderClientCost()}
            </S.effortWrapCards>
            <S.effortStaticText>
              incl. Project Management, Quality Measurements and specifically
              listed external Costs without VAT
            </S.effortStaticText>
            {!isClientRole(user) && (
              <S.buttonLabel>
                <CbRadio
                  name="cost"
                  small
                  checked={showCostToClient}
                  onChange={(evt: ChangeEvent<HTMLInputElement>) => {
                    setShowCostToClient(evt.target.checked);
                  }}
                >
                  <S.checkboxText>Show total cost</S.checkboxText>
                </CbRadio>
              </S.buttonLabel>
            )}
          </S.effortBody>
        </S.effortWrap>
        <S.contactWrap>
          <h1>Contact</h1>
          <S.contactBody>
            <S.contactPicture src={updater?.image || ''} />
            <S.contactInfo>
              <h6>{`${updater?.firstName} ${updater?.lastName}`}</h6>
              <h4>{updater?.position || 'Test'}</h4>
              <S.contactMail>
                <img src={images.mail} alt="email" />
                <span>{updater?.email || ''}</span>
              </S.contactMail>
            </S.contactInfo>
          </S.contactBody>
        </S.contactWrap>
        <S.footedBottomWrap>
          <span>Clever Project Composer</span>
          <span>{projectName}</span>
          <div>
            <span>Creation Date</span>
            <span>{format(new Date(updateDate), 'dd/MM/yyyy')}</span>
          </div>
        </S.footedBottomWrap>
      </S.contactEffortWrap>
    </>
  );
};

export default SummaryContact;
