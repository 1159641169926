import tw, { styled, css } from 'twin.macro';
import { StyledProps as Props } from '../../types/util.types';

export const Wrapper = styled.div<Props>(({ isAllDisabled }) => [
  css`
    ${tw` w-full `};
  `,
  isAllDisabled &&
    css`
      ${tw`opacity-50 pointer-events-none`}
    `
]);
export const InputListRow = styled.div(() => [
  css`
    ${tw`flex flex-col w-full`}
    > div {
      &:first-child {
        ${tw`max-w-full md:max-w-59`}
      }
      &:not(:first-child) {
        ${tw`max-w-full md:max-w-5xl`}
      }
    }
  `
]);

export const InputItem = styled.div(() => [
  css`
    ${tw`flex w-full items-center  md:justify-between flex-wrap lg:flex-no-wrap mb-30`}

    > div {
      ${tw`w-full md:max-w-35`}
    }

    img {
      ${tw`ml-20 mr-auto cursor-pointer`}
    }
    @media screen and (max-width: 768px) {
      > div {
        ${tw`mb-10`}
      }
    }
  `
]);
