import React from 'react';
import { ProjectClientType } from '../../../store/projects/projects.types';
import useGlobal from '../../../store';
import * as S from './project-summary-header';
import logo from '../../../images/logo-white.svg';
import consulteerLogo from '../../../images/consulteer-logo-2020-white-transparent-800w.png';

interface Props {
  name: string;
  imageUrl: string | null;
  clientData: ProjectClientType | null;
}

const ProjectSummaryHeader = ({ name, clientData, imageUrl }: Props) => {
  const [user] = useGlobal((state) => state.auth.currentUser);

  return (
    <S.wrapper>
      <S.header>
        <h2>{name}</h2>
        <img
          src={user?.email.includes('@consulteer.com') ? consulteerLogo : logo}
          alt="logo"
        />
      </S.header>
      <S.body>
        {imageUrl && <img src={imageUrl} alt={name} />}

        <S.clientBox>
          {clientData && (
            <>
              <div>
                {clientData.image ? (
                  <img src={clientData.image} alt={clientData.firstName} />
                ) : (
                  <span>
                    {`${clientData.lastName.substring(
                      0,
                      2
                    )}${clientData.firstName.substring(0, 2)}`}
                  </span>
                )}
              </div>
              <p>
                {clientData.firstName} {clientData.lastName}{' '}
              </p>{' '}
              <span>{clientData.company.name}</span>
            </>
          )}
        </S.clientBox>
      </S.body>
    </S.wrapper>
  );
};

export default ProjectSummaryHeader;
