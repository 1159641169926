import tw, { styled, css } from 'twin.macro';
import { StyledProps as Props } from '../../types/util.types';

const StyledTextInput = styled.div<Props>(({ isClient, small, profile }) => [
  css`
    ${tw`relative w-full`}
    ${!profile && 'padding-bottom: 2.8rem;'}

    input {
      ${tw`w-full relative outline-none bg-transparent font-semibold z-5 text-black text-2xl md:text-4xl border-darkBlue border-2 border-solid`}
      height: ${small ? '4.8rem' : '6.4rem'};
      padding: 1.6rem 1.6rem 1.6rem;
      transition: all 0.3s;
      line-height: 3.2rem;
      ${isClient &&
      css`
        padding-left: 4.5rem;
      `}

      &:not(:active):focus {
        + label {
          ${tw`text-blue`}
        }
      }
    }

    label {
      ${tw`absolute top-0`}
      left: 1.8rem;
      color: #929da2;
      transform: translateY(1.6rem);
      transition: all 0.3s;
      font-size: 2.2rem;
      line-height: 3.2rem;
      font-weight: 600;
      ${(p: any) =>
        p.isClient &&
        css`
          padding-left: 3rem;
        `}
    }

    p {
      ${tw`text-lg md:text-2xl text-error`}
      ${profile ? tw`relative` : tw`absolute bottom-0 left-0`}
      /* ${tw`absolute bottom-0 left-0 text-lg md:text-2xl text-error`} */
    }
  `
]);

export const InputWrapper = styled.div<Props>(
  ({ disabled, hasValue, invalid, isClient }) => [
    css`
      ${tw`flex relative w-full`}
      background: #fff;
      transition: all 0.3s;

      > div,
      > svg {
        ${tw`absolute z-10`}
        top: 50%;
        left: 1.6rem;
        height: 2.4rem;
        transform: translateY(-50%);
        cursor: pointer;

        ~ input {
          padding-right: 5.6rem;
        }
      }
      > svg {
        left: auto;
        right: 1.6rem;
      }
    `,
    !disabled &&
      !invalid &&
      css`
        &:hover {
          background: rgba(30, 68, 92, 0.15);
        }
      `,
    disabled &&
      css`
        opacity: 0.5;
      `,
    invalid &&
      css`
        ${tw`bg-error`}

        &:hover {
          input {
            color: #fff;
          }
        }

        &:not(:hover) {
          input,
          input:focus {
            color: #fff;
          }
        }

        input:not(:active):focus,
        input:focus {
          ~ label {
            color: rgba(255, 255, 255, 0.5);
          }
        }

        svg {
          path {
            fill: rgba(255, 255, 255, 0.5);
            transition: all 0.3s;
          }

          #Rectangle,
          #Rectangle-Copy-2,
          #Rectangle-Copy-3 {
            fill: rgba(255, 255, 255, 0.5);
            transition: all 0.3s;
          }
        }

        label {
          color: rgba(255, 255, 255, 0.5);
        }

        input {
          ${tw`border-error`};
        }
      `,
    hasValue &&
      css`
        label {
          transform: translateY(0.6rem);
          font-size: 1.4rem;
          line-height: 1;
        }
      `
  ]
);

export const PasswordVisibility = styled.span(() => [
  css`
    ${tw`absolute z-10`}
    top: 50%;
    right: 1.6rem;
    height: 2.4rem;
    transform: translateY(-50%);
    cursor: pointer;
  `
]);

export default StyledTextInput;
