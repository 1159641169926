import tw, { styled, css } from 'twin.macro';

export const wrapper = styled.div(() => [
  css`
    ${tw`mx-16 mb-30 sm:mx-37 sm:mb-60`};

    h3 {
      ${tw`text-textBlack text-14 sm:text-24 font-bold leading-none mb-18 sm:mb-28`};
    }

    @media print {
      page-break-inside: avoid;
      padding-top: 3rem;
    }
  `
]);

interface ChartWrapperEvents {
  hasEvents?: boolean;
}

export const chartWrapper = styled.div<ChartWrapperEvents>(({ hasEvents }) => [
  css`
    ${tw`py-40 bg-white rounded-1.5 border border-gray9`};
  `,
  hasEvents &&
    css`
      ${tw`pt-80`}
      @media screen and (max-width: 575px) and (min-width: 320px) {
        ${tw`py-40`}
      }
    `
]);

export const chart = styled.div(() => [
  css`
    ${tw`flex w-full items-start pr-56`};
    background: repeating-linear-gradient(
      180deg,
      #f3f4f5,
      #f3f4f5 5.6rem,
      white 5.6rem,
      white 11.2rem
    );
  `
]);

export const sidebar = styled.div(() => [
  css`
    ${tw`flex relative border-r border-darkBlue border-opacity-25`};
  `
]);

export const sidebarPhaseWrap = styled.div(() => [
  css`
    ${tw`flex flex-col`}
  `
]);

export const siderBarEventWrap = styled.div(() => [
  css`
    ${tw`flex`}
    margin-top: -6.5rem;
    height: 6.5rem;
    @media screen and (max-width: 575px) and (min-width: 320px) {
      display: none;
    }
  `
]);

export const eventWrap = styled.div(() => [
  css`
    ${tw`absolute flex w-full`}
    top: 0;
    margin-top: -6.5rem;
    height: 6.5rem;

    @media screen and (max-width: 575px) and (min-width: 320px) {
      display: none;
    }
  `
]);

interface EventProps {
  iconColor: string;
  inChart: boolean;
}

export const summaryEvent = styled.div<EventProps>(({ iconColor, inChart }) => [
  css`
    ${tw`relative flex flex-col text-12 font-semibold leading-16 pl-6 text-textBlack`}
    word-break: break-word;
    width: 8rem;
    height: auto;

    svg {
      ${tw`mb-6`}
      height: 2rem;
      width: 2rem;
      path {
        fill: ${iconColor};
      }
    }
    @media screen and (max-width: 575px) and (min-width: 375px) {
      display: none;
    }
  `,

  inChart &&
    css`
      ${tw`absolute`}
      width: 8rem;
      height: 6.5rem;
      @media screen and (max-width: 575px) and (min-width: 320px) {
        display: none;
      }
    `
]);

interface EventLineProps {
  lineColor: string;
}

export const summaryEventLine = styled.div<EventLineProps>(({ lineColor }) => [
  css`
    ${tw`h-full absolute top-0 left-0 px-1`}
    background: ${lineColor};
  `
]);

export const sidebarPhase = styled.div(() => [
  css`
    ${tw`flex items-center w-full h-56 sm:px-25 text-textBlack sm:text-18 sm:leading-32 text-12 px-10 font-bold`}
  `
]);

export const main = styled.div(() => [
  css`
    ${tw`relative flex flex-col flex-1`};
  `
]);

export const chartRow = styled.div(() => [
  css`
    ${tw`relative flex items-center h-56`};

    > span {
      white-space: nowrap;
    }

    &:nth-child(n + 1) {
      ${bar} {
        ${tw`bg-darkBlue`}
      }
    }
    &:nth-child(2n) {
      ${bar} {
        ${tw`bg-green2`}
      }
    }
    &:nth-child(3n) {
      ${bar} {
        ${tw`bg-green3`}
      }
    }
    &:nth-child(4n) {
      ${bar} {
        ${tw`bg-darkBlue4`}
      }
    }
  `
]);

interface PhaseProps {
  isGrayedOut: boolean;
}

export const bar = styled.div<PhaseProps>(({ isGrayedOut }) => [
  css`
    ${tw`absolute z-30 rounded-24 bg-green`}
    min-width: 3rem;
    height: 2.2rem;
    @media screen and (max-width: 575px) and (min-width: 320px) {
      height: 1.5rem;
      top: 3rem;
    }
  `,
  isGrayedOut &&
    css`
      ${tw`bg-darkBlue! bg-opacity-20!`}
    `
]);

export const phaseDate = styled.span(() => [
  css`
    ${tw`absolute text-10 font-semibold sm:text-14 leading-none text-textBlack`};
    top: 1px;

    &::first-child {
      left: 3px;
    }
    @media screen and (max-width: 575px) and (min-width: 320px) {
      top: 1.5rem;
    }
  `
]);

interface ProjectDateWrapperProps {
  isEnd?: boolean;
  hasEvents?: boolean;
}

export const projectDateWrapper = styled.div<ProjectDateWrapperProps>(
  ({ isEnd, hasEvents }) => [
    css`
      ${tw`absolute border-l border-darkBlue pl-5`};
      left: -0.1rem;
      top: -3.2rem;
      bottom: -1.5rem;
      @media screen and (max-width: 575px) and (min-width: 320px) {
        top: -3.2rem;
      }
    `,
    isEnd &&
      css`
        ${tw`absolute left-100 border-l border-pink`};
      `,
    hasEvents &&
      css`
        top: -6.5rem;
      `
  ]
);

export const projectTitle = styled.div(() => [
  css`
    ${tw`text-textBlack font-semibold text-12 leading-13`};
    width: 5rem;
  `
]);

export const projectDate = styled.div(() => [
  css`
    ${tw`absolute top-100 left-0 text-textBlack font-semibold text-12 leading-13 transform -translate-x-1/2 translate-y-5`};
  `
]);
