import React, { useState, useEffect } from 'react';
import { Wrapper, Section, Title, Actions } from './StyledMethod';
import { DropdownCbOption, SetStateType } from '../../../../types/util.types';
import {
  MemberRole,
  Complexity,
  AllServices
} from '../../../../store/method-service/types';
import { editMethod } from '../../../../store/method-service/actions';
import { toastUtil } from '../../../../utils/toast.util';
import { validatePd } from '../../../../utils/personDays.utils';
import Button from '../../../../components/Button';
import NameField from '../../../CreateMethod/NameField';
import MethodLevel from '../../../CreateMethod/MethodLevel';
import DisciplineField from '../../../CreateMethod/DisciplineField';
import PhaseField from '../../../CreateMethod/PhaseField';
import MemberField from '../../../CreateMethod/MemberField';
import NumberField from '../../../CreateMethod/NumberField';
import ComplexityField from '../../../CreateMethod/ComplexityField';
import CommentField from '../../../CreateMethod/CommentField';

interface Props extends AllServices {
  isEdit: boolean;
  setIsEdit: SetStateType<boolean>;
  fetchAllServices: () => Promise<void>;
  methodFromServicePackage: boolean;
}

const Method = ({
  id,
  name: methodName,
  level,
  disciplines,
  phases,
  memberRoles,
  personDaysPmQm: pmQmDays,
  duration: methodDuration,
  qualities,
  description: methodDescription,
  isEdit,
  deliverables: methodDeliverables,
  qualityMeasurements: methodQM,
  methodFromServicePackage,
  setIsEdit,
  fetchAllServices
}: Props) => {
  // complexity
  const standardCompx = qualities?.find((q) => q.type === 'STANDARD');
  const highCompx = qualities?.find((q) => q.type === 'HIGH');
  const extremeCompx = qualities?.find((q) => q.type === 'EXTREME');
  const responsiveDCompx = qualities?.find(
    (q: Complexity) => q.type === 'RESPONSIVE_DESIGN'
  );

  //phases
  const newPhases = phases?.map((p) => {
    return {
      id: p.id,
      name: p.title
    };
  });

  const [submitting, setSubmitting] = useState<boolean>(false);
  const [name, setName] = useState<string>(methodName);
  const [selectedLevel, setSelectedLevel] = useState<DropdownCbOption[]>([
    { id: level, name: `.${level.toLowerCase()}` }
  ]);
  const [selectedPhase, setSelectedPhase] =
    useState<DropdownCbOption[]>(newPhases);
  const [selectedDisciplines, setSelectedDisciplines] =
    useState<DropdownCbOption[]>(disciplines);

  const [selectedRoles, setSelectedRoles] = useState<MemberRole[]>(
    memberRoles || []
  );

  const [personDaysPmQm, setPersonDaysPmQm] = useState<string>(`${pmQmDays}`);

  const [duration, setDuration] = useState<string>(`${methodDuration}`);
  const [standard, setStandard] = useState<Complexity>(
    standardCompx || {
      type: 'STANDARD',
      personDays: '',
      price: ''
    }
  );
  const [high, setHigh] = useState<Complexity>(
    highCompx || {
      type: 'HIGH',
      personDays: '',
      price: ''
    }
  );
  const [extreme, setExtreme] = useState<Complexity>(
    extremeCompx || {
      type: 'EXTREME',
      personDays: '',
      price: ''
    }
  );
  const [responsiveDesign, setResponsiveDesign] = useState<Complexity>(
    responsiveDCompx || {
      type: 'RESPONSIVE_DESIGN',
      personDays: '',
      price: ''
    }
  );
  const newDeliverables = methodDeliverables?.reduce(
    (acc: string, current: any) => {
      const line = `• ${current.name}\n`;
      acc += line;
      return acc;
    },
    ''
  );
  const newQM = methodQM?.reduce((acc: string, current: any) => {
    const line = `• ${current.name}\n`;
    acc += line;
    return acc;
  }, '');

  const [deliverables, setDeliverables] = useState<string>(newDeliverables);
  const [qualityMeasurements, setQualityMeasurements] = useState<string>(newQM);
  const [description, setDescription] = useState<string>(methodDescription);

  useEffect(() => {
    if (!personDaysPmQm || !selectedRoles.length) {
      setStandard({ ...standard, personDays: '' });
      setHigh({ ...high, personDays: '' });
      setExtreme({ ...extreme, personDays: '' });
      return;
    }

    let totalPd = selectedRoles.reduce((acc: number, role: MemberRole) => {
      return acc + Number(role.personDays);
    }, 0);
    totalPd += Number(personDaysPmQm);

    setStandard({ ...standard, personDays: totalPd + '' });
    setHigh({ ...high, personDays: (totalPd * 1.25).toFixed(2) + '' });
    setExtreme({ ...extreme, personDays: (totalPd * 1.5).toFixed(2) + '' });
    // eslint-disable-next-line
  }, [selectedRoles, personDaysPmQm]);

  const edit = async () => {
    try {
      setSubmitting(true);

      const res = await editMethod(
        name,
        selectedLevel,
        selectedDisciplines,
        selectedPhase,
        selectedRoles,
        personDaysPmQm,
        duration,
        [standard, high, extreme, responsiveDesign],
        description,
        deliverables,
        qualityMeasurements,
        id
      );
      fetchAllServices();
      setSubmitting(false);
      setIsEdit(false);
      toastUtil('success', res.data.meta.message);
    } catch (error) {
      setSubmitting(false);
    }
  };

  const isInvalid =
    !name ||
    !selectedLevel.length ||
    !selectedDisciplines.length ||
    !selectedPhase.length ||
    !selectedRoles.length ||
    !personDaysPmQm ||
    !validatePd(standard.price) ||
    !standard.personDays ||
    !validatePd(high.price) ||
    !high.personDays ||
    !validatePd(extreme.price) ||
    !duration ||
    !extreme.personDays;

  return (
    <Wrapper>
      <Section>
        <Title>Edit method</Title>
        <NameField
          value={name}
          setValue={setName}
          label="Method name"
          disabled={!isEdit}
        />
        <MethodLevel
          selected={selectedLevel}
          onAdd={setSelectedLevel}
          label="Method Level"
          disabled={!isEdit}
        />
        <DisciplineField
          selected={selectedDisciplines}
          onAdd={setSelectedDisciplines}
          checkingDisabled={!isEdit || methodFromServicePackage}
          details
        />
        <PhaseField
          selected={selectedPhase}
          onAdd={setSelectedPhase}
          disabled={!isEdit}
        />
      </Section>
      <Section>
        <MemberField
          selectedRoles={selectedRoles}
          setSelectedRoles={setSelectedRoles}
          label="Member role and persondays"
          title="Member"
          disabled={!isEdit}
          details
        />
        <NumberField
          title="PM & QM"
          label="Person Days"
          value={personDaysPmQm}
          setValue={setPersonDaysPmQm}
          minValidNumber={0.5}
          disabled={!isEdit}
        />
        <NumberField
          title="Method Duration"
          label="Weeks"
          value={duration}
          setValue={setDuration}
          minValidNumber={0.1}
          disabled={!isEdit}
        />
      </Section>
      <Section>
        <Title>Complexity</Title>
        <ComplexityField
          complexity={standard}
          setComplexity={setStandard}
          title="Standard"
          disableAll={!isEdit}
          disabled={!personDaysPmQm && standard.personDays === ''}
        />
        <ComplexityField
          complexity={high}
          setComplexity={setHigh}
          title="High"
          disableAll={!isEdit}
          disabled={!personDaysPmQm && high.personDays === ''}
        />
        <ComplexityField
          complexity={extreme}
          setComplexity={setExtreme}
          title="Extreme"
          disableAll={!isEdit}
          disabled={!personDaysPmQm && extreme.personDays === ''}
        />
        <ComplexityField
          complexity={responsiveDesign}
          setComplexity={setResponsiveDesign}
          title="Responsive Design"
          disableAll={!isEdit}
        />
      </Section>
      <Section>
        <Title>Comment field</Title>
        <CommentField
          setValue={setDescription}
          value={description}
          placeholder="Description"
          disabled={!isEdit}
        />
        <CommentField
          setValue={setDeliverables}
          value={deliverables}
          placeholder="• Deliverables"
          bulletList
          disabled={!isEdit}
        />
        <CommentField
          setValue={setQualityMeasurements}
          value={qualityMeasurements}
          placeholder="• Quality measurements"
          bulletList
          disabled={!isEdit}
        />
      </Section>
      {isEdit && (
        <Actions>
          <Button secondary disabled={submitting || isInvalid} onClick={edit}>
            Submit
          </Button>
        </Actions>
      )}
    </Wrapper>
  );
};

export default Method;
