import tw, { styled, css } from 'twin.macro';
import { StyledProps as Props } from '../../types/util.types';

export const HeaderWrapper = styled.div(() => [
  css`
    ${tw`w-full px-10 md:px-30 pt-40 pb-15 bg-white`};
  `
]);

export const HeaderTop = styled.div(() => [
  css`
    ${tw`flex flex-wrap flex-col sm:flex-row justify-between mb-40 text-textBlack`};

    h2 {
      ${tw`mb-0`}
    }

    button {
      ${tw`mt-10 sm:mt-0 self-start sm:self-center`}
    }
    a {
      ${tw`mt-10 sm:mt-0 self-start sm:self-auto`}
    }
  `
]);

export const HeaderBottom = styled.div(() => [
  css`
    ${tw`flex flex-col md:flex-row md:items-center md:justify-between text-textBlack`};

    p {
      ${tw`text-32 font-normal leading-44 tracking-wide pb-20`}
    }
  `
]);

export const FilterWrapper = styled.div(() => [
  css`
    ${tw`flex flex-wrap`};
  `
]);

export const FilterItem = styled.span<Props>(({ isActive }) => [
  css`
    ${tw`flex items-center justify-center py-5 px-10 transition duration-300 transition-all text-textBlack font-semibold text-18 cursor-pointer hover:bg-gray6 hover:bg-opacity-50`};
    border-radius: 2px;

    &:not(:last-child) {
      ${tw`sm:mr-15`}
    }

    img {
      ${tw`mr-10`}
    }
  `,
  isActive &&
    css`
      ${tw`bg-gray6`}
    `
]);
