import tw, { styled, css } from 'twin.macro';
import { StyledProps as Props } from '../../types/util.types';
import {
  Dropdown,
  Wrapper as actionsWrapper
} from '../../components/Actions/actions-component';

export const wrapper = styled.div(() => [
  css`
    ${tw`px-10 md:px-30 py-40`};

    h3 {
      ${tw`text-textBlack font-bold text-48 font-bold tracking-normal `}
    }
  `
]);

export const headerActions = styled.div(() => [
  css`
    ${tw`flex items-center relative ml-auto hover:bg-darkBlueHover py-10`};
    ${actionsWrapper} {
      width: 30px;
    }
    ${Dropdown} {
      width: 150px;
      right: 0;
    }

    span {
      ${tw`flex items-center mr-20 cursor-pointer text-18 font-semibold pl-20 `}
      img {
        ${tw`mr-10`}
      }
    }
  `
]);

export const buttonContainer = styled.div<Props>(() => [
  css`
    ${tw`flex flex-col lg:flex-row justify-between w-full max-w-md lg:max-w-44`};

    a {
      ${tw`my-10 lg:my-0`}
    }
  `
]);

export const action = styled.div(() => [
  css`
    ${tw`w-full px-15 py-10 hover:bg-darkBlue text-darkBlue text-left font-semibold hover:text-white cursor-pointer `};
    transition: all 0.3s;
  `
]);
