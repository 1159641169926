import { roles } from '../utils/roles.utils'

export const capitalize = (s: string) => {
  if (typeof s !== 'string') return '';
  if (
    (s.charAt(0) === 'U' && s.charAt(1) === 'I') ||
    (s.charAt(0) === 'U' && s.charAt(1) === 'X')
  ) {
    return (
      s.charAt(0).toUpperCase() +
      s.charAt(1).toUpperCase() +
      s.toLowerCase().slice(2)
    );
  }
  return s.charAt(0).toUpperCase() + s.toLowerCase().slice(1);
};

export const capitalizeString = (s: any) => {
  if (typeof s !== 'string') return '';
  return s.charAt(0).toUpperCase() + s.toLowerCase().slice(1);
};

export const encodeQueryParams = (param: any) => {
  for (let key in param) {
    if (param.hasOwnProperty(key)) {
      param[key] = encodeURIComponent(param[key]);
    }
  }
  return param;
};

export const replaceSpecialCharacter = (value:string) => {
  return value.replace('^', '%5E');
}

export const isClientRole = (value:any) => {
  return value?.role === roles.client;
}

export const isMemberRole = (value:any) => {
  return value?.role === roles.member;
}
