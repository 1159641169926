const vars = {
  colors: {
    textBlack: '#0f2b38',
    textWhite: '#fff',
    pink: '#ed0c6e',
    pinkDark: '#d20d63',
    error: '#ff174d',
    green: '#3cd52e',
    green2: '#009471',
    green3: '#057683',
    green4: '#348894',
    green5: '#008366',
    blue: '#00a7e1',
    darkBlue: '#1e445c',
    darkBlue2: '#216088',
    yellow: '#ffc222',
    gray1: 'rgba(15, 43, 56, .5)',
    gray2: '#9ad3d7',
    gray3: 'rgba(15, 43, 56, .75)',
    gray4: 'rgba(255, 255, 255, .5)',
    gray5: '#f2f2f2',
    gray6: '#a6bcd1',
    gray7: '#c7d0d6',
    gray8: '#979797'
  },
  shadows: {
    default: '0 2px 8px 0 rgba(0, 0, 0, .15)',
    hover: '0 6px 12px 0 rgba(0, 0, 0, .20)',
    pressed: '0 8px 14px 0 rgba(0, 0, 0, .30)',
    button:
      '0px 14px 20px 0px rgba(237, 12, 110, 0.4),0px 14px 20px 0px rgba(0, 0, 0, 0.4)',
    buttonHover:
      '0px 16px 24px 0px rgba(218, 4, 97, 0.35),0px 16px 24px 0px rgba(0, 0, 0, 0.35)',
    buttonActive:
      '0px 18px 28px 0px rgba(237, 12, 110, 0.3),0px 18px 28px 0px rgba(0, 0, 0, 0.3);',
    buttonDarkTheme: '0 12px 20px 0 rgba(0, 0, 0, .60)',
    buttonSecondary:
      '0px 12px 20px 0px rgba(30, 68, 92, 0.4),0px 12px 20px 0px rgba(0, 0, 0, 0.4)',
    buttonSecondaryHover:
      '0px 16px 24px 0px rgba(33, 96, 136, 0.35),0px 16px 24px 0px rgba(0, 0, 0, 0.35);',
    buttonSecondaryActive:
      '0px 18px 28px 0px rgba(30, 68, 92, 0.3),0px 18px 28px 0px rgba(0, 0, 0, 0.3);'
  }
};
export default vars;
