import tw, { styled, css } from 'twin.macro';
import { StyledProps as Props } from '../../types/util.types';

export const projectList = styled.div<Props>(({ listView }) => [
  css`
    ${tw`mx-10 md:mx-30 mt-40`}
  `,
  !listView &&
    css`
      ${tw`grid gap-12 text-white text-center`}
      grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));

      @media screen and (max-width: 380px) {
        grid-template-columns: repeat(auto-fill, minmax(100%, 1fr));
      }
    `,
  listView &&
    css`
      ${tw`bg-white mt-15 px-15`}
      border-radius: 4px;
      box-shadow: 0px 2px 8px 0px rgba(0, 0, 0, 0.15);
    `
]);

export const headerActions = styled.div(() => [
  css`
    ${tw`flex items-center`};

    span {
      ${tw`flex items-center mr-20 cursor-pointer text-18 font-semibold `}
      img {
        ${tw`mr-10`}
      }
    }
  `
]);
