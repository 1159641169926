import tw, { styled, css } from 'twin.macro';
import { DialogOverlay } from '@reach/dialog';
import { StyledProps } from '../types/util.types';
import { ReactSVG } from 'react-svg';

const DialogReachOverlay = styled(DialogOverlay)(() => [
  css`
    ${tw`flex items-center p-8 `}
    z-index: 100;
    [data-dialog-overlay] {
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      z-index: 5;
      background: rgba(236, 236, 236, 0.49);
    }

    [data-reach-dialog-content] {
      ${tw`bg-transparent w-auto z-20 max-w-full`}
    }
    [data-dialog-content] {
      ${tw`relative z-20`}
    }
  `
]);

const ModalContent = styled.div<StyledProps>(
  ({
    isError,
    theme: {
      colors: { error, green5 }
    }
  }) => [
    css`
      ${tw`relative  text-white p-8 w-full`};
      border-radius: 2px;
      background-color: ${isError ? error : green5};
    `
  ]
);

const Title = styled.div(() => [
  css`
    ${tw`flex flex-col items-center lg:flex-row`};
    > div {
      ${tw`mr-8`}
    }

    svg {
      height: 60px;
      width: 70px;
    }

    h4 {
      ${tw`text-4xl lg:text-5xl font-bold tracking-wide leading-48 `}
    }

    h5 {
      ${tw`text-20 lg:text-24 leading-40 w-full `}
      max-width:387px;
    }
  `
]);
const ButtonContainer = styled.div(() => [
  css`
    ${tw`flex justify-end`};

    button {
      ${tw`mx-4`}
      min-width:140px;
    }
    button:last-child {
      ${tw`bg-white text-darkBlue hocus:bg-gray6`}
    }
  `
]);

const CloseModalIcon = styled(ReactSVG)(() => [
  css`
    ${tw`absolute cursor-pointer`};
    top: 5px;
    right: 5px;

    path {
      fill: white;
    }
  `
]);

const Icon = styled(ReactSVG)<StyledProps>(({ red }) => [
  css`
    ${tw`mt-2 mb-auto`}
    path {
      fill: ${red ? 'red' : 'white'};
    }
  `
]);

export {
  Icon,
  CloseModalIcon,
  ButtonContainer,
  ModalContent,
  DialogReachOverlay,
  Title
};
