import tw, { styled, css } from 'twin.macro';

export const wrapper = styled.div(() => [
  css`
    ${tw`relative z-40 w-full mb-28`};
  `
]);

export const input = styled.div(() => [
  css`
    ${tw`flex flex-col justify-center w-full pl-16 pr-50 border-2 border-darkBlue text-22 leading-23 font-semibold text-black cursor-pointer hover:bg-darkBlue hover:bg-opacity-15`};
    height: 6.4rem;

    svg {
      ${tw`absolute`}
      right: 2rem;
      top: 2rem;
    }
  `
]);

interface LabelProps {
  hasValue: boolean;
  open: boolean;
}

export const label = styled.span<LabelProps>(({ hasValue, open }) => [
  css`
    ${tw`absolute top-0 text-gray11 text-22 leading-32 font-semibold transition duration-300 transform translate-y-16`}
    left: 1.6rem;
  `,
  hasValue &&
    css`
      ${tw`text-14 leading-none text-gray11 translate-y-6`}
    `,
  open &&
    css`
      ${tw`text-blue!`}
    `
]);

export const dropdown = styled.div(() => [
  css`
    ${tw`absolute z-50 top-100 right-0 mt-5 flex flex-col items-start w-full max-w-356 border-2 border-darkBlue bg-gray10 p-6`}

    .DayPicker {
      ${tw`w-full`}

      .DayPicker-wrapper {
        ${tw`outline-none p-0`}
      }

      .DayPicker-Month {
        ${tw`w-full m-0`}
      }

      .DayPicker-Caption {
        ${tw`mb-8 text-24 leading-32 font-semibold text-textBlack text-center`}
      }

      .DayPicker-Weekdays {
        ${tw`mb-12 opacity-75`}
      }

      .DayPicker-Weekday {
        ${tw`p-0 m-0 text-18 leading-48 text-textBlack font-semibold`}
      }

      .DayPicker-Week {
        ${tw`mb-4 last:mb-0`}
      }

      .DayPicker-Day {
        ${tw`outline-none text-textBlack font-semibold text-18 leading-32`}

        &.DayPicker-Day--outside {
          ${tw`cursor-default opacity-50`}
        }

        &.DayPicker-Day--selected {
          ${tw`text-white bg-darkBlue`}
        }

        &.DayPicker-Day--disabled {
          ${tw`cursor-default opacity-50`}
        }

        &:not(.DayPicker-Day--disabled):not(.DayPicker-Day--selected):not(.DayPicker-Day--outside):hover {
          ${tw`bg-green2 bg-opacity-20`}
        }
      }
    }
  `
]);
