import React, { useState } from 'react';
import logo from '../../images/logo-white.svg';
import logoGreen from '../../images/logo-green.svg';
import UserMenu from './UserMenu';
import Nav from './Nav';
import { Link } from 'react-router-dom';
import * as S from './app-sidebar';
import { ReactSVG } from 'react-svg';
import openIcon from '../../images/hamburger.svg';
import closeIcon from '../../images/close-x.svg';

const AppSidebar = () => {
  const [isOpen, setIsOpen] = useState<boolean>(true);

  const toggleMenu = () => setIsOpen(!isOpen);
  return (
    <S.wrapper>
      <S.header>
        <S.headerLogo>
          <img src={logoGreen} alt="" />
        </S.headerLogo>
        <Link to="/">Clever Project Composer</Link>
        <S.hamburger onClick={toggleMenu}>
          {isOpen ? <ReactSVG src={closeIcon} /> : <ReactSVG src={openIcon} />}
        </S.hamburger>
      </S.header>
      <S.navigationMenu isOpen={isOpen}>
        <UserMenu toggleMenu={toggleMenu} />
        <Nav toggleMenu={toggleMenu} />
        <img src={logo} alt="" />
      </S.navigationMenu>
    </S.wrapper>
  );
};

export default AppSidebar;
