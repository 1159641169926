import tw, { styled, css } from 'twin.macro';
import { StyledProps as Props } from '../../types/util.types';

export const wrapper = styled.div<Props>(({ isListView }) => [
  css`
    ${tw`flex`};

    img {
      transition: all 0.3s;
      cursor: pointer;

      &:first-of-type {
        ${isListView ? 'opacity: .5;' : 'opacity: 1;'}
      }

      &:last-of-type {
        ${tw`ml-10`}
        ${isListView ? 'opacity: 1;' : 'opacity: .5;'}
      }
    }
  `
]);
