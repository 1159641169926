import api from '../../config/api.config';
import { AppState } from '../index';
import { LoginType, ChangePassType } from './auth.types';
import { handleErrors } from '../../utils/error.utils';

export const logout = (store: AppState) => {
  localStorage.removeItem('cpcToken');
  localStorage.removeItem('cpcRefresh');
  localStorage.removeItem('cpcClientToken');
  store.setState({
    ...store.state,
    auth: {
      loggedIn: false,
      user: null
    }
  });
};

export const login: LoginType = async (store, username, password) => {
  try {
    const {
      data: { data }
    } = await api.post(
      '/auth/login',
      { username, password },
      {
        headers: {
          grant_type: 'password'
        }
      }
    );

    localStorage.setItem('cpcToken', data.token);
    localStorage.setItem('cpcRefresh', data.refresh);

    store.setState({
      ...store.state,
      auth: {
        ...store.state.auth,
        loggedIn: true
      }
    });
  } catch (error) {
    handleErrors(error, 'Invalid user credentials');

    return Promise.reject(error.response.data);
  }
};

export const getCurrentUser = async (store: AppState): Promise<any> => {
  try {
    const {
      data: { data }
    } = await api('/accounts/me');

    store.setState({
      ...store.state,
      auth: {
        ...store.state.auth,
        currentUser: data
      }
    });
  } catch (error) {
    handleErrors(error);
    return Promise.reject(error);
  }
};

export const isClientAction = async (token: string): Promise<any> => {
  try {
    const {
      data: { data }
    } = await api(`/accounts/info?token=${token}`);
    localStorage.setItem('cpcClientToken', token);

    return data;
  } catch (error) {
    handleErrors(error);
    return Promise.reject(error.response.data);
  }
};

export const changeUserPassword: ChangePassType = async (
  store,
  accountId,
  currentPassword,
  newPassword,
  repeatedPassword,
  invitationEmailId
): Promise<any> => {
  try {
    const { data } = await api.patch(`/accounts/${accountId}/update-password`, {
      currentPassword,
      newPassword,
      repeatedPassword
    });

    store.setState({
      ...store.state,
      auth: {
        ...store.state.auth,
        currentUser: {
          ...store.state.auth.currentUser,
          resetPasswordRequired: false
        }
      }
    });
    if (invitationEmailId) {
      await api.patch(`/invitationEmails/${invitationEmailId}/accept`);
    }

    return data;
  } catch (error) {
    handleErrors(error);
    return Promise.reject(error?.response.data || error);
  }
};
export const resetPassword = async (email: string): Promise<any> => {
  try {
    const { data } = await api.post(`/accounts/reset-password`, {
      email
    });

    return data;
  } catch (error) {
    handleErrors(error);
    return Promise.reject(error);
  }
};
