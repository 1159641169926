import { FormFieldType } from '../../../types/form.types';

interface Values {
  firstName: string;
  lastName: string;
  email: string;
}

export const formModel: FormFieldType[] = [
  {
    name: 'firstName',
    type: 'text',
    title: 'First name',
    line: true
  },
  {
    name: 'lastName',
    type: 'text',
    title: 'Surname',
    line: true
  },
  {
    name: 'email',
    type: 'text',
    title: 'Email Address',
    line: true
  }
];
