import React from 'react';
import { ReactComponent as Icon } from '../../../images/completed.svg';

import {ProjectProgressCategories} from '../../../store/projects/projects.types';

import * as S from './project-categories';

interface Props {
    categories: ProjectProgressCategories[];
}

const values = {
    serviceDesign: 'Service Design',
    uxDesign: 'UX Design',
    industrialDesign: 'Industrial Design',
    softwareDevelopment: 'Software Development'
}

const ProjectCategories = ({ categories }: Props) => {
    
    return (
            <S.wrapper>
                <h3>Services</h3>
                <S.content>
                <S.categories>
                    {
                        categories.find((c: ProjectProgressCategories) =>
                            c.name === values.serviceDesign) && <Icon />
                    }
                        <span>Service Design</span>
                    </S.categories>
                    <S.categories>
                    {
                        categories.find((c: ProjectProgressCategories) =>
                            c.name === values.uxDesign) && <Icon />
                    }
                    
                        <span>UX Design</span>
                    </S.categories>
                <S.categories>
                    {
                        categories.find((c: ProjectProgressCategories) =>
                            c.name === values.industrialDesign) && <Icon />
                    }
                        <span>Industrial Design</span>
                    </S.categories>
                    <S.categories>
                    {
                        categories.find((c: ProjectProgressCategories) =>
                            c.name === values.softwareDevelopment) && <Icon />
                    }
                        <span>Software Development</span>
                    </S.categories>
                </S.content>
            </S.wrapper>
    )
}

export default ProjectCategories;