import tw, { styled, css } from 'twin.macro';
import { StyledProps as Props } from '../../types/util.types';
import arrow from '../../images/arrow-down.svg';

const InputContent = styled.div<Props>(({ arrow, disabled, withInputs }) => [
  css`
    ${tw`flex flex-col relative w-full`};
    ${!withInputs && tw`mb-30`}
    border: 2px solid rgb(30, 68, 92);

    input {
      ${tw`outline-none bg-transparent font-semibold  text-2xl md:text-4xl leading-32 text-textBlack pl-10`}
      min-height: 64px;
      padding: 1.6rem 1.6rem;
      ${arrow && tw`cursor-pointer`}
      &:hover {
        background-color: rgba(30, 68, 92, 0.15);
      }
    }
  `,
  disabled &&
    css`
      opacity: 0.5;
      pointer-events: none;
    `
]);

const InputDropdown = styled.div<Props>(({ show }) => [
  css`
    ${tw`absolute w-full max-w-xl z-10 py-15`}
    ${show ? tw`block` : tw`hidden`};
    border: 2px solid rgb(30, 68, 92);
    background-color: rgb(246, 248, 250);
    top: 7.2rem;
    left: -2px;
  `
]);

const NoOptions = styled.div(() => [
  css`
    ${tw`p-10 font-semibold text-22`}
    pointer-events:none;
  `
]);
const Options = styled.div<Props>(({ withInputs }) => [
  css`
    ${tw`cursor-pointer p-10 font-semibold text-18`}
    ${withInputs && tw`flex justify-between items-center`}
    &:hover {
      background-color: #1e445c;
      color: white;

      > div {
        ${tw`text-white`};
        border-color: #fff;
      }
      span {
        ${tw`text-white`};
        opacity: 0.5;
      }
    }
  `
]);

const Label = styled.label<Props>(({ hasValue, open, labelColor }) => [
  css`
    ${tw`absolute top-0`}
    left: 1.6rem;
    color: ${labelColor ? labelColor : '#929da2'};
    transform: translateY(1.6rem);
    transition: all 0.3s;
    font-size: 2.2rem;
    line-height: 3.2rem;
    font-weight: 600;
    pointer-events: none;
  `,
  hasValue &&
    css`
      transform: translateY(0.6rem);
      font-size: 1.4rem;
      line-height: 1;
      color: ${open ? '#00a7e1' : '#929da2'};
    `,
  open &&
    css`
      color: ${'#00a7e1'};
    `
]);

const Container = styled.div<Props>(({ active }) => [
  css`
    ${tw`absolute w-full h-full left-0 top-0 cursor-pointer flex justify-end items-center`};
    pointer-events: none;
    > span {
      ${tw`block bg-center bg-no-repeat bg-contain cursor-pointer `}
      width: 2.4rem;
      height: 2.4rem;
      border-radius: 2px;
      background-image: url(${arrow});
      background-size: 90% auto;
      transition: all 0.3s;
      margin-right: 1.6rem;
      transform: ${active && 'rotate(180deg)'};
    }
  `
]);

const PersonDays = styled.div(() => [
  css`
    ${tw`relative flex items-center border-2 border-solid border-darkBlue `};
    min-width: 12.8rem;
    max-width: 12.8rem;

    div {
      ${tw`w-full pl-2 border-0 outline-none bg-transparent text-22 text-right font-semibold`}
      padding-right: 4.5rem;
      opacity: 0.5;
    }

    span {
      ${tw`absolute text-22`}
      right: 1.5rem;
      opacity: 0.5;
    }
  `
]);

export {
  InputContent,
  InputDropdown,
  NoOptions,
  Options,
  Label,
  Container,
  PersonDays
};
