import React, { useState } from 'react';
import Button from '../../../components/Button';
import Modal from '../../../components/Modal';
import PictureCropper from '../../../components/PictureCropper/PictureCropper';
import useGlobal from '../../../store';
import upload from '../../../images/upload-white.svg';
import * as S from './profile-avatar';
import { toastUtil } from '../../../utils/toast.util';

const ProfileAvatar = () => {
  const [user, updateAvatar] = useGlobal(
    (state) => state.auth.currentUser,
    (actions) => actions.updateAvatar
  );
  const [isOpen, setIsOpen] = useState<boolean>(false);

  const open = () => setIsOpen(true);
  const close = () => setIsOpen(false);

  const update = async (file: any) => {
    try {
      await updateAvatar(user!.accountId, file);
      toastUtil('success', 'Your avatar was successfully changed.');
    } catch (error) {
      return Promise.reject(error);
    }
  };

  return (
    <>
      <S.wrapper>
        {user?.image && <img src={user.image} alt={user?.firstName} />}
        <S.actions>
          <Button secondary onClick={open}>
            <img src={upload} alt="" />
            Change Avatar
          </Button>
          <span>JPG or PNG, at least 256px.</span>
        </S.actions>
      </S.wrapper>
      <Modal isOpen={isOpen} onDismiss={close}>
        <PictureCropper
          onClose={close}
          aspect={1 / 1}
          title="Change Avatar"
          description="Drop new avatar here, or select a file from your computer"
          uploadDefinition="Upload Avatar"
          onCropSuccess={update}
          secondary
        />
      </Modal>
    </>
  );
};

export default ProfileAvatar;
