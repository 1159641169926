import tw, { styled, css } from 'twin.macro';
import { StyledProps as Props } from '../../types/util.types';

export const wrapper = styled.div(() => [
  css`
    h3 {
      ${tw`text-textBlack`}
    }
  `
]);
export const form = styled.form(() => [
  css`
    ${tw`flex flex-col w-full `};

    button {
      ${tw`self-end `}
    }
  `
]);

export const gender = styled.div(() => [
  css`
    ${tw`flex mt-20 mb-10`};

    label {
      ${tw`text-2xl mr-20`}
    }
  `
]);

export const dropdownPlaceholder = styled.div(() => [
  css`
    ${tw`flex items-center `};
    > div {
      ${tw`mr-10`}
    }
  `
]);

export const companyContent = styled.div(() => [
  css`
    ${tw`pb-20`};

    button {
      img {
        height: 2rem;
      }
    }
  `
]);

export const avatarWrapper = styled.div(() => [
  css`
    ${tw`flex flex-col md:flex-row  items-center md:justify-start w-full mt-30 flex-wrap `}
    > img {
      ${tw`w-full mb-0`}
      max-width: 25rem;
      object-fit: cover;
      object-position: 0 30%;
    }
  `
]);

export const actions = styled.div(() => [
  css`
    ${tw`inline-flex flex-col md:self-end mt-10`}

    span {
      ${tw`block w-full mt-3 text-14 leading-22 font-semibold text-textBlack`}
    }
  `
]);

export const droparea = styled.div<Props>(({ preview, disabled }) => [
  css`
    ${tw`flex flex-col items-center justify-center w-full h-full border-2 border-darkBlue border-dashed outline-none text-18 font-semibold text-center md:mr-30 cursor-pointer`};
    max-width: 25rem;
    height: 14rem;
    background: rgba(166, 188, 209, 0.15);
    border-radius: 8px;
    max-height: 14rem;

    > img {
      ${tw`h-full w-full`}
      object-fit: contain;
    }

    div {
      ${tw`flex flex-col items-center p-20`}

      img {
        ${tw`mt-10`}
      }
    }

    p {
      ${tw`text-15 tracking-normal`}
    }
  `,

  disabled &&
    css`
      cursor: default;
      opacity: 0.5;
    `,
  preview &&
    css`
      ${tw`border-0 px-0 cursor-pointer`}
      /* height: auto; */
      img {
        ${tw`w-full mt-0`}
      }
      opacity: 1;
    `
]);

export const buttonContainer = styled.div<Props>(({ userCreated }) => [
  css`
    ${tw`self-end`};
  `,
  userCreated &&
    css`
      button {
        ${tw`bg-darkBlue2 pointer-events-none `}
      }
    `
]);
