import React, { useEffect, useState, useRef } from 'react';
import { HeaderWrapper, HeaderTop } from '../../components/Header/StyledHeader';
import HeaderFilter, { Filters } from '../../components/Header/HeaderFilter';
import * as S from './clients-page';
import { clientOverview } from '../../store/clients/clients.actions';
import { InviteType } from '../../store/clients/clients.types';
import TopBar from '../../components/TopBar';
import Pager from '../../components/Pager';
import CardLoader from '../../components/CardLoader';
import InviteItem from './InviteItem';
import list from '../../images/list.svg';
import accepted from '../../images/accepted.svg';
import plane from '../../images/plane-red.svg';
import warrning from '../../images/warning-orange.svg';
import { PLACEHOLDER_ARRAY } from '../../utils/general.utils';

const filters: Filters[] = [
  {
    label: 'All invitations',
    icon: list,
    value: ''
  },
  {
    label: 'Accepted',
    icon: accepted,
    value: 'ACCEPTED'
  },

  {
    label: 'No confirmation',
    icon: plane,
    value: 'NOT_CONFIRMED'
  },
  {
    label: 'Expired',
    icon: warrning,
    value: 'EXPIRED'
  }
];

const Clients = () => {
  const first = useRef(true);
  const [name, setName] = useState<string>('');
  const [filter, setFilter] = useState<string>('');
  const [page, setPage] = useState<number>(0);
  const [totalPageNumber, setTotalPageNumber] = useState<number>(0);
  const [invites, setInvites] = useState<InviteType[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(true);

  const fetchInvites = async () => {
    try {
      const { totalPageNumber, content } = await clientOverview({
        page,
        filter,
        name
      });
      setTotalPageNumber(totalPageNumber);
      setInvites(content);
      setIsLoading(false);
    } catch (error) {
      console.warn(error);
      setIsLoading(false);
    }
  };

  useEffect(() => {
    if (first.current) return;
    page === 0 ? fetchInvites() : setPage(0);

    // eslint-disable-next-line
  }, [filter, name]);

  useEffect(() => {
    first.current = false;
    fetchInvites();
    // eslint-disable-next-line
  }, [page]);

  return (
    <>
      <TopBar setSearch={setName} placeholder="Search by name" />
      <HeaderWrapper>
        <HeaderTop>
          <h2>Client</h2>
        </HeaderTop>
      </HeaderWrapper>
      <S.filtersWrapper>
        <span>Filter by:</span>
        <HeaderFilter
          selected={filter}
          setSelected={setFilter}
          list={filters}
        />
      </S.filtersWrapper>
      <S.title>Invitations</S.title>
      <S.listWrapper>
        {isLoading
          ? PLACEHOLDER_ARRAY.map((p: any, i: number) => <CardLoader key={i} />)
          : invites?.map((i: InviteType, index) => (
              <InviteItem key={index} invite={i} />
            ))}
      </S.listWrapper>
      {totalPageNumber > 1 && (
        <Pager page={page} setPage={setPage} pageCount={totalPageNumber} />
      )}
    </>
  );
};

export default Clients;
