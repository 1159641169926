import React from 'react';
import { SetStateType } from '../../types/util.types';
import { FilterItem, FilterWrapper } from './StyledHeader';

export interface Filters {
  label: string;
  icon: string;
  value: string;
}

interface Props {
  setSelected: SetStateType<string>;
  selected: string;
  list: Filters[];
}

const HeaderFilter = ({ selected, setSelected, list }: Props) => {
  return (
    <FilterWrapper>
      {list.map((f: Filters) => (
        <FilterItem
          key={f.label}
          isActive={selected === f.value}
          onClick={() => setSelected(f.value)}
        >
          <img src={f.icon} alt="" /> {f.label}
        </FilterItem>
      ))}
    </FilterWrapper>
  );
};

export default HeaderFilter;
