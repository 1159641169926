import tw, { styled, css } from 'twin.macro';
import { StyledProps as Props } from '../../types/util.types';

export default styled.label<Props>(
  ({ disabled, small, radio, alt, theme: { colors } }) => [
    css`
      ${tw`relative flex items-center justify-center py-3 font-semibold leading-none`}
      color: ${colors.textBlack};
      font-size: 2.4rem;
      cursor: ${disabled ? 'default' : 'pointer'};

      span:first-of-type {
        ${tw`relative block w-8 h-8 border-2`}
        margin-right: 1rem;
        border-color: ${colors.darkBlue};
        transition: all 0.3s;

        &:after {
          ${tw`absolute block`}
          content: '';
          left: 50%;
          transition: all 0.3s;
        }
      }

      input {
        ${tw`absolute inset-0 w-0 h-0 opacity-0 m-0`}

        &:checked + span {
          ${alt === 1 && `background: ${colors.darkBlue};`}
          &:after {
            ${radio
              ? `transform: translate(-50%, -50%) scale(1);`
              : `opacity: 1;`}
          }
        }

        &:focus {
          & + span {
            box-shadow: 0 0 0 2px ${colors.blue};
          }
        }

        &:disabled {
          ~ * {
            opacity: 0.5;
          }
        }
      }

      &:hover {
        span:first-of-type {
          background: ${disabled ? 'transparent' : colors.gray6};
        }
      }
    `,

    small &&
      css`
        ${tw`text-2xl md:text-3xl`}

        span:first-of-type {
          margin-right: 0.8rem;
        }
      `,
    !radio &&
      css`
        span:first-of-type {
          &:after {
            top: 0;
            height: 1.3rem;
            width: 0.7rem;
            border-right: 2px solid ${alt === 1 ? 'white' : colors.pink};
            border-bottom: 2px solid ${alt === 1 ? 'white' : colors.pink};
            transform: translateX(-50%) rotate(45deg);
            opacity: 0;
          }
        }
      `,
    radio &&
      css`
        span:first-of-type {
          ${tw`rounded-full`}

          &:after {
            ${tw`w-4 h-4 rounded-full border-none`}
            top: 50%;
            transition: all 0.3s;
            background: ${colors.pink};
            transform: translate(-50%, -50%) scale(0);
          }
        }
      `
  ]
);
