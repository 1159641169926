import React, { useState, useEffect } from 'react';
import { useFormik, FormikHelpers, FormikValues } from 'formik';
import { TextInput } from '../../../components/Inputs';
import Button from '../../../components/Button';
import { FormFieldType } from '../../../types/form.types';
import { initialValues, formModel } from './form-model';
import validationSchema from './validation-schema';
import * as S from './login-form';
import useGlobal from '../../../store/index';
import { routePaths } from '../../../utils/routes.util';

interface Props {
  data: any;
}

const LoginForm = ({ data: { token, isClient, decoding } }: Props) => {
  const [, login] = useGlobal(
    () => null,
    (actions) => actions.login
  );

  const [submitting, setSubmitting] = useState<boolean>(false);

  useEffect(() => {
    let mounted = true;
    if (token && mounted) {
      formik.setFieldValue('email', isClient?.email);
      formik.setFieldValue('password', isClient?.password);
    }
    return () => {
      mounted = false;
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isClient, token]);

  const formik = useFormik({
    initialValues,
    validationSchema,
    onSubmit: async (
      values,
      formikHelpers: FormikHelpers<FormikValues>
    ): Promise<any> => {
      try {
        setSubmitting(true);
        await login(values.email, values.password);
      } catch (error) {
        setSubmitting(false);
      }
    }
  });

  const isValidForm = validationSchema.isValidSync({ ...formik.values });

  if (decoding) return null;

  return (
    <S.wrapper>
      <S.formTitle isClient={isClient}>
        <S.title>{isClient ? 'Login' : 'Login into CPC'}</S.title>
        <S.subtitle isClient={isClient}>
          {isClient
            ? `Hello ${isClient.firstName} ${isClient.lastName}, 
            this is your personal CPC site.`
            : 'Enter your login details below.'}
        </S.subtitle>
      </S.formTitle>

      <S.form onSubmit={formik.handleSubmit}>
        {formModel.map((field: FormFieldType) => (
          <form autoComplete="none">
            <TextInput
              key={field.name}
              type={field.type}
              isClient={isClient}
              label={isClient ? undefined : field.placeholder}
              touched={formik.touched[field.name]}
              error={formik.errors[field.name]}
              {...formik.getFieldProps(field.name)}
            />
          </form>
        ))}
        <S.actions isClient={isClient}>
          <S.topActions>
            <S.linkText to={routePaths.RESET_PASSWORD}>
              Reset Password
            </S.linkText>
          </S.topActions>
          <Button type="submit" disabled={submitting || !isValidForm}>
            {isClient ? 'I want to see my offer' : 'Show me projects'}
          </Button>
        </S.actions>
      </S.form>
    </S.wrapper>
  );
};

export default LoginForm;
