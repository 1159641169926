import React from 'react';
import envelopeIcon from '../../../images/envelope.svg';
import phoneIcon from '../../../images/phone.svg';

import { ProjectMembersType } from '../../../store/projects/projects.types';

import * as S from './project-members';

interface Props {
  data: ProjectMembersType;
}

const ProjectMemberItem = ({ data }: Props) => {
  return (
    <S.item>
      <S.itemHeader>
        {data.imageUrl ? (
          <img src={data.imageUrl} alt="" />
        ) : (
          <span>
            {`${data.lastName.substring(0, 2)}${data.firstName.substring(
              0,
              2
            )}`}
          </span>
        )}
      </S.itemHeader>
      <S.itemBody>
        <h4>
          {data.firstName} {data.lastName}
        </h4>
        {/* <S.itemMain>
                            <span>Role Description Placeholder</span>
                            <span>Phases Placeholder</span>
                    </S.itemMain> */}
        <S.itemFooter>
          {data.phone && (
            <span>
              <img src={phoneIcon} alt="email" />
              <span>{data.phone}</span>
            </span>
          )}
          <span>
            <img src={envelopeIcon} alt="email" />
            <span>{data.email}</span>
          </span>
        </S.itemFooter>
      </S.itemBody>
    </S.item>
  );
};

export default ProjectMemberItem;
