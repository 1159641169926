import React, { useState, ChangeEvent } from 'react';
import { useHistory } from 'react-router-dom';
import {
  HeaderWrapper,
  HeaderTop,
  HeaderBottom
} from '../../components/Header/StyledHeader';
import * as S from './create-project';
// import TopBar from '../../components/TopBar';
import ClientForm from '../../components/ClientForm';
import ProjectForm from './ProjectForm';
import Button from '../../components/Button';
import CbRadio from '../../components/Inputs/CbRadio';
import {
  createProject,
  uploadProjectImage,
  inviteClientToProject
} from '../../store/projects/projects.actions';
import { SearchMemberItem } from '../../store/members/members.types';
import { DropdownCbOption } from '../../types/util.types';
import { handleErrors } from '../../utils/error.utils';
import { validatePd } from '../../utils/personDays.utils';

const CreateProject = () => {
  const history = useHistory();
  const [submitting, setSubmitting] = useState<boolean>(false);
  const [permission, setPermission] = useState<boolean>(false);
  // client
  const [selectedUser, setSelectedUser] =
    useState<SearchMemberItem | null>(null);
  //project
  const [projectName, setProjectName] = useState<string>('');
  const [category, setCategory] = useState<DropdownCbOption[]>([]);
  const [studios, setStudios] = useState<DropdownCbOption[]>([]);
  const [projectOwner, setProjectOwner] = useState<any>();
  const [projectType, setProjectType] = useState<DropdownCbOption[]>([]);
  const isTypeGrid = projectType.some(
    (el: DropdownCbOption) => el.id === 'GRID_PROJECT'
  );
  const [file, setFile] = useState<any>(null);
  const [packageLevel, setPackageLevel] = useState<DropdownCbOption[]>([]);
  const [dailyRate, setDailyRate] = useState<string>('');
  const [priceType] = useState<string>('Standard');

  const updateDailyRate = (evt: ChangeEvent<HTMLInputElement>) => {
    const { value } = evt.target;
    if (validatePd(value)) setDailyRate(value);
  };

  const updatePermissin = (evt: ChangeEvent<HTMLInputElement>) => {
    setPermission(evt.target.checked);
  };

  const handleSubmit = async (inviteClient?: boolean) => {
    let projectId = null;
    try {
      setSubmitting(true);
      const projectData = await createProject({
        projectName,
        projectType,
        projectOwner: { id: projectOwner?.id },
        studios,
        projectCategories: category,
        level: packageLevel,
        client: selectedUser && { id: selectedUser.id },
        priceType,
        dailyRate
      });

      projectId = projectData.id;

      inviteClient &&
        (await inviteClientToProject({
          accountId: selectedUser?.id,
          projectId
        }));

      file &&
        (await uploadProjectImage({
          projectId,
          file
        }));

      history.push(`/projects/${projectId}`);
      window.scrollTo(0, 0);
    } catch (error) {
      if (error.imageError && projectId) {
        history.push(`/projects/${projectId}`);
        window.scrollTo(0, 0);
      } else {
        handleErrors(error);
      }
      setSubmitting(false);
    }
  };

  const isDisabled =
    !projectName ||
    category.length < 1 ||
    studios.length < 1 ||
    typeof projectOwner !== 'object' ||
    projectType.length < 1 ||
    (isTypeGrid && packageLevel.length < 1) ||
    !dailyRate;

  return (
    <>
      {/* <TopBar placeholder="Search by title or description" /> */}
      <HeaderWrapper>
        <HeaderTop>
          <h2>Create Project</h2>
          <Button small secondary to="/">
            Back to projects
          </Button>
        </HeaderTop>
        <HeaderBottom>
          <p>Please fill in all fields.</p>
        </HeaderBottom>
      </HeaderWrapper>
      <S.wrapper>
        <S.createProjectContent>
          <div>
            <ClientForm
              selectedUser={selectedUser}
              setSelectedUser={setSelectedUser}
              title="Client"
            />
          </div>
          <div>
            <ProjectForm
              projectName={projectName}
              setProjectName={setProjectName}
              category={category}
              setCategory={setCategory}
              studios={studios}
              setStudios={setStudios}
              projectOwner={projectOwner}
              setProjectOwner={setProjectOwner}
              projectType={projectType}
              setProjectType={setProjectType}
              setFile={setFile}
              packageLevel={packageLevel}
              setPackageLevel={setPackageLevel}
              priceType={priceType}
              dailyRate={dailyRate}
              updateDailyRate={updateDailyRate}
            />
          </div>
        </S.createProjectContent>
        <S.footer>
          <CbRadio
            checked={permission}
            onChange={updatePermissin}
            disabled={isDisabled}
          >
            I have already asked the customer for permission.
          </CbRadio>
          <S.footerActions>
            <Button
              secondary
              onClick={() => handleSubmit(false)}
              disabled={isDisabled || submitting || !permission}
            >
              Create Project Example
            </Button>
            <Button
              onClick={() => handleSubmit(true)}
              disabled={
                isDisabled || submitting || !permission || !selectedUser
              }
            >
              Invite client
            </Button>
          </S.footerActions>
        </S.footer>
      </S.wrapper>
    </>
  );
};

export default CreateProject;
