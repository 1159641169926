import { BaseUser } from '../../store/auth/auth.types';

interface Values extends BaseUser {
  company: string;
  gender: string;
  projectName: string;
  projectType: string;
  projectOwner: {} | null;
  studios: [{}];
  projectCategories: [{}];
}

export type GenderType = {
  name: string;
  value: string;
};

export const genderModel = [
  {
    name: 'MALE',
    value: 'Male'
  },
  {
    name: 'FEMALE',
    value: 'Female'
  },
  {
    name: 'DIVERSE',
    value: 'Diverse'
  }
];
