import tw, { styled, css } from 'twin.macro';
import { StyledProps as Props } from '../../types/util.types';
import dots from '../../images/vertical-ellipsis.svg';
import arrow from '../../images/arrow-down.svg';

export const Wrapper = styled.div<Props>(({ active, listView, dropdown }) => [
  css`
    ${!dropdown && tw`relative`}

    > span {
      ${tw`block bg-center bg-no-repeat bg-contain`}
      width: 2.4rem;
      height: 2.4rem;
      border-radius: 2px;
      background-image: url(${dropdown ? arrow : dots});
      background-size: 90% auto;
      transition: all 0.3s;
      cursor: pointer;
      transform: ${dropdown && active && 'rotate(180deg)'};
      &:hover {
        ${!dropdown && tw`bg-gray6`}
      }
    }
  `,
  active &&
    css`
      > span {
        ${!dropdown && tw`bg-gray6`}
      }
    `,
  listView && tw`ml-auto`
]);

export const Dropdown = styled.div<Props>(
  ({ listView, dropdown, checkbox }) => [
    css`
    ${tw`absolute z-10 flex flex-col items-start w-full border-2 border-darkBlue bg-gray10 px-6 pt-6 pb-12 `}
    ${dropdown && tw`py-6 px-0`}
    ${checkbox && tw`px-6 pt-0 pb-8`}
    ${!dropdown && tw`transform -translate-y-55`}
    width:${!dropdown && '25.5rem'};
    max-width: ${dropdown && '35rem'};
    border-radius: 2px;

    hr {
      ${tw`w-full my-4 mx-auto border-t-2 border-gray9`}
    }

    button {
      ${tw`self-end mt-6`}
    }
  `,
    !listView &&
      !dropdown &&
      tw` 
    left-auto sm:left-100
    right-100 sm:right-auto
    translate-x-4`,
    listView && tw`left-auto right-100 -translate-x-4`,
    dropdown &&
      css`
        right: -2px;
        top: 115%;
      `
  ]
);

export const Container = styled.div(() => [
  css`
    ${tw`absolute w-full h-full left-0 top-0 cursor-pointer`};
  `
]);
