import api from '../../config/api.config';
import { AppState } from '../index';
import { handleErrors } from '../../utils/error.utils';
import { toastUtil } from '../../utils/toast.util';
import { replaceSpecialCharacter } from  '../../utils';

import {
  GetMembers,
  ToggleUserStatus,
  ChangeRole,
  UpdateProfile,
  AddMember,
  UpdateAvatar,
  ResendInvitation,
  SearchMemberItem
} from './members.types';

export const getMembers: GetMembers = async ({ page, size, filter, name }) => {
  try {
    const {
      data: { data }
    } = await api(
      `/accounts/overview?page=${page}&size=${size}&filter=${filter}&name=${name}`
    );

    return data;
  } catch (error) {
    handleErrors(error);
    return Promise.reject(error);
  }
};

export const toggleUserStatus: ToggleUserStatus = async (
  accountId,
  disable
) => {
  try {
    await api.patch(`/accounts/${accountId}/${disable ? 'disable' : 'enable'}`);
  } catch (error) {
    handleErrors(error);
    return Promise.reject(error);
  }
};

export const changeRole: ChangeRole = async (accountId, role) => {
  try {
    await api.patch(`/accounts/${accountId}/change-role?role=${role}`);
  } catch (error) {
    handleErrors(error);
    return Promise.reject(error);
  }
};

export const updateProfile: UpdateProfile = async (
  store: AppState,
  firstName,
  lastName,
  email,
  accountId,
  isCurrentUser
) => {
  try {
    const {
      data: { data }
    } = await api.patch(`/accounts/${accountId}/edit`, {
      firstName,
      lastName,
      email
    });
    delete data.id;

    if (isCurrentUser) {
      store.setState({
        ...store.state,
        auth: {
          ...store.state.auth,
          currentUser: {
            ...store.state.auth.currentUser,
            ...data
          }
        }
      });
    }
  } catch (error) {
    handleErrors(error);
    return Promise.reject(error?.response.data || error);
  }
};

export const addMember: AddMember = async ({
  gender,
  role,
  position,
  firstName,
  lastName,
  company,
  email
}) => {
  const upperGender = gender.toUpperCase();
  try {
    const {
      data: { data }
    } = await api.post(`/accounts?`, {
      gender: upperGender,
      role,
      firstName,
      position,
      lastName,
      companyId: company,
      email
    });

    return data;
  } catch (error) {
    handleErrors(error);
    return Promise.reject(error?.response.data || error);
  }
};

export const updateAvatar: UpdateAvatar = async (store, accountId, file) => {
  const formData = new FormData();
  formData.append('file', file);

  try {
    await api.post(`/accounts/${accountId}/images`, formData, {
      headers: { 'Content-Type': 'multipart/form-data' }
    });

    const {
      data: {
        data: { image }
      }
    } = await api(`/accounts/${accountId}/profile`);

    store.setState({
      ...store.state,
      auth: {
        ...store.state.auth,
        currentUser: {
          ...store.state.auth.currentUser,
          image
        }
      }
    });
  } catch (error) {
    handleErrors(
      error,
      'The avatar image is too big. Please upload one with a max size of 1MB.'
    );
    return Promise.reject(error);
  }
};

export const resendInvitation: ResendInvitation = async (invitationEmailId) => {
  try {
    await api.put(`/invitationEmails/${invitationEmailId}/resend`);
  } catch (error) {
    handleErrors(error);
    return Promise.reject(error);
  }
};

export const searchAccounts = async (
  searchTerm: string,
  roles: string
): Promise<SearchMemberItem[]> => {
  try {
    const {
      data: { data }
    } = await api(`/accounts/search?roles=${roles}&name=${searchTerm}`);

    return data;
  } catch (error) {
    handleErrors(error);
    return Promise.reject(error);
  }
};

export const updateAccount = async (
  id: number,
  gender: string,
  firstName: string,
  lastName: string,
  email: string,
  companyId: number,
  role: string
): Promise<any> => {
  try {
    const {
      data: { data }
    } = await api.put(`/accounts/${id}`, {
      gender,
      firstName,
      lastName,
      email,
      companyId: companyId,
      role
    });
    toastUtil('success', 'User was successfully updated');
    return data;
  } catch (error) {
    handleErrors(error);
    return Promise.reject(error);
  }
};

export const updateAccountCompany = async (
  id: number,
  name: string
): Promise<any> => {
  try {
    const {
      data: { data }
    } = await api.patch(`/accounts/${id}/updateCompany`, { name: replaceSpecialCharacter(name) });
    toastUtil('success', 'Company name was successfully updated');
    return data;
  } catch (error) {
    handleErrors(error);
    return Promise.reject(error);
  }
};
