export const validatePd = (s: string | number) => {
  const price = s + '';
  // var rgx = /^[0-9]*\.?[0-9]{0,2}$/;
  var hasLetters = price.match(/[a-zA-Z]+/g);
  return hasLetters ? false : true;
};

export const validatePrice = (s: string | number) => {
  const price = s + '';
  var rgx = /^[0-9]*$/;
  return price.match(rgx);
};

export const numberWithCommas = (n: number) => {
  return n.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
};

export const isInvalidValue = (s: any, min: number = 0) =>
  s * 1 < min || s === '' || s === '.';
