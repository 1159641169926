import React, { useEffect, useState, useRef } from 'react';
import { Redirect } from 'react-router-dom';
import useGlobal from '../../store';
import { getProjects } from '../../store/projects/projects.actions';
import { Project } from '../../store/projects/projects.types';
import {
  HeaderWrapper,
  HeaderTop,
  HeaderBottom
} from '../../components/Header/StyledHeader';
import HeaderFilter, { Filters } from '../../components/Header/HeaderFilter';
import Button from '../../components/Button';
import ViewType from '../../components/ViewType';
import TopBar from '../../components/TopBar';
import Pager from '../../components/Pager';
import ProjectItem from './ProjectItem';
import CardLoader from '../../components/CardLoader';
import * as S from './projects-page';
import active from '../../images/active-project.svg';
import star from '../../images/star-outline.svg';
import archive from '../../images/archived.svg';
import sortIcon from '../../images/order.svg';
import { routePaths } from '../../utils/routes.util';
import { NUMBER_OF_ITEMS_FOR_PAGINATION, PLACEHOLDER_ARRAY } from '../../utils/general.utils';
import { isClientRole } from '../../utils/index'
import { AuthRoutes } from '../../utils/routeEnums.utils';

const filters: Filters[] = [
  {
    label: 'Active',
    icon: active,
    value: 'active'
  },
  {
    label: 'Favorite',
    icon: star,
    value: 'favorite'
  },
  {
    label: 'Archived',
    icon: archive,
    value: 'archived'
  }
];

const Projects = () => {
  const first = useRef(true);
  const [listView, setListView] = useState<boolean>(false);
  const [filter, setFilter] = useState<string>('active');
  const [projects, setProjects] = useState<Project[]>([]);
  const [name, setName] = useState<string>('');
  const [sort, setSort] = useState<boolean>(true);
  const [page, setPage] = useState<number>(0);
  const [totalPageNumber, setTotalPageNumber] = useState<number>(0);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [user] = useGlobal((state) => state.auth.currentUser);

  const fetchProjects = async () => {
    try {
      const { totalPageNumber, content } = await getProjects({
        page,
        size: NUMBER_OF_ITEMS_FOR_PAGINATION,
        filter,
        name,
        sort
      });
      setProjects(content);
      setTotalPageNumber(totalPageNumber);
      setIsLoading(false)
    } catch (error) {
      console.warn(error);
      setIsLoading(false)
    }
  };

  const toggleSort = () => setSort(!sort);

  useEffect(() => {
    if (first.current) return;
    page === 0 ? fetchProjects() : setPage(0);
    // eslint-disable-next-line
  }, [filter, name, sort]);

  useEffect(() => {
    first.current = false;
    fetchProjects();
    // eslint-disable-next-line
  }, [page]);

  if (!user) {
    return null
  }

  if (isClientRole(user)) {
    return (
      <Redirect to={AuthRoutes.clientProjects} />
    )
  }

  return (
    <>
      <TopBar setSearch={setName} placeholder="Search by title" />
      <HeaderWrapper>
        <HeaderTop>
          <h2>Projects</h2>
          <Button small to={routePaths.PROJECTS_CREATE}>
            Create new project
          </Button>
        </HeaderTop>
        <HeaderBottom>
          <HeaderFilter
            selected={filter}
            setSelected={setFilter}
            list={filters}
          />
          <S.headerActions>
            <span onClick={toggleSort}>
              <img src={sortIcon} alt="" />
              {sort ? 'Ascending' : 'Descending'}
            </span>
            <ViewType setList={setListView} isListView={listView} />
          </S.headerActions>
        </HeaderBottom>
      </HeaderWrapper>
      <S.projectList listView={listView}>
        {
          isLoading ? (
            PLACEHOLDER_ARRAY.map((p:any, i:number) => (
              <CardLoader key={i}/>
            ))
          ): (
            projects.map((p: Project) => (
              <ProjectItem
                key={p.id}
                {...p}
                listView={listView}
                onChangeCallback={fetchProjects}
              />
            ))
          )
        }
      </S.projectList>
      {totalPageNumber > 1 && (
        <Pager page={page} setPage={setPage} pageCount={totalPageNumber} />
      )}
    </>
  );
};

export default Projects;
