import React, { useState, useMemo, useContext } from 'react';
import { useParams } from 'react-router-dom';
import {
  ProjectProgressPhase,
  ProjectProgressCategories
} from '../../../store/projects/projects.types';
import { removePhase } from '../../../store/projects/projects.actions';
import { ProgressContext } from '../Progress';
import * as S from './project-phases';
import Modal from '../../../components/Modal';
import Button from '../../../components/Button';
import AddPhase from './AddPhase';
import PhaseItem from './PhaseItem';
import images from '../../../utils/images.utils';

interface Params {
  id: string;
}

const ProjectPhases = () => {
  const {
    updateProject,
    project: { projectCategories, phases },
    isMemberRole
  } = useContext(ProgressContext);
  const { id } = useParams<Params>();
  const [active, setActive] = useState<boolean>(false);
  const [deletePhaseId, setDeletePhaseId] = useState<number | null>(null);
  const [activeDelete, setActiveDelete] = useState<boolean>(false);
  const [isDeleting, setIsDeleting] = useState<boolean>(false);

  const parsedPhases: string = useMemo(() => {
    return projectCategories
      .map((c: ProjectProgressCategories) => c.name)
      .join(',');
  }, [projectCategories]);

  const onDelete = async () => {
    try {
      setIsDeleting(true);
      const data = await removePhase(deletePhaseId!, id);
      updateProject(data);
      closeDeleteModal();
      setIsDeleting(false);
    } catch {
      setIsDeleting(false);
    }
  };

  const closeModal = () => {
    setActive(false);
  };

  const showModal = () => {
    setActive(true);
  };

  const showDeleteModal = (id: number) => {
    setActiveDelete(true);
    setDeletePhaseId(id);
  };

  const closeDeleteModal = () => {
    setActiveDelete(false);
    setDeletePhaseId(null);
  };

  return (
    <>
      <S.wrapper>
        <h3>Phases</h3>
        <S.body>
          {phases.map((p: ProjectProgressPhase, i: number) => (
            <PhaseItem
              key={p.id}
              index={i}
              phase={p}
              onDelete={showDeleteModal}
              categories={parsedPhases}
            />
          ))}
          <S.addButton onClick={showModal} disabled={isMemberRole ? 1 : 0}>
            <img src={images.addIcon} alt="Add phase" />
            Add phase
          </S.addButton>
        </S.body>
        <Modal isOpen={active} onDismiss={closeModal}>
          <AddPhase closeModal={closeModal} />
        </Modal>
        <Modal isOpen={activeDelete} onDismiss={closeDeleteModal}>
          <S.deleteModal>
            <p>Are you sure that you want to delete this phase?</p>
            <div>
              <Button
                secondary
                onClick={closeDeleteModal}
                disabled={isDeleting}
              >
                Cancel
              </Button>
              <Button onClick={onDelete} disabled={isDeleting}>
                Confirm
              </Button>
            </div>
          </S.deleteModal>
        </Modal>
      </S.wrapper>
    </>
  );
};

export default ProjectPhases;
