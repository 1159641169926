import tw, { styled, css } from 'twin.macro';
import { StyledProps as Props } from '../../types/util.types';

export const wrapper = styled.div<Props>(({ active, invalid, disabled }) => [
  css`
    ${tw`relative flex items-center border-2 border-solid border-darkBlue`};
    min-width: 12rem;
    max-width: 12rem;

    input {
      ${tw`w-full pl-2 border-0 outline-none bg-transparent text-22 text-right font-semibold`}
      padding-right: 4.5rem;
    }

    span {
      ${tw`absolute text-22`}
      right: 1.5rem;
    }
  `,
  (!active || disabled) &&
    css`
      input,
      span {
        opacity: 0.5;
        pointer-events: none;
      }
    `,
  active &&
    invalid &&
    css`
      ${tw`bg-error text-white`}
      input {
        color: #fff;
      }
    `
]);
