import React from 'react';
import * as S from './services-list';
import Filter from '../Filter';
import ListItem from '../ListItem';
import ListLoader from '../../../components/ListLoader';
import Pager from '../../../components/Pager';
import { SetStateType } from '../../../types/util.types';
interface Props {
  items?: any;
  setQualityType: SetStateType<string>;
  totalPageNumber: number;
  page: number;
  setPage: SetStateType<number>;
  setLevel: SetStateType<string>;
  setServiceType: SetStateType<string>;
  isLoading: boolean;
  placeHolderArray: any;
  fetchAllServices: () => Promise<void>;
}

const List = ({
  items,
  setQualityType,
  totalPageNumber,
  page,
  setPage,
  setLevel,
  setServiceType,
  isLoading,
  placeHolderArray,
  ...rest
}: Props) => {
  return (
    <S.wrapper>
      <S.content>
        <Filter
          setQualityType={setQualityType}
          setLevel={setLevel}
          setServiceType={setServiceType}
        />
        {isLoading
          ? placeHolderArray.map((s: any, i: number) => <ListLoader key={i} />)
          : items.map((s: any) => (
              <ListItem key={`${s.serviceType}_${s.id}`} {...s} {...rest} />
            ))}
      </S.content>
      {totalPageNumber > 1 && (
        <Pager page={page} setPage={setPage} pageCount={totalPageNumber} />
      )}
    </S.wrapper>
  );
};

export default List;
