import tw, { styled, css } from 'twin.macro';
import { StyledProps as Props } from '../../../types/util.types';
import { Dropdown } from '../../../components/Actions/actions-component';

export const wrapper = styled.div<Props>(
  ({
    theme: {
      media: {
        breakpoints: { smMax }
      }
    }
  }) => [
    css`
      ${tw`w-full lg:mr-30`};
      max-width: 25.5rem;

      ${Dropdown} {
        ${tw`max-w-full sm:max-w-none`}
        width: 16rem;
      }

      @media screen and (max-width: ${smMax}px) {
        max-width: none;
      }
    `
  ]
);
