export const NUMBER_OF_ITEMS_FOR_PAGINATION = 12;
export const PLACEHOLDER_ARRAY = Array.from(Array(NUMBER_OF_ITEMS_FOR_PAGINATION).keys())

export const colorPicker = (disciplineName: string) => {
    switch (disciplineName) {
        case 'UX Design': return '#ed0c6e';
        case 'Industrial Design': return '#3cd52e';
        case 'Software Development': return '#00a7e1';
        default:
            return '#ffc222';
    } 
        
}