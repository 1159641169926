import tw, { styled, css } from 'twin.macro';

export const addEventWrap = styled.span(() => [
  css`
    ${tw`flex items-center
        self-end
        order-last
        sm:order-none
        mt-20
        sm:mt-0
        ml-auto
        sm:mr-auto sm:ml-44
        text-22 font-semibold text-darkBlue
        cursor-pointer
      `}

    img {
      ${tw`mr-14`}
      width: 3rem;
    }
  `
]);

export const wrapper = styled.div(() => [
  css`
    ${tw`flex flex-col bg-white shadow-discipline w-540 max-w-full
        md:px-95 
        px-40 pt-27 pb-40`}

    h3 {
      ${tw`
          text-textBlack text-32 leading-36 font-semibold 
          mb-35`}
    }     

        h4 {
         ${tw`w-full text-24 font-semibold leading-32 mb-18 text-textBlack`}
     }

     button {
         ${tw`w-auto ml-auto md:-mr-63 -mr-15`}
     }     
  `
]);

export const event = styled.div(() => [
    css`
     ${tw`mt-35`}
    `
])

export const colorPicker = styled.div(() => [
    css`
     ${tw`flex flex-wrap
     text-textBlack text-24 leading-32 font-semibold
     mb-28`}
    `
])

interface ColorBoxProps {
    active: boolean;
    color: string;
}

// const colorBlue = '#1e445c';
// const colorRed = '#ed0c6e';

export const colorBox = styled.span<ColorBoxProps>(({active, color}) => [
    css`
    ${tw`block h-40 w-40 bg-black last:ml-24 cursor-pointer`}
    background-color: ${color === 'BLUE' ? '#1e445c' : '#ed0c6e'};
    `,
    active && css`
    ${tw`border-4 border-blue`}
    `
])

interface ReviewBox {
    lineColor?: string;
    iconColor?: string;
}

export const review = styled.div<ReviewBox>(({ lineColor, iconColor }) => [
  css`
    ${tw`border-2 h-200 border-darkBlueHover py-40 pl-40 mb-32`}

    div {
        ${tw`flex flex-col items-start 
        h-full 
        border-l-2
        pl-5
        text-textBlack`}
        border-color: rgba(30, 68, 92, .25);
        ${
          lineColor === 'BLUE' && 'border-color: #1e445c;'
        }
        ${
          lineColor === 'RED' && 'border-color: #ed0c6e;'
        }

        svg {
            path {
                fill: rgba(30, 68, 92, .25);
                ${
                  iconColor === 'BLUE' && 'fill: #1e445c;'
                }
                ${
                  iconColor === 'RED' && 'fill: #ed0c6e;'
                }
            }
        }
    }
    `
])

export const iconSearchWrapper = styled.div(() => [
    css`
     ${tw`relative mb-28 z-50`}   
    `
])

export const iconInput = styled.div(() => [
    css`
    ${tw`flex flex-col relative w-full`};

    input {
      ${tw`outline-none bg-transparent 
        font-semibold  text-2xl md:text-4xl leading-32 text-textBlack pl-10 
        border-2 border-darkBlue hover:bg-darkBlueHover
        p-16 pr-40`}
      min-height: 64px;

      &:focus +label {
        ${tw`text-blue`}
      }
    }

    svg {
        ${tw`absolute transform -translate-y-1/2`}
        right: 1.6rem;
        top: 50%;

        path {
          fill: rgba(30, 68, 92, .5);
        }
    }
  `
])

export const iconList = styled.div(() => [
    css`
     ${tw`absolute w-full z-10 py-15 
          border-2 border-darkBlue 
          top-100 left-0 bg-gray10`}   
    
    transform: translateY(.5rem);

    span {
      ${tw`flex items-center justify-between p-10 cursor-pointer font-semibold text-18 text-darkBlue`}

      svg {
        path {
          fill: #1e445c;
        }
      }

      &:hover {
        ${tw`bg-darkBlue text-white`}

        svg {
          path {
            fill: white;
          }
        }
      }
    }
    `
])

interface LabelProp {
    hasValue: boolean;
    open: boolean;
}

export const iconSearchLabel = styled.label<LabelProp>(({ hasValue, open}) => [
  css`
    ${tw`absolute top-0 text-gray11 transition duration-300 text-22 leading-32 font-semibold`}
    left: 1.6rem;
    transform: translateY(1.6rem);
    pointer-events: none;
  `,
  hasValue &&
    css`
    ${tw`leading-none text-14 text-gray11`}
      transform: translateY(0.6rem);
    `,
  open &&
    css`
     ${tw`text-blue`}
    `
]);