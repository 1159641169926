import { ReactComponent as Appointment } from '../images/calendar.svg';
import { ReactComponent as OfferAccept } from '../images/confirmed.svg';
import { ReactComponent as Document } from '../images/list.svg';
import { ReactComponent as WeCare } from '../images/we-care.svg';
import { ReactComponent as Call } from '../images/call.svg';
import { ReactComponent as MessageSent } from '../images/paper-plane.svg';
import { ReactComponent as Edits } from '../images/edit.svg';
import { ReactComponent as PersonalMeet } from '../images/speech-bubble.svg';
import { ReactComponent as Fair } from '../images/fair.svg';
import { ReactComponent as Comment } from '../images/commentary.svg';

const fetchIcon = (icon: string) => {
  switch (icon) {
    case 'date':
      return Appointment;
    case 'accepted':
      return OfferAccept;
    case 'document':
      return Document;
    case 'weCare':
      return WeCare;
    case 'call':
      return Call;
    case 'message':
      return MessageSent;
    case 'edit':
      return Edits;
    case 'talkMeeting':
      return PersonalMeet;
    case 'fair':
      return Fair;
    case 'comment':
      return Comment;
    default:
      return WeCare;
  }
};

const colors: { [key: string]: string } = {
  BLUE: '#1e445c',
  RED: '#ed0c6e'
};

export {
  colors,
  fetchIcon,
  Appointment,
  OfferAccept,
  Document,
  WeCare,
  Call,
  MessageSent,
  Edits,
  PersonalMeet,
  Fair,
  Comment
};
