import React from 'react';
import { Redirect, RouteComponentProps, Route } from 'react-router-dom';
import { AuthRoutes } from  '../../../utils/routeEnums.utils'

interface Props {
	Component: any
	path: string;
    exact?: boolean;
}
const NonAuthRoute = ({ Component, path, exact = false }: Props): JSX.Element => {
    const isAuthed = !!localStorage.getItem('cpcToken');
	return (
		<Route
			exact={exact}
			path={path}
			render={(props: RouteComponentProps) =>
				isAuthed ? (
                    <Redirect
						to={{
							pathname: AuthRoutes.root,
							state: { 
								requestedPath: path 
							}
						}}
					/>
				) : (
					<Component {...props} />
				)
			}
		/>
	);
};

export default NonAuthRoute