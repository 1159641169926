export const routePaths: { [key: string]: string } = {
  ROOT: '/',
  LOGIN: '/login',
  RESET_PASSWORD: '/reset-password',
  PROJECTS: '/projects',
  PROJECTS_CREATE: '/projects/create',
  SINGLE_PROJECT: '/projects/:id',
  PROJECT_SUMMARY: '/projects/:id/summary',
  EDIT_PROJECT: 'projects/:id/edit',
  ACTIVITY: '/activity',
  PROFILE: '/profile',
  MEMBERS: '/members',
  MEMBERS_CREATE: '/members/create',
  SERVICES: '/services',
  SERVICES_NEW_METHOD: '/services/new-method',
  SERVICES_NEW_SERVICE: '/services/new-service',
  CLIENTS: '/clients'
};
