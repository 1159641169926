import tw, { styled, css } from 'twin.macro';
import { StyledProps as Props } from '../../types/util.types';
import { Link } from 'react-router-dom';

export const StyledButton = styled.button<Props>(
  ({ small, frameless, secondary }) => [
    css`
      ${tw`bg-pink relative flex items-center text-white text-22 justify-center border-0 font-semibold cursor-pointer outline-none! rounded-default shadow-button hover:shadow-buttonHover focus:shadow-buttonFocus hover:bg-pinkDark leading-none`}
      padding: 0.8rem 2.4rem;
      min-height: 4.8rem;
      transition: background 0.3s, box-shadow 0.3s;

      &:disabled {
        ${tw`opacity-50 pointer-events-none shadow-none`}
      }
      img,
      svg {
        ${tw`mr-10`}
        width: 3.2rem;
      }

      svg {
        path {
          fill: white;
        }
      }
    `,
    frameless &&
      css`
        ${tw`bg-transparent text-darkBlue shadow-none hover:shadow-none hover:bg-gray6 hover:bg-opacity-50 focus:shadow-outline active:bg-gray6`}
      `,

    secondary === 1 &&
      css`
        ${tw`bg-darkBlue shadow-buttonSecondary hover:shadow-buttonSecondaryHover hover:bg-darkBlue2 focus:shadow-buttonSecondaryFocus `}
      `,
    small === 1 &&
      css`
        ${tw`text-18 min-h-4`}
        padding: 0.8rem 1.6rem;
        img {
          width: 2.4rem;
          margin-right: 0.8rem;
        }
      `
  ]
);

export const StyledLinkButton = styled(Link)<Props>(
  ({ small, disabled, frameless, secondary }) => [
    css`
      ${tw`bg-pink relative flex items-center text-white text-22 justify-center border-0 font-semibold cursor-pointer outline-none! rounded-default shadow-button hover:shadow-buttonHover hover:bg-pinkDark hover:text-white focus:shadow-buttonFocus leading-none`}
      padding: 0.8rem 2.4rem;
      min-height: 4.8rem;
      transition: background 0.3s, box-shadow 0.3s;

      img {
        ${tw`mr-10`}
        width: 3.2rem;
      }
    `,
    frameless &&
      css`
        ${tw`bg-transparent text-darkBlue shadow-none hover:shadow-none hover:bg-gray6 hover:bg-opacity-50 focus:shadow-outline active:bg-gray6`}
      `,

    secondary === 1 &&
      css`
        ${tw`bg-darkBlue shadow-buttonSecondary hover:shadow-buttonSecondaryHover hover:bg-darkBlue2 focus:shadow-buttonSecondaryFocus `}
      `,
    small === 1 &&
      css`
        ${tw`text-18 min-h-4`}
        padding: 0.8rem 1.6rem;
        img {
          width: 2.4rem;
          margin-right: 0.8rem;
        }
      `,
    disabled &&
      css`
        ${tw`opacity-50 pointer-events-none shadow-none`}
      `
  ]
);
