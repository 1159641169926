import tw, { styled, css } from 'twin.macro';
// import { StyledProps as Props } from '../../types/util.types';
import { StyledButton } from '../../../components/Button/styles';

interface RoleBasedControlProps {
  disabled?: number;
}

export const wrapper = styled.div(() => [
  css`
    ${tw`mx-30`};

    h3 {
      ${tw`text-textBlack text-48 leading-none mb-28`};
    }
  `
]);

export const body = styled.div(() => [
  css`
    ${tw`
        w-full 
        rounded-4 bg-white shadow-discipline 
        px-95 sm:px-10 md:px-20 lg:px-50 xxl:px-95
        py-32
    `}
    box-shadow: 0px 2px 8px 0px rgb(0 0 0 / 15%);
    p {
      ${tw`text-textBlack text-32 leading-44 font-normal mb-44`}
    }
  `
]);

export const itemsWrapper = styled.div(() => [
  css`
    ${tw`grid grid-cols-1 sm:grid-cols-2 xxl:grid-cols-4 xl:grid-cols-3 gap-30 mb-32`}
  `
]);

export const item = styled.div(() => [
  css`
    ${tw`bg-darkBlue bg-opacity-05 rounded-t-4`}
    width: 24rem;

    h4 {
      ${tw`text-18 text-textBlack font-semibold leading-28 mb-5`}
    }
  `
]);

export const itemHeader = styled.header(() => [
  css`
    ${tw`
        flex
        items-center
        justify-center
        relative
        `}
    height: 24rem;

    img {
      ${tw`w-full h-full object-contain rounded-t-4`}
    }

    span {
      ${tw`text-white lowercase bg-green2 font-semibold p-30 rounded-4 text-48 leading-56`}
    }
  `
]);

export const itemBody = styled.div(() => [
  css`
    ${tw`
      w-full 
      flex flex-col 
      content-center justify-center 
      pl-24 pb-24 pt-16
      text-13 text-textBlack leading-28 font-semibold`}
  `
]);

export const itemMain = styled.div(() => [
  css`
    ${tw`
        flex flex-col`}
  `
]);

export const itemFooter = styled.div(() => [
  css`
    ${tw`mt-20`}

    span {
      ${tw`flex items-center pr-5 break-all`}

      &:first-child:not(:last-child) {
        ${tw`mb-8`}
      }

      img {
        ${tw`mr-5`}
        width: 1.5rem;
        height: 1.5rem;
      }
    }
  `
]);

export const addMemberWrap = styled.span<RoleBasedControlProps>(
  ({ disabled }) => [
    css`
      ${tw`flex items-center
        text-22 font-semibold text-darkBlue ml-20
        cursor-pointer
      `}

      img {
        ${tw`mr-13`}
        width: 2.8rem;
      }
    `,
    disabled === 1 &&
      css`
        ${tw`pointer-events-none opacity-50`}
      `
  ]
);

export const modalWrapper = styled.div(() => [
  css`
    ${tw`flex flex-col items-start bg-gray10 border-2 border-darkBlue w-730 max-w-full
        p-32`}

    h3 {
      ${tw`
          text-textBlack text-32 leading-36 font-semibold 
          mb-0`}
    }
    ${StyledButton} {
      ${tw`ml-auto mt-26`}
    }
  `
]);

export const memberInput = styled.div(() => [
  css`
    ${tw`flex items-center relative
        mt-32
        w-full
        pr-50
   `}

    img {
      ${tw`absolute right-0 cursor-pointer`}
    }

    > div {
      ${tw`mb-0`}
    }
  `
]);

export const wrapperItem = styled.span(() => [
  css`
    ${tw`flex items-center
         pl-16 pt-13 pb-12
         text-22 font-semibold text-darkBlue
         bg-white
         border-2 border-darkBlue h-17 w-full`}

    img {
      ${tw`mr-16`}
    }
  `
]);

export const modalAddMember = styled.span(() => [
  css`
    ${tw`flex items-center mt-24
        text-22 font-semibold text-darkBlue
        cursor-pointer
      `}

    img {
      ${tw`mr-14 ml-20`}
      width: 2.8rem;
    }
  `
]);
