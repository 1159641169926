import React, { useState, useContext } from 'react';
import { useParams } from 'react-router-dom';
import addIcon from '../../../images/add-2.svg';
import removeIcon from '../../../images/delete.svg';
import NewSearchInput from '../../../components/Inputs/NewSearchInput';
import Button from '../../../components/Button/Button';
import { roles } from '../../../utils/roles.utils';
import { addProjectMembers } from '../../../store/projects/projects.actions';
import { searchAccounts } from '../../../store/members/members.actions';
import {
  SearchMemberType,
} from '../../../store/projects/projects.types';
import { toastUtil } from '../../../utils/toast.util';
import {ProgressContext} from '../Progress';

import * as S from './project-members';

interface Props {
  closeModal: () => void;
}

interface Params {
  id: string;
}

interface MemberState {
  id: null | number;
  value: string;
}

const AddMember = ({ closeModal }: Props) => {
  const { id } = useParams<Params>();
  const { updateProject } = useContext(ProgressContext);

  const [members, setMembers] = useState<MemberState[]>([
    { id: null, value: '' }
  ]);

  const newMember = () => {
    if (!members[members.length - 1]) return;
    setMembers([...members, { id: null, value: '' }]);
  };

  const searchMember = async (searchTerm: string) => {
    try {
      const searchResult = await searchAccounts(
        searchTerm,
        `${roles.superAdmin},${roles.admin},
      ${roles.manager},${roles.member}`
      );
      return searchResult;
    } catch (error) {}
  };

  const confirm = async () => {
    try {
      const res = await addProjectMembers(
        id,
        members.map((m: MemberState) => m.id)
      );
      closeModal();
      updateProject(res);
    } catch {}
  };

  const onSelectValue = (item: SearchMemberType, index: number) => {
    const updatedMembers = [...members];
    const isExistingMember = updatedMembers.find(
      (m: MemberState) => m.id === item.id
    );
    if (isExistingMember) {
      toastUtil('warning', 'Member already selected');
      return;
    }
    updatedMembers[index] = {
      id: item.id,
      value: `${item.firstName} ${item.lastName}`
    };
    setMembers(updatedMembers);
  };

  const deleteMember = (index: number) => {
    const updatedMembers = [...members];
    updatedMembers.splice(index, 1);
    setMembers(updatedMembers);
  };

  return (
    <S.modalWrapper>
      <h3>Add member to team</h3>
      {members.map((m: MemberState, index: number) => (
        <S.memberInput key={index}>
          <NewSearchInput
            setValue={(value: string) => {
              const updatedMembers = [...members];
              updatedMembers[index] = { id: null, value };
              setMembers(updatedMembers);
            }}
            selectValue={(item: SearchMemberType) => {
              onSelectValue(item, index);
            }}
            onSearch={searchMember}
            value={members[index].value}
            label="Search member"
            labelProps={['firstName', 'lastName']}
          />
          {index !== 0 && (
            <img
              onClick={() => deleteMember(index)}
              src={removeIcon}
              alt="Remove Member"
            />
          )}
        </S.memberInput>
      ))}
      <S.modalAddMember onClick={newMember}>
        <img src={addIcon} alt="Add Member" />
        More members
      </S.modalAddMember>
      <Button secondary onClick={confirm}>
        Confirm
      </Button>
    </S.modalWrapper>
  );
};

export default AddMember;
