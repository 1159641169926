import tw, { styled, css } from 'twin.macro';
import { StyledProps as Props } from '../../../types/util.types';

export const wrapper = styled.div<Props>(() => [
  css`
    ${tw`bg-white pb-30  `}
    max-width: 1504px;
    box-shadow: 0px 2px 8px 0px rgba(0, 0, 0, 0.15);
  `
]);

export const content = styled.div(() => [
  css`
    ${tw`bg-white pb-20 `};
    overflow: auto;
    min-height: 48rem;

    ::-webkit-scrollbar {
      display: none;
      -ms-overflow-style: none;
      scrollbar-width: none;
    }
  `
]);
