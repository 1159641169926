import tw, { styled, css } from 'twin.macro';
import { Dropdown } from '../../../components/Actions/actions-component';
import StyledCbRadio from '../../../components/Inputs/checkbox-radio';

export const wrapper = styled.div(
  ({
    theme: {
      media: {
        breakpoints: { smMax }
      }
    }
  }) => [
    css`
      ${tw`w-full md:mr-30`};
      max-width: 25.5rem;

      ${Dropdown} {
        ${tw`max-w-full sm:max-w-none px-15! pt-0! pb-20`}
        width: 35rem;
      }

      ${StyledCbRadio} {
        &:before {
          ${tw`block h-16 mr-15 bg-pink`}
          content: '';
          width: 0.4rem;
        }

        &:nth-child(2) {
          &:before {
            ${tw` bg-green`}
          }
        }
        &:nth-child(3) {
          &:before {
            ${tw` bg-yellow`}
          }
        }
        &:nth-child(4) {
          &:before {
            ${tw` bg-blue`}
          }
        }
      }

      @media screen and (max-width: ${smMax}px) {
        max-width: none;
      }
    `
  ]
);
