import { FormFieldType } from '../../../types/form.types';
import { BaseUser } from '../../../store/auth/auth.types';

interface Values extends BaseUser {
  position: string;
  company: string;
  gender: string;
}

export type GenderType = {
  name: string;
  value: string;
};

export const initialValues: Values = {
  gender: 'Male',
  position: '',
  firstName: '',
  lastName: '',
  email: '',
  company: '',
  role: ''
};

export const formModel: FormFieldType[] = [
  {
    name: 'firstName',
    type: 'text',
    title: 'Firstname'
  },
  {
    name: 'lastName',
    type: 'text',
    title: 'Lastname'
  },
  {
    name: 'email',
    type: 'text',
    title: 'Email address'
  },
  {
    name: 'company',
    type: 'dropdown',
    title: 'Company Name'
  }
];
export const genderModel = [
  {
    name: 'male',
    value: 'Male'
  },
  {
    name: 'female',
    value: 'Female'
  },
  {
    name: 'diverse',
    value: 'Diverse'
  }
];

export const RoleOptions = [
  { id: 'ROLE_ADMIN', name: 'Project Admin' },
  { id: 'ROLE_MANAGER', name: 'Project Manager' },
  { id: 'ROLE_PROJECT_MEMBER', name: 'Project Member' }
];
