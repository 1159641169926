import React, { ChangeEvent } from 'react';
import { ProjectMethodQualities } from '../../../store/projects/projects.types';
import DaysLog from './DaysLog';
import * as S from './phase-method';

interface Props {
  onChange: (evt: ChangeEvent<HTMLInputElement>) => void;
  complexity: ProjectMethodQualities;
}

const MethodDays = ({ onChange, complexity: { personDays, log } }: Props) => {
  return (
    <S.pd>
      {log && <DaysLog log={log} />}
      <input onChange={onChange} value={personDays} />
      <span>pd</span>
    </S.pd>
  );
};

export default MethodDays;
