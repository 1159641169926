import tw, { styled, css } from 'twin.macro';
import { StyledProps } from '../../types/util.types';

export const wrapper = styled.div<StyledProps>(({ disabled, small }) => [
  css`
    ${tw`flex justify-start items-center mb-30 flex-row-reverse w-full relative outline-none bg-transparent font-semibold  text-black text-2xl md:text-4xl border-darkBlue border-2 border-solid`}

    height:6.4rem;
    padding: 1.6rem 1.6rem;
    transition: all 0.3s;

    &:hover {
      background-color: rgba(30, 68, 92, 0.15);
    }
  `,
  disabled &&
    css`
      opacity: 0.5;
      pointer-events: none;
    `
]);

export const items = styled.div<StyledProps>(({ icon }) => [
  css`
    ${tw`block whitespace-no-wrap overflow-hidden pl-1 pr-20 mr-auto text-textBlack`};
    text-overflow: ellipsis;

    padding-left: ${icon && '2.8rem'};
  `
]);

export const label = styled.label<StyledProps>(
  ({ hasValue, open, labelColor, icon, small }) => [
    css`
      ${tw`absolute top-0 font-semibold text-22`}

      left: ${icon ? '4.5rem' : '1.8rem'};
      color: ${labelColor ? labelColor : '#929da2'};
      transform: translateY(1.6rem);

      transition: all 0.3s;
      line-height: 2.8rem;
      pointer-events: none;
    `,
    hasValue &&
      css`
        transform: translateY(0.6rem);

        font-size: 1.4rem;
        line-height: 1;
        color: ${open ? '#00a7e1' : '#929da2'};
      `,

    open &&
      css`
        color: ${'#00a7e1'};
      `
  ]
);

export const options = styled.div(() => [
  css`
    ${tw`cursor-pointer p-10 font-semibold text-18 w-full text-textBlack`}
    &:hover {
      background-color: #1e445c;
      color: white;
    }
  `
]);
export const noOptions = styled.div(() => [
  css`
    ${tw`p-10 font-semibold text-22`}
    pointer-events:none;
  `
]);

export const iconWrapper = styled.div(() => [
  css`
    ${tw`flex absolute `};
    left: 10px;
    svg {
      width: 3rem;
      height: 3rem;
    }
  `
]);
