import React, { useState, useRef } from 'react';
import useOnClickOutside from 'use-onclickoutside';
import Actions from '../../../components/Actions';
import { ProjectMethodQualities } from '../../../store/projects/projects.types';
import { ReactComponent as IconCheck } from '../../../images/check.svg';
import * as S from './phase-method';

interface Props {
  qualities: ProjectMethodQualities[];
  onClick: (selected: ProjectMethodQualities) => void;
}

const qualityLables: { [key: string]: string } = {
  STANDARD: 'Standard',
  HIGH: 'High',
  EXTREME: 'Extreme',
  RESPONSIVE_DESIGN: 'Responsive Design'
};

const MethodComplexity = ({ qualities, onClick }: Props) => {
  const ref = useRef(null);
  const [active, setActive] = useState<boolean>(false);
  const toggle = () => setActive(!active);
  const close = () => {
    if (active) setActive(false);
  };

  useOnClickOutside(ref, close);

  return (
    <S.complexity ref={ref}>
      {
        qualityLables[
          qualities.find((q: ProjectMethodQualities) => q.picked)!.type
        ]
      }
      <Actions toggle={toggle} open={active} close={close} dropdown>
        {qualities
          .sort((a: ProjectMethodQualities, b: ProjectMethodQualities) =>
            a.projectQualityId > b.projectQualityId ? 1 : -1
          )
          .map((q: ProjectMethodQualities) => (
            <S.complexityItem
              key={q.projectQualityId}
              onClick={() => {
                onClick(q);
                close();
              }}
            >
              {qualityLables[q.type]}
              {q.picked && <IconCheck />}
            </S.complexityItem>
          ))}
      </Actions>
    </S.complexity>
  );
};

export default MethodComplexity;
