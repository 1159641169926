import React from 'react';
import { Company } from '../../store/auth/auth.types';
import * as S from './user-role';
import { roleLabels } from '../../utils/roles.utils';

interface Props {
  role: string;
  company?: Company;
}

const UserRole = ({ role, company }: Props) => {
  return (
    <S.wrapper company={company}>
      {company ? company.name : roleLabels[role]}
    </S.wrapper>
  );
};

export default UserRole;
