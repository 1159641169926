import tw, { styled, css } from 'twin.macro';
import { StyledProps as Props } from '../../../types/util.types';
import { Link } from 'react-router-dom';

export const wrapper = styled.div(() => [
  css`
    ${tw`flex flex-col w-full text-textBlack justify-center pb-120 pt-80 xl:pt-0 px-10 md:px-0`}
  `
]);
export const formTitle = styled.div<Props>(({ isClient }) => [
  isClient ? tw`flex flex-col-reverse` : tw`flex flex-col`
]);

export const title = styled.h1(() => [
  css`
    ${tw`flex `};
  `
]);

export const subtitle = styled.div<Props>(
  ({
    isClient,
    theme: {
      typography: { sectionSubtitle }
    }
  }) => [
    css`
      ${sectionSubtitle}
      ${tw`mb-80 font-normal leading-44 whitespace-pre-line`};
      max-width: ${isClient && '540px'};
    `
  ]
);

export const form = styled.form(() => [
  css`
    ${tw`flex flex-col`};
    max-width: 540px;
  `
]);

export const actions = styled.div<Props>(({ isClient }) => [
  css`
    ${tw`flex flex-col`}
    ${tw`justify-between h-36`}
      margin-top: -1rem;

    button {
      ${tw`self-end`}
    }
  `
]);

export const topActions = styled.div(
  ({
    theme: {
      typography: { p }
    }
  }) => [
    css`
      ${p}
      ${tw`flex justify-between items-center`};
    `
  ]
);

export const linkText = styled(Link)(() => [
  css`
    ${tw`self-end py-3 text-2xl md:text-3xl text-darkBlue ml-auto`}
  `
]);
