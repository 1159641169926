import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import Actions from '../../../components/Actions';
import { Project } from '../../../store/projects/projects.types';
import {
  toggleFavorite,
  archiveProject
} from '../../../store/projects/projects.actions';
import * as S from './project-item';
import star from '../../../images/star-outline.svg';
import starFilled from '../../../images/star-filled.svg';
import useGlobal from '../../../store';
import { toastUtil } from '../../../utils/toast.util';

interface Props extends Project {
  listView: boolean;
  id: number;
  onChangeCallback: () => Promise<any>;
}

const ProjectItem = ({
  id,
  name,
  favorite,
  archived,
  listView,
  onChangeCallback
}: Props) => {
  const [accountId] = useGlobal((state) => state.auth.currentUser?.accountId);

  const [open, setOpen] = useState<boolean>(false);

  const toggle = () => setOpen(!open);
  const close = () => {
    if (open) setOpen(false);
  };

  const setFavorite = async () => {
    try {
      await toggleFavorite(accountId, id, !favorite);
      await onChangeCallback();
      toastUtil('success', 'Success', '', 1000);
    } catch (error) {
      return Promise.reject(error);
    }
  };

  const archive = async () => {
    try {
      await archiveProject(id);
      await onChangeCallback();
      close();
      toastUtil('success', 'Successfully archived', '', 1500);
    } catch (error) {
      return Promise.reject(error);
    }
  };

  return (
    <S.wrapper listView={listView}>
      <S.title list={listView ? 1 : 0} to={`/projects/${id}`}>
        {name}
      </S.title>
      <S.footer listView={listView}>
        <img src={favorite ? starFilled : star} alt="" onClick={setFavorite} />
        {!archived && (
          <Actions
            listView={listView}
            toggle={toggle}
            open={open}
            close={close}
          >
            <Link to={`/projects/${id}`}>Go to Project example</Link>
            <Link to={`/projects/${id}/edit`}>Edit project data</Link>
            <div onClick={archive}>Archive</div>
          </Actions>
        )}
      </S.footer>
    </S.wrapper>
  );
};

export default ProjectItem;
