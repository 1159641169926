import api from '../../config/api.config';
import {
  CreateMethod,
  BulletListItem,
  GetMethod,
  CreateServicePackage,
  GetAllServices,
  DuplicateArgs
} from './types';
import { DropdownCbOption } from '../../types/util.types';
import { handleErrors } from '../../utils/error.utils';
import { AppState } from '..';

export const getAllServices: GetAllServices = async ({
  page,
  size,
  level,
  qualityType,
  sort,
  serviceType,
  name
}) => {
  try {
    const {
      data: { data }
    } = await api(
      `/methods/serviceOverview?page=${page}&size=${size}&type=${qualityType}&level=${level}&sort=${sort}&serviceType=${serviceType}&name=${name}`
    );

    return data;
  } catch (error) {
    handleErrors(error);
    return Promise.reject(error);
  }
};

// methods

export const parseBulletList = (list: string = ''): BulletListItem[] =>
  list
    .split('•')
    .filter((item: string) => !!item.replace('\n', '').trim())
    .map((item: string) => ({
      name: item.replace('\n', '').trim()
    }));

export const getDisciplinesRolesPhases = async (
  store: AppState
): Promise<any> => {
  try {
    const {
      data: { data: disciplines }
    } = await api('/disciplines');

    const {
      data: { data: roles }
    } = await api('/memberRoles');

    const {
      data: { data: phase }
    } = await api('/phases');
    const parsedPhases: DropdownCbOption[] = phase.map(
      (p: { id: number; title: string }) => ({
        id: p.id,
        name: p.title
      })
    );

    store.setState({
      ...store.state,
      services: {
        disciplines: disciplines.sort((a: any, b: any) => a.id - b.id),
        memberRoles: roles,
        phases: parsedPhases
      }
    });
  } catch (error) {
    handleErrors(error);
    return Promise.reject(error);
  }
};

export const createMethod: CreateMethod = async (
  name,
  level,
  disciplines,
  phase,
  memberRoles,
  personDaysPmQm,
  duration,
  complexityStandards,
  description,
  deliverables,
  qualityMeasurements
) => {
  try {
    const parsedDeliverables = parseBulletList(deliverables);
    const parsedQm = parseBulletList(qualityMeasurements);

    const data = {
      name,
      level: level[0].id,
      disciplines: disciplines.map((d: DropdownCbOption) => d.id),
      phase: { id: phase[0].id },
      memberRoles,
      personDaysPmQm,
      duration: Number(duration),
      complexityStandards,
      description,
      qualityMeasurements: parsedQm,
      deliverables: parsedDeliverables
    };
    const res = await api.post('/methods', data);
    return res;
  } catch (error) {
    handleErrors(error);
    return Promise.reject(error);
  }
};

export const getMethods: GetMethod = async ({
  page,
  size,
  name = '',
  sort = true,
  qualityType
}) => {
  try {
    const {
      data: { data }
    } = await api(
      `/methods/overview?page=${page}&size=${size}&name=${name}&qualityType=${qualityType}&sort=${sort}`
    );

    return data;
  } catch (error) {
    handleErrors(error);
    return Promise.reject(error);
  }
};

export const editMethod: CreateMethod = async (
  name,
  level,
  disciplines,
  phase,
  memberRoles,
  personDaysPmQm,
  duration,
  complexityStandards,
  description,
  deliverables,
  qualityMeasurements,
  id
) => {
  try {
    const parsedDeliverables = parseBulletList(deliverables);
    const parsedQm = parseBulletList(qualityMeasurements);

    const data = {
      name,
      level: level[0].id,
      disciplines: disciplines.map((d: DropdownCbOption) => d.id),
      phase: { id: phase[0].id },
      memberRoles,
      personDaysPmQm,
      duration,
      complexityStandards,
      description,
      qualityMeasurements: parsedQm,
      deliverables: parsedDeliverables
    };
    const res = await api.put(`/methods/${id}`, data);
    return res;
  } catch (error) {
    handleErrors(error);
    return Promise.reject(error);
  }
};

export const duplicateMethod: DuplicateArgs = async (id) => {
  try {
    const res = await api.post(`/methods/${id}/duplicate`);
    return res;
  } catch (error) {
    handleErrors(error);
    return Promise.reject(error);
  }
};

// SP

export const createServicePackage: CreateServicePackage = async (
  name,
  level,
  methods,
  comment
) => {
  let methodIds = methods.reduce((acc: number[], current: any) => {
    if (current.method?.id) {
      acc.push(current.method?.id);
    }
    return acc;
  }, []);

  try {
    const data = {
      name,
      level: level[0].id,
      methods: methodIds,
      comment
    };
    const res = await api.post('/servicePackages', data);
    return res;
  } catch (error) {
    handleErrors(error);
    return Promise.reject(error);
  }
};

export const editServicePackage: CreateServicePackage = async (
  name,
  level,
  methods,
  comment,
  id
) => {
  let methodIds = methods.reduce((acc: number[], current: any) => {
    if (current.method?.id) {
      acc.push(current.method?.id);
    }
    return acc;
  }, []);
  try {
    const data = {
      name,
      level: level[0].id,
      methods: methodIds,
      comment
    };
    const res = await api.put(`/servicePackages/${id}`, data);
    return res;
  } catch (error) {
    handleErrors(error);
    return Promise.reject(error);
  }
};

export const duplicateServicePackage: DuplicateArgs = async (id) => {
  try {
    const res = await api.post(`/servicePackages/${id}/duplicate`);
    return res;
  } catch (error) {
    handleErrors(error);
    return Promise.reject(error);
  }
};

export const searchServicePackage = async (
  name: string,
  categories: string
): Promise<any> => {
  try {
    const {
      data: { data }
    } = await api.get(`/servicePackages?discipline=${categories}&name=${name}`);
    return data;
  } catch (error) {
    handleErrors(error);
    return Promise.reject(error);
  }
};

export const searchMethod = async (
  name: string,
  categories: string,
  level?: string
): Promise<any> => {
  try {
    const {
      data: { data }
    } = await api.get(
      `/methods?discipline=${categories}&name=${name}${
        level ? `&level=${level}` : ''
      }`
    );
    return data;
  } catch (error) {
    handleErrors(error);
    return Promise.reject(error);
  }
};
