import tw, { styled, css } from 'twin.macro';
import { StyledProps as Props } from '../../../types/util.types';

export const Wrapper = styled.div<Props>(({ open }) => [
  css`
    ${tw`flex bg-white  w-full h-0 opacity-0 overflow-hidden`}; /* max-width: 1504px; */
    transition: 1s ease-out;
  `,
  open &&
    css`
      ${tw`opacity-100 h-auto`}
    `
]);
