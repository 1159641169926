import React, { useState, useEffect } from 'react';
import { useFormik, FormikHelpers, FormikValues } from 'formik';
import useIsMounted from 'react-is-mounted-hook';
import validationSchema from './validation-schema';
import { handleFieldErrors } from '../../utils/error.utils';
import { initialValues, formModel } from './form-model';
import { TextInput } from '../../components/Inputs';
import { FormFieldType } from '../../types/form.types';
import Button from '../../components/Button';
import PasswordOutline from '../../images/auth/password-outline.svg';
import CloseIcon from '../../images/close-x.svg';
import useGlobal from '../../store';
import { toastUtil } from '../../utils/toast.util';
import { Form, InputTitle, InputWrapper, FormInputs } from '../Form/StyledForm';
import * as S from './change-password-form';
import { isClientAction } from '../../store/auth/auth.actions';

interface ChangePassFormProps {
  closeModal?: () => void;
}

const ChangePasswordForm = ({ closeModal }: ChangePassFormProps) => {
  const isMounted = useIsMounted();
  const [userId] = useGlobal((state) => state.auth.currentUser?.accountId);
  const [userName] = useGlobal((state) => state.auth.currentUser?.firstName);
  const [, logout] = useGlobal(
    () => null,
    (actions) => actions.logout
  );
  const [isChangePassRequired, changeUserPassword] = useGlobal(
    (state) => state.auth.currentUser?.resetPasswordRequired,
    (actions) => actions.changeUserPassword
  );

  const [invitationEmail, setInvitationEmail] =
    useState<undefined | undefined>(undefined);
  const [isSuccessful, setIsSuccessful] = useState<boolean>(false);
  const [submitting, setSubmitting] = useState<boolean>(false);

  const formik = useFormik({
    initialValues,
    validationSchema,
    onSubmit: async (
      values,
      formikHelpers: FormikHelpers<FormikValues>
    ): Promise<any> => {
      const { currentPassword, password, repeatedPassword } = values;
      try {
        setSubmitting(true);
        await changeUserPassword(
          userId,
          currentPassword,
          password,
          repeatedPassword,
          invitationEmail
        );
        formik.handleReset({});
        !!closeModal
          ? setIsSuccessful(true)
          : toastUtil(
              'success',
              'Great! Your password was successfully changed.',
              'change_password'
            );

        if (isMounted()) setSubmitting(false);
      } catch (error) {
        if (isMounted()) setSubmitting(false);
        handleFieldErrors(error, formikHelpers.setFieldError);
      }
    }
  });
  const isValidForm = validationSchema.isValidSync({ ...formik.values });

  useEffect(() => {
    const token = localStorage.getItem('cpcClientToken');
    if (closeModal && isChangePassRequired && token) {
      (async () => {
        try {
          const userData = await isClientAction(token ? token : '');
          setInvitationEmail(userData.invitationEmailId);
          formik.setFieldValue('currentPassword', userData?.password);
        } catch (error) {
          console.warn('Error: isClientAction');
        }
      })();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isChangePassRequired]);

  if (isSuccessful) {
    return (
      <S.content>
        <S.title>
          <S.passwordOutlineIcon src={PasswordOutline} />

          <h4>Great! Your password was successfully changed.</h4>
        </S.title>
        <S.buttonContainer>
          <S.closeXIcon src={CloseIcon} onClick={closeModal} />

          <Button type="submit" small secondary onClick={closeModal}>
            Go back to CPC
          </Button>
          <Button type="submit" small secondary onClick={logout}>
            Sign out
          </Button>
        </S.buttonContainer>
      </S.content>
    );
  }
  return (
    <Form onSubmit={formik.handleSubmit} isModal={!!closeModal}>
      {closeModal ? (
        <S.title>
          <S.passwordOutlineIcon src={PasswordOutline} red={true} /> 
          <h4>Hi {userName}, please change your password.</h4>
        </S.title>
      ) : (
        <h3>Change Password</h3>
      )}

      {formModel.map((field: FormFieldType) => (
        <FormInputs line={field.line} key={field.name}>
          <InputTitle>{field.title}</InputTitle>
          <InputWrapper>
            <TextInput
              profile
              type={field.type}
              small
              touched={formik.touched[field.name]}
              error={formik.errors[field.name]}
              {...formik.getFieldProps(field.name)}
            />
            {field.tips && (
              <S.hint>
                *<p>{field.tips}</p>
              </S.hint>
            )}
          </InputWrapper>
        </FormInputs>
      ))}
      <Button type="submit" disabled={!isValidForm || submitting}>
        Save changes
      </Button>
    </Form>
  );
};

export default ChangePasswordForm;
