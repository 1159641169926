import React, { useState, useRef, useCallback } from 'react';
import { Link } from 'react-router-dom';
import useOnClickOutside from 'use-onclickoutside';
import { ReactSVG } from 'react-svg';
import useGlobal from '../../store';
import UserRole from '../UserRole';
import * as S from './app-sidebar';
import arrow from '../../images/arrow-down.svg';
import Modal from '../Modal';
import ChangePasswordForm from '../ChangePasswordForm';
import { routePaths } from '../../utils/routes.util';

interface Props {
  toggleMenu?: () => void;
}

const UserMenu = ({ toggleMenu }: Props) => {
  const [user, logout] = useGlobal(
    (state) => state.auth.currentUser,
    (actions) => actions.logout
  );

  const ref = useRef(null);
  const [active, setActive] = useState<boolean>(false);

  const toggle = () => setActive(!active);
  const close = () => {
    if (active) setActive(false);
  };
  useOnClickOutside(ref, close);
  const [isUserResetPassReq] = useGlobal(
    (state) => state.auth.currentUser?.resetPasswordRequired
  );

  const [showDialog, setShowDialog] = useState<boolean>(false);
  const openModal = useCallback(() => setShowDialog(true), []);
  const closeModal = useCallback(() => setShowDialog(false), []);

  return (
    <S.userMenuWrapper>
      <div>
        {user?.image && <img src={user.image} alt="" />}
        {!user?.image && user?.firstName && (
          <span>{`${user.firstName.substring(0, 2)}${user.lastName.substring(
            0,
            2
          )}`}</span>
        )}
      </div>
      <span>Hello,</span>
      <S.userMenuActions onClick={toggle} ref={ref} active={active}>
        <span>
          {user?.firstName} {user?.lastName} <ReactSVG src={arrow} />
        </span>
        {active && (
          <S.userMenuDropdown>
            <Link to={routePaths.PROFILE} onClick={toggleMenu}>
              Personal Settings
            </Link>
            <hr />
            <span onClick={isUserResetPassReq ? openModal : logout}>
              Sign out of CPC
            </span>
          </S.userMenuDropdown>
        )}
        {user?.role && <UserRole role={user.role} company={user.company} />}
      </S.userMenuActions>

      <Modal isOpen={showDialog} onDismiss={closeModal}>
        <ChangePasswordForm closeModal={closeModal} />
      </Modal>
    </S.userMenuWrapper>
  );
};

export default UserMenu;
