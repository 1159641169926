import React, { useState, ChangeEvent, useEffect } from 'react';
import { useDropzone } from 'react-dropzone';
import { TextInput, CbRadio, NewSearchInput } from '../../../components/Inputs';
import Button from '../../../components/Button';
import Modal from '../../../components/Modal';
import PictureCropper from '../../../components/PictureCropper/PictureCropper';
import { ProjectClientType } from '../../../store/projects/projects.types';
import { CompanyType } from '../../../store/companies/companies.types';
import {
  updateAccount,
  updateAccountCompany
} from '../../../store/members/members.actions';
import { getCompanies } from '../../../store/companies/companies.actions';
import { uploadClientAvatar } from '../../../store/projects/projects.actions';
import { toastUtil } from '../../../utils/toast.util';
import uploadWhite from '../../../images/upload-white.svg';
import uploadDark from '../../../images/upload.svg';
import closeIcon from '../../../images/close-x.svg';
import {
  genderModel,
  GenderType
} from '../../../components/ClientForm/form-model';
import * as S from './project-header';

interface Props {
  onClose: () => void;
  client: ProjectClientType;
  onUpdate: (data: { clientData?: ProjectClientType; image?: string }) => void;
}

const EditClient = ({ onClose, client, onUpdate }: Props) => {
  const [updatingCompany, setUpdatingCompany] = useState<boolean>(false);
  const [company, setCompany] = useState(client.company.name);
  const [updatingUser, setUpdatingUser] = useState<boolean>(false);
  const [gender, setGender] = useState(client.gender);
  const [lastName, setLastName] = useState<string>(client.lastName);
  const [firstName, setFirstName] = useState<string>(client.firstName);
  const [email, setEmail] = useState<string>(client.email);
  const [isUploading, setIsUploading] = useState<boolean>(false);
  const [userAvatarPreview, setUserAvatarPreview] = useState<any>(null);
  const [file, setFile] = useState<any>(null);
  const [userImage] = useState<string>(client.image || '');
  const [openModal, setOpenModal] = useState<boolean>(false);
  const [croppedImagePreview, setCroppedImagePreview] = useState<any>(null);

  const { getRootProps, getInputProps } = useDropzone({
    accept: 'image/png, image/jpeg',
    onDropRejected: () => {
      setFile(null);
      setUserAvatarPreview(null);
    },
    onDrop: (acceptedFiles) => {
      const reader = new FileReader();
      reader.onload = () => {
        const url = reader.result;
        setUserAvatarPreview(url);
      };
      reader.readAsDataURL(acceptedFiles[0]);
      setFile(acceptedFiles[0]);
    }
  });

  const openCropModal = () => {
    setOpenModal(true);
  };

  const closeModal = () => {
    setOpenModal(false);
  };

  const onCropSuccess = async (file: any, preview: any) => {
    try {
      setFile(file);
      setCroppedImagePreview(preview);
    } catch {
      console.warn('Image upload failed');
    }
  };

  useEffect(() => {
    if (userAvatarPreview) openCropModal();
  }, [userAvatarPreview]);

  const searchCompany = async (searchTerm: string) => {
    try {
      const data = await getCompanies(searchTerm);
      return data;
    } catch {}
  };

  const onUpdateCompany = async () => {
    try {
      setUpdatingCompany(true);
      const clientData = await updateAccountCompany(client.id, company);
      onUpdate({ clientData });
      setUpdatingCompany(false);
    } catch {
      setUpdatingCompany(false);
    }
  };

  const onUpdateUser = async () => {
    try {
      setUpdatingUser(true);
      const clientData = await updateAccount(
        client.id,
        gender,
        firstName,
        lastName,
        email,
        client.company.id,
        client.role
      );
      onUpdate({ clientData });
      setUpdatingUser(false);
    } catch {
      setUpdatingUser(false);
    }
  };

  const handleUpload = async () => {
    try {
      setIsUploading(true);
      const { image } = await uploadClientAvatar(client.id, file);
      onUpdate({ image });
      setIsUploading(false);
      toastUtil('success', `User avatar was successfully uploaded`);
    } catch (error) {
      setIsUploading(false);
    }
  };

  return (
    <>
      <img src={closeIcon} alt="" onClick={onClose} />

      <S.editWrapper>
        <h3>Edit Client Information</h3>
        <S.companyContent>
          <NewSearchInput
            setValue={(value: string) => setCompany(value)}
            selectValue={(item: CompanyType) => {
              setCompany(item.name);
            }}
            value={company}
            label="Company Name"
            labelProps={['name']}
            onSearch={searchCompany}
          />
          <Button
            small
            secondary
            disabled={updatingCompany || !company}
            onClick={onUpdateCompany}
          >
            Edit company name
          </Button>
        </S.companyContent>
        <S.form>
          <S.gender>
            {genderModel.map((g: GenderType) => (
              <CbRadio
                key={g.value}
                radio
                checked={gender === g.name}
                name={g.name}
                value={g.value}
                onChange={(evt: ChangeEvent<HTMLInputElement>) => {
                  if (evt.target.checked) {
                    setGender(g.name);
                  }
                }}
              >
                {g.value}
              </CbRadio>
            ))}
          </S.gender>
          <TextInput
            value={lastName}
            type="text"
            label="Lastname"
            name="lastName"
            onChange={(e: ChangeEvent<HTMLInputElement>) => {
              setLastName(e.target.value);
            }}
          />
          <TextInput
            value={firstName}
            type="text"
            label="Firstname"
            name="firstName"
            onChange={(e: ChangeEvent<HTMLInputElement>) => {
              setFirstName(e.target.value);
            }}
          />
          <TextInput
            value={email}
            type="email"
            label="Email address"
            name="email"
            onChange={(e: ChangeEvent<HTMLInputElement>) => {
              setEmail(e.target.value);
            }}
          />
          <Button
            secondary
            small
            disabled={updatingUser || !lastName || !firstName || !email}
            onClick={onUpdateUser}
          >
            Update user
          </Button>
        </S.form>
        <S.avatarWrapper>
          <S.droparea
            {...getRootProps({ refKey: 'innerRef' })}
            preview={!!croppedImagePreview}
          >
            <input {...getInputProps()} />
            {userImage || croppedImagePreview ? (
              <img src={croppedImagePreview || userImage} alt="" />
            ) : (
              <div>
                <p>
                  Drop avatar image here, or select a file from your computer
                </p>
                <img src={uploadDark} alt="" />
              </div>
            )}
          </S.droparea>
          <S.actions>
            <Button
              secondary
              disabled={isUploading || !file}
              onClick={handleUpload}
              small
            >
              <img src={uploadWhite} alt="" />
              Change avatar
            </Button>
            <span>JPG or PNG, at least 256px.</span>
          </S.actions>
        </S.avatarWrapper>
        <Modal isOpen={openModal} onDismiss={closeModal}>
          <PictureCropper
            title="Upload Avatar"
            description="Drop avatar image here, or select a file from your computer"
            uploadDefinition="Upload Image"
            aspect={1 / 1}
            onClose={closeModal}
            selectedImage={userAvatarPreview}
            onCropSuccess={onCropSuccess}
          />
        </Modal>
      </S.editWrapper>
    </>
  );
};

export default EditClient;
