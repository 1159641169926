import React, { useState, useContext } from 'react';
import { ReactSVG } from 'react-svg';
import { useParams } from 'react-router-dom';
import { ExternalCostType } from '../../../store/projects/projects.types';
import { removeExternalCost } from '../../../store/projects/projects.actions';
import { ProgressContext } from '../Progress';
import { numberWithCommas } from '../../../utils/personDays.utils';
import * as S from './external-cost';
import Modal from '../../../components/Modal';
import Button from '../../../components/Button';
import AddCost from './AddCost';
import CostItem from './CostItem';
import images from '../../../utils/images.utils';

interface Props {
  data: ExternalCostType[];
}

interface Params {
  id: string;
}

const ExternalCost = ({ data }: Props) => {
  const { id } = useParams<Params>();
  const { isMemberRole } = useContext(ProgressContext);
  const [active, setActive] = useState<boolean>(false);
  const [costs, setCosts] = useState<ExternalCostType[]>(data);
  const [edit, setEdit] = useState<ExternalCostType | null>(null);
  const [deleteItem, setDeleteItem] = useState<number | null>(null);
  const [activeDelete, setActiveDelete] = useState<boolean>(false);
  const [isDeleting, setIsDeleting] = useState<boolean>(false);

  const closeModal = () => {
    setActive(false);
    setEdit(null);
  };

  const showModal = () => {
    setActive(true);
  };

  const onSuccess = (data: ExternalCostType | ExternalCostType[]) => {
    setCosts(Array.isArray(data) ? data : [...costs, data]);
    closeModal();
  };

  const onEdit = (cost: ExternalCostType) => {
    setEdit(cost);
    showModal();
  };

  const deleteCost = async () => {
    try {
      setIsDeleting(true);
      await removeExternalCost(id, deleteItem!);
      setCosts(costs.filter((c: ExternalCostType) => c.id !== deleteItem));
      closeDeleteModal();
      setIsDeleting(false);
    } catch {
      setIsDeleting(false);
    }
  };

  const showDeleteModal = (id: number) => {
    setActiveDelete(true);
    setDeleteItem(id);
  };

  const closeDeleteModal = () => {
    setActiveDelete(false);
    setDeleteItem(null);
  };

  const totalPrice = costs
    .reduce(
      (acc: number, curr: ExternalCostType) =>
        acc + Number(curr.price.replace(/,/g, '')),
      0
    )
    .toFixed(2);

  return (
    <>
      <S.wrapper>
        <h3>External Cost</h3>
        <S.body>
          <S.total>
            <ReactSVG wrapper="span" src={images.costIcon} />
            {numberWithCommas(Number(totalPrice))}
          </S.total>
          {costs.length > 0 && (
            <S.listWrapper>
              {costs.map((c: ExternalCostType) => (
                <CostItem
                  onDelete={showDeleteModal}
                  cost={c}
                  key={c.id}
                  onEdit={onEdit}
                />
              ))}
            </S.listWrapper>
          )}
          <S.addCostWrap onClick={showModal} disabled={isMemberRole ? 1 : 0}>
            <img src={images.addIcon} alt="Add External Cost" />
            Add external cost
          </S.addCostWrap>
        </S.body>
      </S.wrapper>
      <Modal isOpen={active} onDismiss={closeModal}>
        <AddCost onSuccess={onSuccess} cost={edit} />
      </Modal>
      <Modal isOpen={activeDelete} onDismiss={closeDeleteModal}>
        <S.deleteModal>
          <p>Are you sure that you want to delete this external cost?</p>
          <div>
            <Button secondary onClick={closeDeleteModal} disabled={isDeleting}>
              Cancel
            </Button>
            <Button onClick={deleteCost} disabled={isDeleting}>
              Confirm
            </Button>
          </div>
        </S.deleteModal>
      </Modal>
    </>
  );
};

export default ExternalCost;
