import tw, { styled, css } from 'twin.macro';
import { Dropdown } from '../../../components/Actions/actions-component';

export const wrapper = styled.div(
  ({
    theme: {
      media: {
        breakpoints: { smMax }
      }
    }
  }) => [
    css`
      ${tw`w-full`};
      max-width: 16rem;

      ${Dropdown} {
        width: 25.5rem;
      }

      @media screen and (max-width: ${smMax}px) {
        max-width: none;
      }
    `
  ]
);
