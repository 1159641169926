import React, { useState, useContext } from 'react';
import { useParams } from 'react-router-dom';
import { removeEvent } from '../../../store/projects/projects.actions';
import { ProgressContext } from '../Progress';
import Modal from '../../../components/Modal';
import Button from '../../../components/Button';
import images from '../../../utils/images.utils';
import * as S from './project-duration';
interface Params {
  id: string;
}
interface Props {
  eventId: number;
  [x: string]: any;
}

const DeleteEvent = ({ eventId, onClick }: Props) => {
  const { id: projectId } = useParams<Params>();
  const { updateProject } = useContext(ProgressContext);
  const [active, setActive] = useState<boolean>(false);
  const [isDeleting, setIsDeleting] = useState<boolean>(false);

  const closeDeleteModal = () => {
    setActive(false);
  };

  const showDeleteModal = () => {
    setActive(true);
  };

  const onDeleteEvent = async () => {
    try {
      setIsDeleting(true);
      const data = await removeEvent(projectId, eventId);
      setIsDeleting(false);
      updateProject(data);
      setIsDeleting(false);
    } catch (err) {
      console.warn(err);
      setIsDeleting(false);
    }
  };

  return (
    <>
      <S.deleteEventWrap>
        <img src={images.delete} alt="delte event" onClick={showDeleteModal} />
      </S.deleteEventWrap>
      <Modal isOpen={active} onDismiss={closeDeleteModal}>
        <S.deleteEventModal>
          <p>Are you sure that you want to delete this event?</p>
          <div>
            <Button secondary onClick={closeDeleteModal} disabled={isDeleting}>
              Cancel
            </Button>
            <Button onClick={onDeleteEvent} disabled={isDeleting}>
              Confirm
            </Button>
          </div>
        </S.deleteEventModal>
      </Modal>
    </>
  );
};

export default DeleteEvent;
