import React, { useEffect } from 'react';
import { Switch } from 'react-router-dom';
import Sidebar from '../../components/AppSidebar';
import useGlobal from '../../store';
import Members from '../Members';
import Projects from '../Projects';
import CreateProject from '../CreateProject';
import Profile from '../Profile';
import AddMember from '../AddMember';
import CreateMethod from '../CreateMethod';
import CreateService from '../CreateService';
import Clients from '../Clients';
import Services from '../Services';
import Progress from '../Progress';
import EditProject from '../EditProject';
import Summary from '../Summary';
import ClientPage from '../ClientPage';
import AuthRoute from '../../components/RouteGuard/AuthRoute';
import { AuthRoutes, UserRoles } from '../../utils/routeEnums.utils';
import Unauthorized from '../Unauthorized';

const Protected = () => {
  const [loggedIn, getCurrentUser] = useGlobal(
    (state) => state.auth.loggedIn,
    (actions) => actions.getCurrentUser
  );

  useEffect(() => {
    (async () => {
      try {
        if (loggedIn) {
          getCurrentUser();
        }
      } catch (error) {
        console.warn('Error: getCurrentUser');
      }
    })();
  }, [loggedIn, getCurrentUser]);

  return (
    <>
      <Sidebar />
      <div className="content-wrapper">
        <Switch>
          <AuthRoute
            exact
            path={AuthRoutes.root}
            Component={Projects}
            requiredRoles={[
              String(UserRoles.superAdmin),
              String(UserRoles.admin),
              String(UserRoles.manager),
              String(UserRoles.member),
              String(UserRoles.client)
            ]}
          />
          <AuthRoute
            exact
            path={AuthRoutes.profile}
            Component={Profile}
            requiredRoles={[
              String(UserRoles.superAdmin),
              String(UserRoles.admin),
              String(UserRoles.manager),
              String(UserRoles.member),
              String(UserRoles.client)
            ]}
          />
          <AuthRoute
            exact
            path={AuthRoutes.members}
            Component={Members}
            requiredRoles={[
              String(UserRoles.superAdmin),
              String(UserRoles.admin),
              String(UserRoles.manager)
            ]}
          />
          <AuthRoute
            exact
            path={AuthRoutes.membersCreate}
            Component={AddMember}
            requiredRoles={[
              String(UserRoles.superAdmin),
              String(UserRoles.admin),
              String(UserRoles.manager)
            ]}
          />
          <AuthRoute
            exact
            path={AuthRoutes.clientProjects}
            Component={ClientPage}
            requiredRoles={[String(UserRoles.client)]}
          />
          <AuthRoute
            exact
            path={AuthRoutes.project}
            Component={Projects}
            requiredRoles={[
              String(UserRoles.superAdmin),
              String(UserRoles.admin),
              String(UserRoles.manager),
              String(UserRoles.member)
            ]}
          />
          <AuthRoute
            exact
            path={AuthRoutes.projectsCreate}
            Component={CreateProject}
            requiredRoles={[
              String(UserRoles.superAdmin),
              String(UserRoles.admin),
              String(UserRoles.manager)
            ]}
          />
          <AuthRoute
            exact
            path={AuthRoutes.singleProject}
            Component={Progress}
            requiredRoles={[
              String(UserRoles.superAdmin),
              String(UserRoles.admin),
              String(UserRoles.manager),
              String(UserRoles.member)
            ]}
          />
          <AuthRoute
            exact
            path={AuthRoutes.editProject}
            Component={EditProject}
            requiredRoles={[
              String(UserRoles.superAdmin),
              String(UserRoles.admin),
              String(UserRoles.manager)
            ]}
          />
          <AuthRoute
            exact
            path={AuthRoutes.projectSummary}
            Component={Summary}
            requiredRoles={[
              String(UserRoles.superAdmin),
              String(UserRoles.admin),
              String(UserRoles.manager)
            ]}
          />
          <AuthRoute
            exact
            path={AuthRoutes.clientSummary}
            Component={Summary}
            requiredRoles={[String(UserRoles.client)]}
          />
          <AuthRoute
            exact
            path={AuthRoutes.services}
            Component={Services}
            requiredRoles={[
              String(UserRoles.superAdmin),
              String(UserRoles.admin),
              String(UserRoles.manager)
            ]}
          />
          <AuthRoute
            exact
            path={AuthRoutes.servicesNewMethod}
            Component={CreateMethod}
            requiredRoles={[
              String(UserRoles.superAdmin),
              String(UserRoles.admin),
              String(UserRoles.manager)
            ]}
          />
          <AuthRoute
            exact
            path={AuthRoutes.servicesNewService}
            Component={CreateService}
            requiredRoles={[
              String(UserRoles.superAdmin),
              String(UserRoles.admin),
              String(UserRoles.manager)
            ]}
          />
          <AuthRoute
            exact
            path={AuthRoutes.clients}
            Component={Clients}
            requiredRoles={[
              String(UserRoles.superAdmin),
              String(UserRoles.admin),
              String(UserRoles.manager)
            ]}
          />
          <AuthRoute
            exact
            path={AuthRoutes.unauthorized}
            Component={Unauthorized}
            requiredRoles={[
              String(UserRoles.superAdmin),
              String(UserRoles.admin),
              String(UserRoles.manager),
              String(UserRoles.member),
              String(UserRoles.client)
            ]}
          />
        </Switch>
      </div>
    </>
  );
};

export default Protected;
