import React from 'react';
import { SetStateType } from '../../types/util.types';
import * as S from './view-type';
import card from '../../images/card-view.svg';
import list from '../../images/list-view.svg';

interface Props {
  setList: SetStateType<boolean>;
  isListView: boolean;
}

const ViewType = ({ isListView, setList }: Props) => (
  <S.wrapper isListView={isListView}>
    <img src={card} alt="card view" onClick={() => setList(false)} />
    <img src={list} alt="list view" onClick={() => setList(true)} />
  </S.wrapper>
);

export default ViewType;
