import tw, { styled, css } from 'twin.macro';

export const wrapper = styled.div(() => [
  css`
    > img {
      height: 30.4rem;
      width: 100%;
      object-fit: cover;
    }
  `
]);

export const content = styled.div(() => [
  css`
    ${tw`bg-white px-30 py-50`};

    div {
      ${tw`flex items-start justify-between mb-30`}
    }

    h2 {
      ${tw`mb-0 leading-none text-textBlack text-56 font-bold`}
    }
    p {
      ${tw`mb-30 last:mb-0 font-normal text-32 text-textBlack leading-44`}
    }

    button {
      svg {
        path {
          fill: white;
        }
      }
    }
  `
]);

export const modalContent = styled.div(() => [
  css`
    ${tw`max-w-full w-730 pt-27 pb-41 px-20 sm:px-40 md:px-95 shadow-discipline  bg-white rounded-2`};

    > img {
      ${tw`absolute cursor-pointer`}
      top: 1rem;
      right: 1rem;
    }

    > button {
      ${tw`mt-34 ml-auto`}
    }
  `
]);

export const editWrapper = styled.div(() => [
  css`
    h3 {
      ${tw`text-textBlack`}
    }
  `
]);

export const companyContent = styled.div(() => [
  css`
    ${tw`flex flex-col items-end mb-20`};
  `
]);

export const gender = styled.div(() => [
  css`
    ${tw`flex mt-20 mb-10`};

    label {
      ${tw`text-2xl mr-20`}
    }
  `
]);

export const form = styled.form(() => [
  css`
    ${tw`flex flex-col w-full `};

    button {
      ${tw`self-end `}
    }
  `
]);

export const avatarWrapper = styled.div(() => [
  css`
    ${tw`flex items-center md:justify-start w-full mt-30 flex-wrap `}
    > img {
      ${tw`w-full mb-15 mr-auto opacity-50`}
      height:13.5rem;
      width: 13.5rem;
      border-radius: 50%;
      object-fit: cover;
      object-position: 0 30%;
    }
  `
]);

type DropareaProps = {
  preview: boolean;
};

export const droparea = styled.div<DropareaProps>(({ preview }) => [
  css`
    ${tw`flex flex-col items-center justify-center w-full h-full border-2 border-darkBlue border-dashed outline-none text-15 leading-22 font-semibold text-center md:mr-15 cursor-pointer text-textBlack`};
    max-width: 19.5rem;
    height: 14rem;
    background: rgba(166, 188, 209, 0.15);
    border-radius: 8px;
    max-height: 14rem;

    > img {
      ${tw`h-full w-full`}
      object-fit: contain;
    }

    div {
      ${tw`flex flex-col items-center p-20`}

      img {
        ${tw`mt-10`}
      }
    }

    p {
      ${tw`text-14 leading-22 tracking-normal`}
    }
  `,
  preview &&
    css`
      ${tw`border-0 px-0`}
      /* height: auto; */
      img {
        ${tw`w-full mt-0`}
      }
    `
]);

export const actions = styled.div(() => [
  css`
    ${tw`inline-flex flex-col self-end ml-auto mt-15`}

    span {
      ${tw`block w-full mt-3 text-14 leading-22 font-semibold text-textBlack`}
    }
  `
]);
