import React, { useRef } from 'react';
import useOnClickOutside from 'use-onclickoutside';
import { Wrapper, Dropdown, Container } from './actions-component';

interface Props {
  listView?: boolean;
  open: boolean;
  close: () => void;
  toggle?: () => void;
  dropdown?: boolean;
  checkbox?: boolean;
  children: any;
}

const Actions = ({
  children,
  listView,
  open,
  close,
  toggle,
  ...rest
}: Props) => {
  const ref = useRef(null);

  useOnClickOutside(ref, close);

  return (
    <Wrapper ref={ref} active={open} listView={listView} {...rest}>
      {rest.dropdown && <Container onClick={toggle} />}
      <span onClick={toggle} />
      {open && (
        <Dropdown listView={listView} {...rest}>
          {children}
        </Dropdown>
      )}
    </Wrapper>
  );
};

export default Actions;
