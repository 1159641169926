import { toastUtil } from '../utils/toast.util';

export const handleFieldErrors = (
  error: any,
  setError?: any,
  isModal?: boolean
): void => {
  if (error?.meta?.errors) {
    error.meta.errors.forEach((e: any) => {
      if (isModal) {
        toastUtil(
          'error',
          e.messages[0] || 'Something went wrong. Please try again.'
        );
      }
      return setError(e.field, e.messages[0]);
    });
  }
};
export const handleErrors = (error: any, customMessage?: string): void => {
  let msg = error?.message;
  if (error?.response?.data?.meta?.message)
    msg = error.response.data.meta.message;
  if (error?.response?.data?.meta?.errors)
    msg = error.response.data.meta.errors[0].messages[0];
  if (error?.response?.status === 401)
    msg = 'Please check your credentials and try again.';

  toastUtil(
    'error',
    msg === 'Unauthorized' && customMessage
      ? customMessage
      : (msg !== `Cannot read property 'status' of undefined` && msg) ||
          'Something went wrong. Please try again.'
  );
};
