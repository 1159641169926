import React, { useState, useContext } from 'react';
import {
  ProjectMembersType,
} from '../../../store/projects/projects.types';
import { ProgressContext } from '../Progress';
import * as S from './project-members';
import Modal from '../../../components/Modal';
import ProjectMemberItem from './ProjectMemberItem';
import AddMember from './AddMember';
import images from '../../../utils/images.utils';

interface Props {
  isClient?: boolean;
}

const ProjectMembers = ({ isClient }: Props) => {
  const { project: {projectMembers}, isMemberRole } = useContext(ProgressContext);
  const [active, setActive] = useState<boolean>(false);

  const closeModal = () => {
    setActive(false);
  };

  const showModal = () => {
    setActive(true);
  };

  return (
    <>
      <S.wrapper>
        <h3>Your Team</h3>
        <S.body>
          {projectMembers.length > 0 && (
            <S.itemsWrapper>
              {projectMembers.map((m: ProjectMembersType) => (
                <ProjectMemberItem key={m.id} data={m} />
              ))}
            </S.itemsWrapper>
          )}
          {!isClient && (
            <S.addMemberWrap onClick={showModal} disabled={isMemberRole ? 1 : 0}>
              <img src={images.addIcon} alt="Add Member" />
              {projectMembers.length > 0 ? 'Add more members' : 'Add member'}
            </S.addMemberWrap>
          )}
        </S.body>
      </S.wrapper>
      <Modal isOpen={active} onDismiss={closeModal}>
        <AddMember closeModal={closeModal} />
      </Modal>
    </>
  );
};

export default ProjectMembers;
