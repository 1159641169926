import tw, { styled, css } from 'twin.macro';
import CbRadio from '../../../components/Inputs/checkbox-radio';

export const contactEffortWrap = styled.div(() => [
  css`
    ${tw``}
    margin-bottom: auto;

    @media print {
      page-break-inside: avoid;
      padding-top: 3rem;
    }
  `
]);

export const effortWrap = styled.div(() => [
  css`
    ${tw`mx-16 sm:mx-37 mb-31`};

    h1 {
      ${tw`text-14 sm:text-22 text-textBlack font-bold mb-10 sm:mb-20 leading-none`};
      letter-spacing: 0.29px;
    }
  `
]);

export const effortBody = styled.div(() => [
  css`
    ${tw`bg-white flex flex-col px-24 pt-24 pb-5 items-start`}

    @media screen and (max-width: 575px) and (min-width: 320px) {
      ${tw`px-16 pt-16`}
    }
  `
]);

export const effortWrapCards = styled.div(() => [
  css`
    ${tw`flex justify-between w-full`}
  `
]);

export const effortStaticText = styled.span(() => [
  css`
    ${tw`block text-pink text-12 font-semibold my-8 last:mb-0`};

    @media screen and (max-width: 575px) and (min-width: 320px) {
      ${tw`text-9 mt-6`}
    }
  `
]);

export const effortCard = styled.div(() => [
  css`
    ${tw`rounded-1 flex items-center border-half p-16`};
    border-color: rgb(231, 235, 236);
    width: 37rem;

    @media print {
      width: 40rem;
    }

    img {
      ${tw`pr-16 ml-24`};
      height: 4.5rem;
    }

    div {
      ${tw`flex flex-col justify-start`};

      span:nth-of-type(1) {
        ${tw`text-darkBlue text-18 font-bold`};
      }

      span:nth-of-type(2) {
        ${tw`text-darkBlue text-14 font-bold`};
      }
    }

    @media screen and (max-width: 575px) and (min-width: 320px) {
      ${tw`p-10 w-140`}

      img {
        ${tw`h-28 ml-1`}
      }

      div {
        span:nth-of-type(1) {
          ${tw`text-darkBlue text-12 font-bold`};
        }

        span:nth-of-type(2) {
          ${tw`text-darkBlue text-9 font-bold`};
        }
      }
    }
  `
]);

export const contactWrap = styled.div(() => [
  css`
    ${tw`mx-37 mb-31`};

    h1 {
      ${tw`text-22 text-textBlack font-bold mb-20 leading-none`};
      letter-spacing: 0.29px;
    }

    @media screen and (max-width: 575px) and (min-width: 320px) {
      ${tw`mx-16`}

      h1 {
        ${tw`text-14 mb-10`}
      }
    }
  `
]);

export const contactBody = styled.div(() => [
  css`
    ${tw`bg-white flex flex-row px-24 py-24`}

    @media screen and (max-width: 575px) and (min-width: 320px) {
      ${tw`px-16 py-17`}
    }
  `
]);

export const contactPicture = styled.img(() => [
  css`
    ${tw`rounded-4 mr-40`}
    height: auto;
    width: 15rem;

    @media screen and (max-width: 575px) and (min-width: 320px) {
      margin-right: 1rem;
      width: 8rem;
      height: 8rem;
    }
  `
]);

export const contactInfo = styled.div(() => [
  css`
    ${tw`pt-10`}

    h6 {
      ${tw`text-18 text-textBlack font-semibold`}
    }

    h4 {
      ${tw`text-18 text-textBlack font-normal`}
      letter-spacing: 0.33px;
    }

    @media screen and (max-width: 575px) and (min-width: 320px) {
      ${tw`pt-0`}
      h6 {
        ${tw`text-12`}
      }

      h4 {
        ${tw`text-12 mb-0`}
      }
    }
  `
]);

export const contactMail = styled.div(() => [
  css`
    ${tw`flex mt-16 pl-2 py-1 items-center`}

    span {
      ${tw`ml-20 text-18 font-normal text-textBlack`}
    }

    @media screen and (max-width: 575px) and (min-width: 320px) {
      margin-top: 1.2rem;

      span {
        ${tw`text-10 ml-10`}
      }

      img {
        width: 1.5rem;
      }
    }
  `
]);

export const footedBottomWrap = styled.div(() => [
  css`
    ${tw`w-full bg-darkBlue flex justify-between items-center py-5 px-34`};

    div {
      ${tw`flex items-center`};

      & > span:nth-of-type(1) {
        ${tw`mr-5`};
      }
    }

    span {
      ${tw`text-white font-semibold text-14`};
      letter-spacing: 0.32px;
    }

    @media screen and (max-width: 575px) and (min-width: 320px) {
      ${tw`px-16`}

      span {
        ${tw`text-9`}
      }
    }
  `
]);

export const buttonLabel = styled.div(() => [
  css`
    ${CbRadio} {
      span:first-of-type {
        height: 1.5rem;
        width: 1.5rem;
        &:after {
          height: 9px;
          width: 5px;
        }
      }
    }

    @media print {
      display: none;
    }
  `
]);

export const checkboxText = styled.div(() => [
  css`
    ${tw`text-darkBlue text-14 mr-16`};

    @media screen and (max-width: 575px) and (min-width: 320px) {
      ${tw`text-9`}
    }
  `
]);
