import tw, { styled, css } from 'twin.macro';
import { Dropdown } from '../../../components/Actions/actions-component';
import { StyledButton } from '../../../components/Button/styles';
import { StyledProps as Props } from '../../../types/util.types';

export const wrapper = styled.div<Props>(
  ({
    theme: {
      media: {
        breakpoints: { smMax }
      }
    },
    disabled
  }) => [
    css`
      ${tw`w-full lg:mr-30`};
      max-width: 34.8rem;

      ${Dropdown} {
        ${tw`px-20! `}
        max-width: none;
        width: calc(100% + 3.2rem);
        right: -1.7rem;

        ${StyledButton} {
          ${tw`mt-0`}
        }
      }

      @media screen and (max-width: ${smMax}px) {
        max-width: none;
      }
    `,
    disabled &&
      css`
        opacity: 0.5;
        pointer-events: none;
      `
  ]
);

export const roles = styled.div(() => [
  css`
    ${tw`flex justify-between items-center w-full mb-15`};
  `
]);

export const personDays = styled.div(() => [
  css`
    ${tw`relative flex items-center border-2 border-solid border-darkBlue `};
    min-width: 12.8rem;
    max-width: 12.8rem;

    div {
      ${tw`w-full pl-2 border-0 outline-none bg-transparent text-22 text-right font-semibold`}
      padding-right: 4.5rem;
      opacity: 0.5;
    }

    span {
      ${tw`absolute text-22`}
      right: 1.5rem;
      opacity: 0.5;
    }
  `
]);

export const title = styled.div(() => [
  css`
    ${tw`w-full mb-25 text-textBlack text-32 leading-36 font-semibold`};
  `
]);
