import React, { useState, useRef, useEffect, useContext } from 'react';
import useOnClickOutside from 'use-onclickoutside';
import DayPicker, { DateUtils } from 'react-day-picker';
import {
  format,
  differenceInBusinessDays,
  isWeekend,
  addWeeks
} from 'date-fns';
import { ProgressContext } from '../Progress';
import calendarIcon from '../../../images/calendar.svg';
import closeIcon from '../../../images/close-x.svg';
import CalendarNav from '../../../components/CalendarNav';
import Button from '../../../components/Button';
import calendarCheckedIcon from '../../../images/calender-checked.svg';
import * as S from './phase-method';

type RangeType = {
  from: any;
  to: any;
};

interface Props {
  from: Date | undefined;
  to: Date | undefined;
  onSave: (start: Date, end: Date) => void;
  durationInWeeks: number;
}

const MethodDate = ({ from, to, onSave, durationInWeeks }: Props) => {
  const ref = useRef(null);
  const initialDate = useRef(false);
  const [active, setActive] = useState<boolean>(false);
  const [range, setRange] = useState<RangeType>({
    from: from,
    to: to
  });
  const {
    project: { duration }
  } = useContext(ProgressContext);

  const toggle = () => {
    if (!active && !duration?.startDate) return;
    setActive(!active);
  };
  const close = () => {
    if (active) setActive(false);
  };

  const onConfirm = () => {
    onSave(range.from, range.to);
    close();
  };

  const onReset = () => {
    setRange({ from, to });
  };

  const handleDayClick = (day: any, { disabled }: any) => {
    if (disabled) return;
    const newRange = DateUtils.addDayToRange(day, { ...range });
    setRange(newRange);
  };

  useEffect(() => {
    if (initialDate.current) return;
    if (range.from && !range.to) {
      setRange({ ...range, to: addWeeks(range.from, durationInWeeks) });
      initialDate.current = true;
    }
  }, [range, durationInWeeks]);

  useEffect(() => {
    setRange({ from, to });
  }, [from, to]);

  useOnClickOutside(ref, close);

  let bussinesDays = differenceInBusinessDays(range.to, range.from);
  let endsOnWeekend = isWeekend(range.to);

  return (
    <S.dateWrapper ref={ref}>
      <S.dateIcon
        src={from && to ? calendarCheckedIcon : calendarIcon}
        alt="date"
        onClick={toggle}
        disabled={!duration?.startDate}
      />
      {active && (
        <S.dateDropdown>
          <S.dateHeader>
            <span>
              {range.to && range.from
                ? (bussinesDays + (endsOnWeekend ? 0 : 1)).toFixed(2)
                : '0.00'}{' '}
              workdays
            </span>
            <div>
              <S.dateBox>
                <span>Start date</span>
                {range.from && format(range.from, 'dd.MM.yyyy')}
                {range.from && (
                  <img
                    src={closeIcon}
                    alt=""
                    onClick={() => setRange({ ...range, from: undefined })}
                  />
                )}
              </S.dateBox>
              <S.dateBox>
                <span>End date</span>
                {range.to && format(range.to, 'dd.MM.yyyy')}
                {range.to && (
                  <img
                    src={closeIcon}
                    alt=""
                    onClick={() => setRange({ ...range, to: undefined })}
                  />
                )}
              </S.dateBox>
            </div>
          </S.dateHeader>
          <S.dateBody>
            <DayPicker
              numberOfMonths={2}
              navbarElement={<CalendarNav />}
              disabledDays={
                duration?.startDate
                  ? [
                      { before: new Date(duration.startDate) },
                      { daysOfWeek: [0, 6] }
                    ]
                  : [{ daysOfWeek: [0, 6] }]
              }
              selectedDays={[range.from, range.to, { ...range }]}
              modifiers={{ start: range.from, end: range.to }}
              onDayClick={handleDayClick}
              initialMonth={from ? from : new Date()}
            />
          </S.dateBody>
          <S.dateFooter>
            <Button secondary small onClick={onReset}>
              Reset date
            </Button>
            <Button
              small
              onClick={onConfirm}
              disabled={!range.from || !range.to}
            >
              Confirm date
            </Button>
          </S.dateFooter>
        </S.dateDropdown>
      )}
    </S.dateWrapper>
  );
};

export default MethodDate;
