import React, { useState, useEffect, useRef } from 'react';
import useOnClickOutside from 'use-onclickoutside';
import useDebounce from '../../hooks/useDebounce';
import { dropdownSearchTerm } from '../../store/companies/companies.actions';
import {
  InputContent,
  InputDropdown,
  Label,
  NoOptions,
  Options,
  Container,
  PersonDays
} from './StyledSearchInput';

interface Props {
  result?: any;
  setResult?: any;
  options?: any;
  setOptions?: any;
  label: string | undefined;
  api: string;
  disabledInput?: boolean;
  disabled?: boolean;
  checkProp?: any;
  arrow?: boolean;
  labelColor?: string;
  setIsSelected?: any;
  setGender?: any;
  withInputs?: boolean;
  index?: number;
}

const SearchInput = ({
  result,
  setResult,
  options,
  setOptions,
  label,
  api,
  disabledInput,
  disabled,
  checkProp,
  arrow,
  setIsSelected,
  setGender,
  withInputs,
  index,
  ...rest
}: Props) => {
  const toggle = () => setShowDrop(!showDrop);
  const close = () => {
    if (showDrop) setShowDrop(false);
  };
  const [showDrop, setShowDrop] = useState(false);

  const [searchTerm, setSearchTerm] = useState<string>('');

  const debouncedSearchTerm = useDebounce(searchTerm, 500);

  const [isSearching, setIsSearching] = useState<boolean>(false);

  // const [selected, setSelected] = useState<any>();

  const ref = useRef(null);
  useOnClickOutside(ref, close);

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setShowDrop(true);
    setSearchTerm(e.target.value);
    setResult(e.target.value, index);
    if (setIsSelected) {
      setIsSelected(false);
    }
  };

  const handleTab = (ev: any) => {
    const { keyCode } = ev;
    if (keyCode === 9) close();
  };

  const handleBlur = () => {
    if (disabledInput && !result?.poName) {
      setResult('', index);
    }
  };

  useEffect(() => {
    if (debouncedSearchTerm) {
      (async () => {
        try {
          setIsSearching(true);
          const searchResult = await dropdownSearchTerm(
            debouncedSearchTerm,
            api
          );
          const newOptions = searchResult?.map((option: any) => {
            if (checkProp === 'poName') {
              return {
                ...option,
                poName: option.firstName + ' ' + option.lastName
              };
            } else {
              return option;
            }
          });

          setIsSearching(false);
          setOptions(newOptions, index);
        } catch (error) {
          console.warn('error:', error);
          setIsSearching(false);
        }
      })();
    } else {
      setOptions([], index);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [debouncedSearchTerm]);

  const value = result && result[checkProp] ? result[checkProp] : result;

  return (
    <InputContent
      onKeyDown={handleTab}
      onClick={toggle}
      onBlur={handleBlur}
      arrow={arrow}
      disabled={disabled}
      withInputs={withInputs}
    >
      {arrow && (
        <Container active={showDrop}>
          <span />
        </Container>
      )}

      <input onChange={handleChange} value={value} />

      <Label hasValue={result} open={showDrop} {...rest}>
        {label}
      </Label>

      <InputDropdown show={showDrop} ref={ref}>
        {isSearching ? (
          <NoOptions>Loading...</NoOptions>
        ) : options === undefined || options?.length < 1 ? (
          <NoOptions>No options</NoOptions>
        ) : !withInputs ? (
          options?.map((item: any) => {
            const user = `${item.lastName} ${item.firstName}`;
            return (
              <Options
                key={item.id}
                onClick={() => {
                  setResult(item, index);
                  setShowDrop(false);
                  setIsSelected && setIsSelected(true);
                  setGender && setGender(item.gender);
                }}
              >
                {item.name || user}
              </Options>
            );
          })
        ) : (
          options?.map((item: any) => {
            const user = `${item.lastName} ${item.firstName}`;
            const { minPersonDays, gender } = item;

            return (
              <Options
                key={item.id}
                withInputs={withInputs}
                onClick={() => {
                  setResult(item, index);
                  setShowDrop(false);
                  setIsSelected && setIsSelected(true);
                  setGender && setGender(gender);
                }}
              >
                {item.name || user}
                <PersonDays>
                  <div>{minPersonDays}</div>
                  <span>pd</span>
                </PersonDays>
              </Options>
            );
          })
        )}
      </InputDropdown>
    </InputContent>
  );
};

export default SearchInput;
