import React, { useState, useRef } from 'react';
import useOnClickOutside from 'use-onclickoutside';
import { ProjectQualityLog } from '../../../store/projects/projects.types';
import images from '../../../utils/images.utils';
import * as S from './phase-method';

interface Props {
  log: ProjectQualityLog;
}

const DaysLog = ({
  log: {
    previousPersonDays,
    currentPersonDays,
    updater: { firstName, lastName }
  }
}: Props) => {
  const ref = useRef(null);
  const [active, setActive] = useState<boolean>(false);
  const toggle = () => setActive(!active);
  const close = () => {
    if (active) setActive(false);
  };

  useOnClickOutside(ref, close);

  return (
    <S.log ref={ref}>
      <img src={images.commentActive} alt="" onClick={toggle} />
      {active && (
        <S.commentBox>
          <img src={images.commentActive} alt="comment active" />
          <img src={images.closeX} alt="close" onClick={close} />
          <S.commentLog>
            {firstName} {lastName} changed person days from{' '}
            <span>{previousPersonDays} pd</span> to{' '}
            <span>{currentPersonDays} pd</span>
          </S.commentLog>
        </S.commentBox>
      )}
    </S.log>
  );
};

export default DaysLog;
